import React, { useState, useEffect, useReducer, useImperativeHandle } from 'react';
import { Col, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';

import InfoIcon from 'components/Icons/InfoIcon';
import Input from 'components/Input';
import { userVar, fromAppVar } from 'graphql/cache';
import { ClientName, WixClientName, MondayClientName } from '../../../modules';
import { sanitizeString } from 'utils/xss';

const initialState = {
  name: '',
  contact: '',
  desc: '',
  email: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setName':
      return { ...state, name: action.name };
    case 'setContact':
      return { ...state, contact: action.contact };
    case 'setDesc':
      return { ...state, desc: action.desc };
    case 'setEmail':
      return { ...state, email: action.email };
    case 'setMondayItemId':
      return { ...state, mondayItemId: action.id };
    default:
      throw new Error('Unexpected action');
  }
};

const ClientInformation = React.forwardRef(
  ({ prop, save, configText, fetchWixProductsAndServicesAndInfo, setWixSiteProperties }, ref) => {
    const userVariables = userVar();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [mrmsSet, setMrmsSet] = useState(false);
    const [mrmsclient, setMrmsclient] = useState(
      _.random(1) ? configText('mr client', true) : configText('ms client', true)
    );

    useEffect(() => {
      if (prop?.client) {
        dispatch({ type: 'setName', name: prop.client?.name });
        dispatch({ type: 'setDesc', desc: prop.client?.desc });
        dispatch({ type: 'setEmail', email: prop.client?.email });

        if (prop.client?.contact === '') {
          dispatch({ type: 'setContact', contact: mrmsclient });
        } else {
          dispatch({ type: 'setContact', contact: prop.client?.contact });
          if (!mrmsSet) {
            setMrmsclient('');
            setMrmsSet(true);
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prop?.client]);

    const onChange = ({ type, action, value }) => {
      value = sanitizeString(value);

      if (
        type !== 'setContact' ||
        type === 'setEmail' ||
        type === 'setName' ||
        type === 'setMondayItemId'
      )
        dispatch({ type, [action]: value });

      const client = prop.client || {};
      client[action] = value.trim();
      save({ client }, false, false, () => {}, 0);
    };

    const updateProposal = ({ email, instanceId = '', currency }) => {
      const updateQuery = {};

      if (email) {
        updateQuery['signatureEmails'] = [...new Set([...prop.signatureEmails, email])];
      }

      if (currency) {
        updateQuery['currency'] = currency;
      }

      updateQuery['wixConnectedInstance'] = instanceId;

      save(
        updateQuery,
        true,
        false,
        () => {
          if (instanceId !== prop.wixConnectedInstance) {
            fetchWixProductsAndServicesAndInfo({
              variables: {
                instanceId: instanceId,
              },
            });
          }
        },
        0
      );
    };

    useImperativeHandle(
      ref,
      () => ({
        resetChosen: () => {
          setMrmsclient('');
          setMrmsSet(true);

          dispatch({ type: 'setName', name: '' });
          dispatch({ type: 'setContact', contact: '' });
          dispatch({ type: 'setDesc', desc: '' });
          dispatch({ type: 'setEmail', email: '' });

          // save data
          save({ client: { ...initialState } }, true, false, () => {}, 0);
        },
      }),
      [dispatch, save]
    );

    return (
      <Col className="wizard-inner-content">
        {fromAppVar() === 'monday' &&
        userVariables.monday &&
        Object.keys(userVariables.monday)?.length > 0 ? (
          <MondayClientName
            type="setContact"
            action="contact"
            label="CONTACT PERSON NAME"
            clientNameValue={state.contact || prop?.client?.contact || ''}
            onChange={onChange}
            updateProposal={updateProposal}
            language={prop.language}
            currency={prop.currency}
            setWixSiteProperties={setWixSiteProperties}
          />
        ) : userVariables?.wix?.length ? (
          <WixClientName
            type="setContact"
            action="contact"
            label="CONTACT PERSON NAME"
            clientNameValue={state.contact || prop?.client?.contact || ''}
            onChange={onChange}
            updateProposal={updateProposal}
            language={prop.language}
            currency={prop.currency}
            setWixSiteProperties={setWixSiteProperties}
          />
        ) : (
          <ClientName
            type="setContact"
            action="contact"
            clientNameValue={state.contact || prop?.client?.contact || ''}
            onChange={onChange}
            label="CONTACT PERSON NAME"
            placeholder="CONTACT PERSON NAME"
          />
        )}
        <Input
          label="COMPANY NAME"
          placeholder={configText('the company', true)}
          value={state.name}
          onChange={(e) =>
            onChange({
              type: 'setName',
              action: 'name',
              value: e.target.value,
            })
          }
        />
        <Input
          type="textarea"
          label="COMPANY DESCRIPTION"
          placeholder={configText('wizard company desc placeholder', true)}
          value={state.desc}
          onChange={(e) => onChange({ type: 'setDesc', action: 'desc', value: e.target.value })}
        />
        <Input
          label="CONTACT PERSON EMAIL"
          icon={
            <Tooltip
              overlayClassName="wizard-tooltip-email"
              title="Enter your prospect's email to send the proposal, schedule reminders, and utilize integrations"
              color="black"
              key="white">
              <InfoIcon />
            </Tooltip>
          }
          placeholder={configText('wizard company email', true)}
          type="email"
          value={state.email}
          onChange={(e) =>
            onChange({
              type: 'setEmail',
              action: 'email',
              value: e.target.value,
            })
          }
        />
      </Col>
    );
  }
);

ClientInformation.defaultProps = {};

ClientInformation.propTypes = {
  prop: PropTypes.instanceOf(Object).isRequired,
  configText: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  fetchWixProductsAndServicesAndInfo: PropTypes.func.isRequired,
  setWixSiteProperties: PropTypes.func.isRequired,
};

export default ClientInformation;
