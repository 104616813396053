import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useReactiveVar, useLazyQuery } from '@apollo/client';
import { notification } from 'antd';
import { convertToRaw, Modifier, EditorState } from 'draft-js';
import { useIntercom } from 'react-use-intercom';
import { v4 as uuidv4 } from 'uuid';

import * as _ from 'lodash';
import moment from 'moment';
// import download from 'downloadjs';

import { fontListEnglish, fontListHebrew } from 'pages/Proposal/constants/constants';
import {
  GET_SINGLE_PROPOSAL,
  DOWNLOAD_PROPOSAL,
  FETCH_OR_CREATE_WIX_PROPOSAL,
} from 'graphql/queries/proposalQueries';
import {
  UPDATE_PROPOSAL_MUTATION,
  UPDATE_PROPOSAL_CLIENT_MUTATION,
} from 'graphql/mutations/proposalMutation';
import { USER_DETAILS, USER_TEAMS_DETAILS } from 'graphql/queries/userQueries';
import { cache, getUserId, userVar } from 'graphql/cache';
import helpers, {
  /*findDraftObject, */ loadProposalFont,
  getLanguageCode,
  refineDraftObject,
} from 'helpers/proposal';
import * as wixHelpers from 'helpers';
import { formatLocale } from 'pages/Proposal/components/RichEditor/wix-components/wix-pricing-helper';
import { PROPOSAL_SUBSCRIPTION_TOPIC, ELEMENTS_VARIABLES } from 'constants/index';
import { UPDATE_PROPOSAL_SUBSCRIPTION } from 'graphql/queries/proposalSubscription';
import { USER_UPDATE } from 'graphql/mutations/userMutations';
import { GET_CONFIGURATION } from 'graphql/queries/configurationQueries';
import {
  FETCH_WIX_PRODUCTS_AND_SERVICES_AND_INFO,
  FETCH_MORE_WIX_PRODUCTS_AND_SERVICES,
} from 'graphql/queries/wixQueries';
import { GET_TEMPLATE, GET_SECTION } from 'graphql/queries/contentLibraryQueries';
import {
  UPDATE_LIBRARY_TEMPLATE_MUTATION,
  UPDATE_LIBRARY_SECTION_MUTATION,
  CONTENT_LIBRARY_ADD_ITEM,
} from 'graphql/mutations/contentLibraryMutations';
import { NoTemplateModal } from './components/Draft/DraftModals';
import { getBlockSelection } from './components/RichEditor/lib/selection';

import Loader from 'components/Loader';
import Draft from './components/Draft';
import Publish from './components/Publish';
import Wizard from './components/Wizard/Wizard';
import EmptyProposal from './components/EmptyProposal';
import ProposalHeaderBar from './components/ProposalHeaderBar';
import ProposalWixHeaderBar from './components/ProposalWixHeaderBar';
import commonUtils from 'utils/utils';
import AppContext from 'context/index';
import useWindowDimensions from 'hooks/useWindowDimensions';
import PublishContent from 'pages/Proposal/components/Publish/components/PublishContent';
import utils from 'utils/utils';

let __fontsLoaded = false;

const Proposal = (props) => {
  const user = useReactiveVar(userVar);
  const params = useParams();
  const { trackEvent } = useIntercom();
  const { path } = props;
  const { isOnline } = useContext(AppContext);
  const { isMobile } = useWindowDimensions();
  const tabID = sessionStorage.tabID ? sessionStorage.tabID : (sessionStorage.tabID = uuidv4());

  const type = path === '/PDF/:cid' ? commonUtils.getQueryStringValue('type') : '';

  const [prop, setProp] = useState('');
  const [template, setTemplate] = useState('');
  const [isEditingModal, setIsEditingModal] = useState(false);
  const [section, setSection] = useState('');
  const [config, setConfig] = useState({});
  const [wixProductsAndServices, setWixProductsAndServices] = useState([]);
  const [wixSiteProperties, setWixSiteProperties] = useState();
  const [usersTeam, setUsersTeam] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [loadingWixProductsAndServices, setLoadingWixProductsAndServices] = useState(false);
  const [shouldUndoRedoUpdate, setShouldUndoRedoUpdate] = useState(false);
  const [openVariablesList, setOpenVariablesList] = useState(false);
  const [scrollingTo, setScrollingTo] = useState('');
  const [savingDraftProposal, setSavingDraftProposal] = useState(false);
  const [proposalSettings, setProposalSettings] = useState({});
  const [isSavingForm, setIsSavingForm] = useState(false);
  const [openWixPreview, setOpenWixPreview] = useState(false);
  const [templateNodal, setNoTemplateModal] = useState(false);
  const [undoableProposalStack, setUndoableProposalStack] = useState({
    past: [],
    present: {},
    future: [],
  });
  const [networkOnline, setNetworkOnline] = useState(true);
  const [preventReload, setPreventReload] = useState(true);

  const undoableProposalStackRef = useRef(undoableProposalStack);
  const propRef = useRef(prop || template || section);
  const clientWixPreview = !!path?.includes('/proposal-preview') || type === 'wix-proposal';
  const templateWixPreview =
    !!path?.includes('/template-preview') || !!(utils.getQueryStringValue('for') === 'wix');
  const wixEditor = !!path?.includes('/proposal-editor');
  const templateWixEditor = !!path?.includes('/template-editor');
  const isWix = !!prop.wixProposalId?.length;
  const isOwner = utils.getQueryStringValue('owner') === 'true';

  const handleUpdateEditing = (proposal) => {
    propRef.current = proposal;
    setProp(proposal);
    if (
      window.location.href.indexOf('/cd/') > 0 ||
      window.location.href.indexOf('proposal-preview') > 0 ||
      window.location.href.indexOf('/d/') > 0
    ) {
      if (proposal.isEditing) {
        if (proposal.isEditing?.tabID === tabID && proposal.isEditing?.uid === user._id) {
          setIsEditingModal(false);
        } else {
          setIsEditingModal(true);
        }
      } else {
        setIsEditingModal(false);
      }
    }
  };

  const { loading: isFetchingUser, data: userInfo } = useQuery(USER_DETAILS, {
    variables: {
      id: getUserId(),
      type: window.instanceId ? 'wix-user' : '',
    },
    skip: !getUserId() || user || clientWixPreview || templateWixPreview,
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      const { fetchUser } = res;
      userVar({ ...user, ...fetchUser });
    },
  });

  useQuery(USER_TEAMS_DETAILS, {
    variables: { teamId: user?.teamId || getUserId() },
    skip: !getUserId() || !user || path !== '/pd/:id' || openWixPreview,
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ fetchUsers }) => {
      setUsersTeam(fetchUsers);
    },
  });

  const configText = useCallback(
    (x, notmarked, disableTrim) => {
      return helpers.configText({
        x,
        notmarked,
        config,
        prop,
        user,
        vars: {},
        disableTrim,
      });
    },
    [config, prop, user]
  );

  const [addItem] = useMutation(CONTENT_LIBRARY_ADD_ITEM, {
    onCompleted() {
      notification.success({
        description: 'Template saved successfully.',
      });
    },
    onError() {
      notification.error({
        description: 'Template save failed.',
      });
    },
  });

  const { loading: isFetchingProposal, subscribeToMore } = useQuery(GET_SINGLE_PROPOSAL, {
    notifyOnNetworkStatusChange: true,
    variables: {
      pid: params.pid,
      _id: params.id,
      cid: params.cid,
    },
    skip:
      prop ||
      path === '/t/:tid' ||
      path === '/pt/:tid' ||
      path === '/s/:sid' ||
      path === '/ps/:sid' ||
      window.proposalId ||
      window.templateId ||
      window.wixTemplateId ||
      (path === '/PDF/:cid' && type !== 'proposal' && type !== 'wix-proposal' && !!type),
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchProposal }) => {
      if (fetchProposal) {
        const updatedProposal = JSON.parse(JSON.stringify(fetchProposal));

        if (updatedProposal.dateFormat) {
          setProposalSettings({ dateFormat: updatedProposal.dateFormat });
        }

        handleUpdateEditing({
          ...updatedProposal,
          pricing: updatedProposal.pricing || { strategy: 'table' },
        });

        if (
          path === '/w/:pid' ||
          path === '/d/:pid' ||
          path === '/t/:tid' ||
          path === '/s/:sid' ||
          wixEditor
        ) {
          const undoStack = {
            past: [fetchProposal.draft],
            present: fetchProposal.draft,
            future: [],
          };
          undoableProposalStackRef.current = undoStack;
          setUndoableProposalStack(undoStack);
        }

        if (!__fontsLoaded) loadFonts(updatedProposal);
      }
    },
  });

  const { loading: isFetchingTemplate } = useQuery(GET_TEMPLATE, {
    variables: {
      _id: params.tid || params.cid || window.templateId,
      type: templateWixPreview ? 'wix-preview' : '',
      instanceId: templateWixPreview ? window.instanceId : '',
    },
    skip:
      template ||
      (path !== '/t/:tid' && path !== '/pt/:tid' && path !== '/PDF/:cid' && !templateWixPreview) ||
      (path === '/PDF/:cid' && type !== 'template'),
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchTemplate }) => {
      if (fetchTemplate) {
        let templateData = JSON.parse(JSON.stringify(fetchTemplate));

        propRef.current = templateData;
        setTemplate({ ...templateData, pricing: templateData.pricing || { strategy: 'table' } });

        if (path === '/d/:pid' || path === '/t/:tid' || path === '/s/:sid' || wixEditor) {
          const undoStack = {
            past: [fetchTemplate.draft],
            present: fetchTemplate.draft,
            future: [],
          };
          undoableProposalStackRef.current = undoStack;
          setUndoableProposalStack(undoStack);
        }

        if (!__fontsLoaded) loadFonts(templateData);
        if (templateWixPreview) window?.proposalApi?.onLoaded();
      }
    },
  });

  const { loading: isFetchingSection } = useQuery(GET_SECTION, {
    variables: {
      _id: params.sid,
    },
    skip: template || (path !== '/s/:sid' && path !== '/ps/:sid'),
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchLibrary }) => {
      if (fetchLibrary) {
        const sectionInfo = {
          ...JSON.parse(
            JSON.stringify({
              draft: { [fetchLibrary._id]: fetchLibrary, sectionorder: [fetchLibrary._id] },
            })
          ),
          language: fetchLibrary.language,
          isHeader: fetchLibrary.isHeader,
        };

        propRef.current = sectionInfo;
        setSection(sectionInfo);

        if (path === '/d/:pid' || path === '/t/:tid' || path === '/s/:sid') {
          const undoStack = {
            past: [{ draft: { [fetchLibrary._id]: fetchLibrary } }],
            present: { draft: { [fetchLibrary._id]: fetchLibrary } },
            future: [],
          };
          undoableProposalStackRef.current = undoStack;
          setUndoableProposalStack(undoStack);
        }

        if (!__fontsLoaded) loadFonts(sectionInfo);
      }
    },
  });

  const { loading: isFetchingOrCreatingWixProposal } = useQuery(FETCH_OR_CREATE_WIX_PROPOSAL, {
    notifyOnNetworkStatusChange: true,
    variables: {
      wixTemplateId: window.wixTemplateId,
      templateId: window.templateId,
      wixProposalId: window.proposalId,
      type: openWixPreview ? 'preview' : clientWixPreview ? 'client' : 'editor',
    },
    skip: (!window.proposalId && !window.templateId) || prop,
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchOrCreateWixProposal }) => {
      if (fetchOrCreateWixProposal) {
        const updatedProposal = JSON.parse(JSON.stringify(fetchOrCreateWixProposal));

        if (!updatedProposal.wixPricing.locale) {
          updatedProposal.wixPricing.locale = {
            language: 'en',
            country: 'US',
          };
        }

        const locale = formatLocale(updatedProposal?.wixPricing);
        updatedProposal.dateFormat = wixHelpers.formatDateByRegion(locale);

        if (updatedProposal.noTemplate) {
          // show modal
          setNoTemplateModal(true);
          delete updatedProposal.noTemplate;
        } else {
          delete updatedProposal.noTemplate;

          if (updatedProposal.dateFormat) {
            setProposalSettings({ dateFormat: updatedProposal.dateFormat });
          }

          handleUpdateEditing({
            ...updatedProposal,
            pricing: updatedProposal.pricing || { strategy: 'table' },
          });

          if (path === '/d/:pid' || path === '/t/:tid' || path === '/s/:sid' || wixEditor) {
            const undoStack = {
              past: [fetchOrCreateWixProposal.draft],
              present: fetchOrCreateWixProposal.draft,
              future: [],
            };
            undoableProposalStackRef.current = undoStack;
            setUndoableProposalStack(undoStack);
          }

          if (!__fontsLoaded) loadFonts(updatedProposal);
          window?.proposalApi?.onLoaded();
        }
      }
    },
  });

  const [loadConfig, { loading: isFetchingConfiguration }] = useLazyQuery(GET_CONFIGURATION, {
    fetchPolicy: 'cache-and-network',
    skip: config,
    onCompleted: (data) => {
      setConfig(data?.fetchConfiguration || {});
    },
  });

  const [fetchWixProductsAndServicesAndInfo] = useLazyQuery(
    FETCH_WIX_PRODUCTS_AND_SERVICES_AND_INFO,
    {
      fetchPolicy: 'network-only',
      skip:
        (!user && !loadingWixProductsAndServices) ||
        path === '/PDF/:cid' ||
        path === '/pd/:id' ||
        path === '/cd/:cid',
      onCompleted: ({ fetchWixProductsAndServices }) => {
        setWixProductsAndServices(fetchWixProductsAndServices || {});
        if (prop.wixConnectedInstance) {
          const connectedWixServices = fetchWixProductsAndServices?.services?.find(
            (service) => service.instanceId === prop.wixConnectedInstance
          );
          if (connectedWixServices) {
            setWixSiteProperties(
              {
                name: connectedWixServices.name,
                description: connectedWixServices.description,
                logo: connectedWixServices.logo,
                domain: connectedWixServices.domain,
              } || {}
            );
          }
        } else {
          setWixSiteProperties(
            {
              name: '',
              description: '',
              logo: '',
              domain: '',
            } || {}
          );
        }
        setLoadingWixProductsAndServices(false);
      },
      onError: (err) => {
        setLoadingWixProductsAndServices(false);
        console.log(err, 'wix error');
      },
    }
  );

  const [fetchMoreWixProductsAndServices, { loading: fetchMoreWixProductsAndServicesLoading }] =
    useLazyQuery(FETCH_MORE_WIX_PRODUCTS_AND_SERVICES, {
      fetchPolicy: 'network-only',
      onCompleted: ({ fetchMoreWixProductsAndServices }) => {
        let newWixProductsAndServices = { ...wixProductsAndServices };

        if (fetchMoreWixProductsAndServices.type === 'products') {
          if (fetchMoreWixProductsAndServices.instanceId) {
            newWixProductsAndServices['products'] = wixProductsAndServices?.products.map(
              (product) => {
                if (product.instanceId === fetchMoreWixProductsAndServices.instanceId) {
                  return {
                    ...product,
                    fields: [...product.fields, ...fetchMoreWixProductsAndServices.fields],
                  };
                } else {
                  return product;
                }
              }
            );
          } else {
            newWixProductsAndServices.products[0] = {
              ...newWixProductsAndServices.products[0],
              fields: {
                ...wixProductsAndServices.products[0]?.fields,
                ...fetchMoreWixProductsAndServices.fields,
              },
            };
          }
        } else {
          if (fetchMoreWixProductsAndServices.instanceId) {
            newWixProductsAndServices['services'] = wixProductsAndServices?.services.map(
              (service) => {
                if (service.instanceId === fetchMoreWixProductsAndServices.instanceId) {
                  return {
                    ...service,
                    fields: [...service.fields, ...fetchMoreWixProductsAndServices.fields],
                  };
                } else {
                  return service;
                }
              }
            );
          } else {
            newWixProductsAndServices.services[0] = {
              ...newWixProductsAndServices.services[0],
              fields: {
                ...wixProductsAndServices.services[0]?.fields,
                ...fetchMoreWixProductsAndServices.fields,
              },
            };
          }
        }

        setWixProductsAndServices(newWixProductsAndServices || {});
      },
      onError: (err) => {
        setLoadingWixProductsAndServices(false);
        console.log(err, 'wix error');
      },
    });

  const [generatePDF, { loading: downloadingPDF }] = useLazyQuery(DOWNLOAD_PROPOSAL, {
    skip: prop === null,
    fetchPolicy: 'cache-and-network',
    variables: {
      origin: window.location.origin,
      proposalId: prop ? prop._id : template ? template._id : '',
      ...(!user ? { cid: prop._id } : {}),
    },
  });

  const downloadFile = (url) => {
    // if (prop.wixProposalId) {
    //   const fileName = prop ? `${utils.getTitleText(prop, 'header')}_${prop._id}.pdf` : '';
    //   download(url, fileName, 'application/pdf');
    // } else {
    const link = document.createElement('a');
    link.href = url;
    link.download = '';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // }
  };

  const downloadPdf = ({ onDownloadCompleted, onErrorPopupClose }) => {
    const onCompleted = (data) => {
      // no need to prevent reload if user downloaded pdf
      setPreventReload(false);
      try {
        window.ga('send', 'event', 'pdf', 'click-download', window.location.href);
        trackEvent('click-download-pdf', {
          pid: prop?.pid,
          id: prop?._id,
          url: window.location.origin + '/pd/' + prop?._id,
        });
      } catch (error) {
        console.log(error);
      }
      downloadFile(data.downloadProposal);
      onDownloadCompleted?.();
    };
    const onError = (error) => {
      setPreventReload(false);
      commonUtils.showErrorMessage({
        title: 'Problem Downloading PDF',
        message: `Please try in a few minutes or contact support. Error ${error.message}`,
        onClose: () => onErrorPopupClose?.(),
      });
    };

    generatePDF({
      onCompleted,
      onError,
    });
  };

  useEffect(() => {
    if ((prop || template || section) && !Object.keys(config)?.length) {
      loadConfig({
        variables: {
          language:
            prop?.language?.toLowerCase() ||
            template?.language?.toLowerCase() ||
            section?.language?.toLowerCase() ||
            'english',
        },
      });
    }
  }, [prop, template, section, config, loadConfig]);

  useEffect(() => {
    if (
      (prop || template || section) &&
      user?.wix?.length &&
      !Object.keys(wixProductsAndServices)?.length
    ) {
      setLoadingWixProductsAndServices(true);
      if (
        !loadingWixProductsAndServices &&
        path !== '/PDF/:cid' &&
        path !== '/pd/:id' &&
        path !== '/cd/:cid' &&
        !wixEditor &&
        !templateWixEditor &&
        !templateWixPreview &&
        !clientWixPreview
      ) {
        fetchWixProductsAndServicesAndInfo({
          variables: {
            instanceId: prop?.wixConnectedInstance,
          },
        });
      }
    }
  }, [prop, template, section, wixProductsAndServices, fetchWixProductsAndServicesAndInfo, user]); //eslint-disable-line

  useEffect(() => {
    if (!isOnline && networkOnline) {
      setNetworkOnline(false);
    } else {
      if (!networkOnline && isOnline && prop) {
        const unsavedProposal = localStorage.getItem('unsavedproposal');

        if (unsavedProposal) {
          const proposal = JSON.parse(unsavedProposal);
          localStorage.setItem('unsavedproposal', '');

          if (proposal.templateName) {
            updateTemplate({
              variables: {
                topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
                  proposal?.channel || proposal?.auid || proposal?.uid
                }`,
                updateType: 'edit',
                template: { ...proposal, id: proposal._id },
              },
            });
          } else if (proposal.sectionId) {
            updateSection({
              variables: {
                topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
                  proposal?.channel || proposal?.auid || proposal?.uid
                }`,
                updateType: 'edit',
                section: {
                  ...proposal.draft[proposal.sectionId],
                  thumbnail: '',
                },
              },
            });
          } else {
            setProp(proposal);

            updateProposalPreHandler(
              {
                variables: {
                  topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
                    proposal?.channel || proposal?.auid || proposal?.uid
                  }`,
                  updateType: 'edit',
                  proposal: {
                    ...proposal,
                    id: proposal._id,
                  },
                },
              },
              proposal
            );
          }
        }
        setNetworkOnline(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline, prop]);

  const subscribeToUpdateProposal = () => {
    return subscribeToMore({
      document: UPDATE_PROPOSAL_SUBSCRIPTION,
      variables: { topic: `PROPOSALS_${prop.channel || prop.auid || prop._id}` },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        const { typename, proposal } = subscriptionData.data.updateProposalSubscription;

        if (
          user &&
          user._id !== proposal.uid &&
          user.teamId !== proposal.auid &&
          user._id !== proposal.auid &&
          user.teamId !== proposal.uid
        )
          return;

        if (params.cid && params.cid !== proposal._id) {
          return;
        }

        if (params.id && params.id !== proposal._id) {
          return;
        }

        if (params.sid && params.sid !== proposal._id) {
          return;
        }

        if (params.tid && params.tid !== proposal._id) {
          return;
        }

        if (params.pid && params.pid !== proposal.pid) {
          return;
        }

        if (window.proposalId && window.proposalId !== proposal.wixProposalId) {
          return;
        }

        let { draft } = propRef.current;
        draft = {
          ...draft,
          ...proposal.draft,
        };
        proposal.draft = draft;

        if (typename === 'UPDATE_PROPOSAL') {
          // if same tab ignore the data
          if (
            !!propRef?.current?.isEditing?.tabID &&
            propRef?.current?.isEditing?.tabID === proposal?.isEditing?.tabID &&
            propRef?.current?.isEditing?.tabID === tabID
          ) {
            return;
          }

          handleUpdateEditing(proposal);

          return { fetchProposal: proposal };
        } else if (typename === 'UPDATE_CLIENT_PROPOSAL') {
          handleUpdateEditing(proposal);

          return { fetchProposal: proposal };
        } else if (typename === 'UPDATE_TEMPLATE') {
          propRef.current = proposal;
          setTemplate(JSON.parse(JSON.stringify(proposal)));
        }
      },
    });
  };

  const [userway, setUserway] = useState({
    language: '',
    position: 0,
  });

  useEffect(() => {
    if (prop._id && (path === '/pd/:id' || path === '/cd/:cid')) {
      let proposalHasUserway = prop.hasOwnProperty('userway') && prop.userway !== null;

      // if /pd then use user.userway
      const creatorUserway = (path === '/pd/:id' ? user?.userway : prop?.creatorUserway) === true;

      // disabled for this proposal
      if (
        (proposalHasUserway && prop.userway === true) ||
        (!proposalHasUserway && creatorUserway)
      ) {
        const widgetAddedToDom = !!document.getElementById('userway-script');
        const isRtl = utils.isRTLProposal(prop);
        const languageCode = getLanguageCode(prop.language?.toLowerCase());
        const position = userwayPosition(isRtl);

        const newUserway = {};

        if (userway.language !== languageCode) {
          newUserway.language = languageCode;
        }
        if (userway.position !== position) {
          newUserway.position = position;
        }

        if (widgetAddedToDom) {
          let userwayDom = document.getElementsByClassName('uwy');
          if (userwayDom?.length > 0) {
            try {
              window.UserWay.iconVisibilityOn();

              if (userway.language !== languageCode) {
                window.UserWay.changeWidgetLanguage(languageCode);
              }
            } catch (e) {}

            // icon position
            if (userway.position !== position) {
              [userwayDom] = userwayDom;
              const activeClass = `userway_p${position}`;
              const inactiveClass = ['userway_p1', 'userway_p2', 'userway_p6', 'userway_p7'].filter(
                (f) => f !== activeClass
              );

              userwayDom.classList.remove(...inactiveClass);
              userwayDom.classList.add(activeClass);
            }
          }
        } else {
          const script = document.createElement('script');
          script.setAttribute('src', 'https://cdn.userway.org/widget.js');
          script.setAttribute('data-account', 'YQuDIqtDQg');
          script.setAttribute('id', 'userway-script');
          script.setAttribute('data-position', position);
          script.setAttribute('data-language', languageCode);
          document.body.appendChild(script);
        }

        if (Object.keys(newUserway).length > 0) {
          setUserway({ ...userway, ...newUserway });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop.userway, path, isMobile]);

  const userwayPosition = (isRtl) => {
    // 7(top left), 1(top right) - mobile,  HE, EN
    if (isMobile) {
      return isRtl ? 7 : 1;
    }
    // 2(right middle), 6(left middle) - desktop, HE, EN
    return isRtl ? 2 : 6;
  };

  const [saveUser] = useMutation(USER_UPDATE, {
    async onCompleted(data) {
      cache.evict('fetchUser');
      userVar({ ...user, ...data.updateUser });
    },
  });

  const [updateProposal] = useMutation(UPDATE_PROPOSAL_MUTATION, {
    onCompleted: ({ updateProposal }) => {
      // if (
      //   !propRef.current.draft ||
      //   !propRef.current.draft.signature ||
      //   propRef.current.draft.sectionorder
      // ) {
      //   let { draft } = propRef.current;
      //   draft = {
      //     ...draft,
      //     ...updateProposal.draft,
      //   };
      // updateProposal.draft = draft;
      // }
      if (
        (updateProposal.state === 'approved' && propRef.current.state !== updateProposal.state) ||
        !_.isEqual(propRef.current.draft.variables, updateProposal.draft.variables)
      ) {
        propRef.current = { ...propRef.current, ...updateProposal };
        setProp({ ...propRef.current, ...updateProposal });
      }
    },
    // onError: () => {},
  });

  const [updateProposalClient] = useMutation(UPDATE_PROPOSAL_CLIENT_MUTATION, {
    onCompleted: ({ updateProposalClient }) => {
      propRef.current = updateProposalClient;
      setProp(updateProposalClient);
    },
  });

  const [updateTemplate] = useMutation(UPDATE_LIBRARY_TEMPLATE_MUTATION, {
    onCompleted: ({ updateTemplate }) => {
      let { draft } = propRef.current;
      draft = {
        ...draft,
        ...updateTemplate.draft,
      };
      updateTemplate.draft = draft;
      propRef.current = updateTemplate;
      setProp(updateTemplate);
    },
    onError: () => {},
  });

  const [updateSection] = useMutation(UPDATE_LIBRARY_SECTION_MUTATION, {
    onCompleted: ({ updateLibrary }) => {
      const updatedSection = {
        ...JSON.parse(
          JSON.stringify({
            draft: {
              [updateLibrary._id]: {
                ...propRef.current.draft[updateLibrary._id],
                ...updateLibrary,
              },
              sectionorder: [updateLibrary._id],
            },
          })
        ),
        language: updateLibrary.language,
      };
      propRef.current = updatedSection;
      setProp(updatedSection);
    },
    onError: () => {},
  });

  const updateLoaderState = (shouldShowLoader) => {
    setShowLoader(shouldShowLoader);
  };

  const updateProposalPreHandler = (context, proposal) => {
    // update the proposal before making api call
    propRef.current = proposal;
    setProp(proposal);

    return new Promise((resolve, reject) => {
      updateProposal(context)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  };

  const handleUpdateTemplateProposalSection = (
    templateProposalSection,
    handleSuccess = () => {},
    handleError = () => {}
  ) => {
    const { from } = templateProposalSection;
    delete templateProposalSection.from;
    if (path === '/d/:pid' || path === '/w/:pid' || wixEditor) {
      const oldProp = JSON.parse(JSON.stringify(propRef.current));
      let tempProp = {
        ...propRef.current,
        ...templateProposalSection,
        draft: { ...propRef.current.draft, ...(templateProposalSection.draft || {}) },
      };

      let tempShouldUndoRedoUpdate = shouldUndoRedoUpdate;
      if (from) {
        tempShouldUndoRedoUpdate = true;
      }

      if (
        (path === '/d/:pid' || path === '/w/:pid' || wixEditor) &&
        templateProposalSection.draft?.sectionorder &&
        (!prop.draft || !prop.draft.signature || !prop.draft.sectionorder)
      ) {
        undoableProposalStackRef.current = {
          past: [],
          present: tempProp.draft,
          future: [],
        };
        setUndoableProposalStack({
          past: [],
          present: tempProp.draft,
          future: [],
        });
      }

      if (templateProposalSection.draft)
        handleProposalUndoRedo(null, tempProp.draft, from, tempShouldUndoRedoUpdate);

      updateProposalPreHandler(
        {
          variables: {
            topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
              templateProposalSection?.channel ||
              templateProposalSection?.auid ||
              templateProposalSection?.uid
            }`,
            updateType: 'edit',
            proposal: { id: tempProp._id, ...tempProp },
          },
        },
        tempProp
      ).then(
        (response) => {
          response.data = response?.data || templateProposalSection;
          if (
            (!prop.draft || !prop.draft.signature || !prop.draft.sectionorder) &&
            response?.data?.updateProposal
          ) {
            const proposal = JSON.parse(
              JSON.stringify({ ...propRef.current, ...response.data.updateProposal })
            );
            propRef.current = { ...proposal };
            setProp({ ...proposal });
          }

          if (
            (response?.errors?.message === 'Failed to fetch' && path === '/d/:pid') ||
            (response?.errors?.message === 'Failed to fetch' && wixEditor)
          ) {
            propRef.current = { ...prop, draft: templateProposalSection.draft };
            setProp({ ...prop, draft: templateProposalSection.draft });
            window.localStorage.setItem(
              'unsavedproposal',
              JSON.stringify({ ...prop, draft: templateProposalSection.draft })
            );
          }

          updateLoaderState(false);
          handleSuccess(response);

          setSavingDraftProposal(false);
          if (preventReload) {
            window.onbeforeunload = () => {};
          }
        },
        (error) => {
          if (error.message === 'Proposal state is different.') {
            notification.destroy();
            notification.error({
              message: "Proposal can't be edited",
              description: 'Proposal is not in Draft state',
            });
          }
          updateLoaderState(false);
          setSavingDraftProposal(false);
          propRef.current = oldProp;
          setProp(oldProp);
          handleError(error);
        }
      );
    } else if (path === '/t/:tid' || templateWixEditor) {
      const oldProp = propRef.current;
      let tempProp = {
        ...propRef.current,
        ...templateProposalSection,
        draft: { ...propRef.current.draft, ...(templateProposalSection.draft || {}) },
      };
      propRef.current = tempProp;
      setTemplate(tempProp);

      let tempShouldUndoRedoUpdate = shouldUndoRedoUpdate;
      if (from) {
        tempShouldUndoRedoUpdate = true;
      }

      if (templateProposalSection.draft)
        handleProposalUndoRedo(null, tempProp.draft, from, tempShouldUndoRedoUpdate);

      updateTemplate({
        variables: {
          topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
            templateProposalSection?.channel ||
            templateProposalSection?.auid ||
            templateProposalSection?.uid
          }`,
          template: { id: tempProp._id, ...tempProp },
          templateUrl: '',
        },
      }).then(
        (response) => {
          response.data = response?.data || templateProposalSection;

          if (
            (!template.draft || !template.draft.signature || !template.draft.sectionorder) &&
            response?.data?.updateTemplate
          ) {
            const proposal = JSON.parse(
              JSON.stringify({ ...propRef.current, ...response.data.updateTemplate })
            );
            propRef.current = { ...proposal };
            setProp({ ...proposal });
          }

          if (
            (response?.errors?.message === 'Failed to fetch' && path === '/t/:tid') ||
            (response?.errors?.message === 'Failed to fetch' && wixEditor)
          ) {
            propRef.current = { ...template, draft: templateProposalSection.draft };
            setTemplate({ ...template, draft: templateProposalSection.draft });
            window.localStorage.setItem(
              'unsavedproposal',
              JSON.stringify({ ...template, draft: templateProposalSection.draft })
            );
          }

          updateLoaderState(false);
          handleSuccess(response);

          setSavingDraftProposal(false);
          if (preventReload) {
            window.onbeforeunload = () => {};
          }
        },
        (error) => {
          setSavingDraftProposal(false);
          propRef.current = oldProp;
          setTemplate(oldProp);
          handleError(error);
        }
      );
    } else if (path === '/s/:sid') {
      const oldProp = propRef.current;
      let tempProp = {
        ...propRef.current,
        ...templateProposalSection,
        draft: { ...propRef.current.draft, ...(templateProposalSection.draft || {}) },
      };
      propRef.current = tempProp;
      setSection(tempProp);

      let tempShouldUndoRedoUpdate = shouldUndoRedoUpdate;
      if (from) {
        tempShouldUndoRedoUpdate = true;
      }

      if (templateProposalSection.draft)
        handleProposalUndoRedo(null, tempProp.draft, from, tempShouldUndoRedoUpdate);

      updateSection({
        variables: {
          section: {
            ...section?.draft?.[params.sid],
            ...tempProp?.draft?.[params.sid],
            thumbnail: '',
          },
        },
      }).then(
        (response) => {
          response.data = response?.data || templateProposalSection;

          if (
            (!section.draft || !section.draft.signature || !section.draft.sectionorder) &&
            response?.data?.updateLibrary
          ) {
            const proposal = JSON.parse(
              JSON.stringify({ ...propRef.current, ...response.data.updateLibrary })
            );
            propRef.current = { ...proposal };
            setProp({ ...proposal });
          }

          if (response?.errors?.message === 'Failed to fetch' && path === '/s/:sid') {
            propRef.current = { ...template, draft: templateProposalSection.draft };
            setSection({ ...template, draft: templateProposalSection.draft });
            window.localStorage.setItem(
              'unsavedproposal',
              JSON.stringify({ ...templateProposalSection, sectionId: params.sid })
            );
          }

          updateLoaderState(false);
          handleSuccess(response);

          setSavingDraftProposal(false);
          if (preventReload) {
            window.onbeforeunload = () => {};
          }
        },
        (error) => {
          setSavingDraftProposal(false);
          propRef.current = oldProp;
          setTemplate(oldProp);
          handleError(error);
        }
      );
    }
  };

  const getOccuranceCount = (text, value) => {
    if (!text?.length) {
      return 0;
    }

    return (text.match(new RegExp(value, 'g')) || []).length;
  };

  const getVariableOccuranceCount = (occurrences, block, variables, variableKeys) => {
    // level 1 - sender
    variableKeys.forEach((value) => {
      // level 2 - sender.firstName
      Object.keys(variables[value]).forEach((variable) => {
        occurrences[value][variable].count += getOccuranceCount(
          block.text,
          `{{${value}.${variable}}}`
        );
      });
    });

    return occurrences;
  };

  //eslint-disable-next-line
  const saveTemplateProposal = (what, handleSuccess = () => {}, handleError = () => {}) => {
    const { from } = what;
    delete what.from;

    console.log('What is saving', what);
    setScrollingTo('');
    setSavingDraftProposal(true);
    window.onbeforeunload = () => {
      return 'Changes you made may not be saved yet.';
    };

    const { _id, pid } = prop || template;
    const propData = propRef.current || prop || template || section;
    // let { draft } = findDraftObject({
    let newDraft = what.draft
      ? what.draft
      : refineDraftObject({
          what,
          proposal: propRef.current || prop || template || section,
        });

    propData.draft = {
      ...propData.draft,
      ...newDraft,
    };

    const rest = {};

    if (from === 'wizard' && what.state === 'edit') {
      const occurrences = JSON.parse(
        JSON.stringify(propData?.draft?.variables || ELEMENTS_VARIABLES)
      );

      if (propData?.draft?.variables) {
        /**
         * Sender variables
         */
        occurrences.sender = {
          ...occurrences.sender,
          email: {
            count: occurrences?.sender?.email?.count || 0,
            value: !!propData.email ? propData.email : '',
          },
          phone: {
            count: occurrences?.sender?.phone?.count || 0,
            value: !!propData.contactnumber ? propData.contactnumber : '',
          },
          company: {
            count: occurrences?.sender?.company?.count || 0,
            value: !!userVar()?.profile?.companyname ? userVar()?.profile?.companyname : '',
          },
          VAT: {
            count: occurrences?.sender?.VAT?.count || 0,
            value: !!userVar()?.profile?.vatnumber ? userVar()?.profile?.vatnumber : '',
          },
        };

        if (propData?.signature?.myname || propData?.draft?.signature?.myname || propData?.name) {
          const senderNames = (
            propData?.signature?.myname ||
            propData?.draft?.signature?.myname ||
            propData?.name
          ).split(' ');

          // eslint-disable-next-line prefer-destructuring
          occurrences.sender.firstName.value = senderNames[0];
          occurrences.sender.lastName.value = senderNames.slice(1, senderNames.length).join(' ');
          occurrences.sender.fullName.value = senderNames.join(' ');
        }

        newDraft = { ...newDraft, variables: occurrences };
      }
    } else {
      let occurrences = JSON.parse(
        JSON.stringify(propData?.draft?.variables || ELEMENTS_VARIABLES)
      );
      if (propData?.draft?.variables) {
        Object.keys(occurrences).forEach((value) => {
          Object.keys(occurrences[value]).forEach((variable) => {
            occurrences[value][variable].count = 0;
          });
        });

        const variableKeys = Object.keys(propData.draft.variables);

        // get variable occurrence count
        Object.keys(propData.draft).forEach((key) => {
          const keyExists = propData.draft?.sectionorder?.indexOf(key) > -1;

          if (key === 'header' || key === 'signature' || keyExists) {
            let blockKeys = [];

            if (key === 'header') {
              blockKeys = ['rawtitle', 'rawsubtitle', 'rawcontact', 'rawname', 'rawemail'];
            } else if (key === 'signature') {
              blockKeys = ['rawtitle', 'rawmyname', 'rawname'];
            } else {
              blockKeys = ['rawtitle', 'subrawtitle', 'raw', 'raw1', 'raw2', 'raw3'];
            }

            // loop for each block
            blockKeys.forEach((blockKey) => {
              // check if block exists
              if (propData.draft?.[key]?.[blockKey]?.blocks?.length) {
                propData.draft[key][blockKey].blocks.forEach((block) => {
                  // get and update the variable occurrence count
                  getVariableOccuranceCount(
                    occurrences,
                    block,
                    propData.draft.variables,
                    variableKeys
                  );
                });
              }
            });
          }
        });

        if (what?.expiryDate || what?.expiryDate === null) {
          /**
           * Proposal variables
           */
          occurrences.proposal = {
            ...occurrences.proposal,
            expireDate: {
              count: occurrences.proposal.expireDate.count || 0,
              value: what?.expiryDate ? moment(what.expiryDate).format('DD-MM-YYYY') : null,
            },
          };
        }

        newDraft = { ...newDraft, variables: occurrences };
      }
    }

    for (const [key, value] of Object.entries(what)) {
      if (!key.includes('draft')) {
        rest[key] = value;
      }
    }

    if (newDraft.signature) {
      const clientNames = newDraft?.signature?.rawname
        ? newDraft?.signature?.rawname.blocks[0].text.split(' ')
        : newDraft?.signature?.name.split(' ');

      rest.client = {
        ...propData.client,
        contact: clientNames.join(' '),
      };
    }

    const rawkeys = [
      'rawtitle',
      'subrawtitle',
      'rawsubtitle',
      'raw',
      'raw1',
      'raw2',
      'raw3',
      'rawcontact',
      'rawname',
      'rawemail',
      'rawmyname',
      'rawname',
    ];

    newDraft = JSON.parse(JSON.stringify(newDraft));
    // remove duplicate inlineStyleRanges
    Object.keys(newDraft).forEach((key) => {
      if (key !== 'variables') {
        rawkeys.forEach((rawkey) => {
          if (newDraft?.[key]?.[rawkey]?.blocks) {
            newDraft[key][rawkey].blocks.forEach((block, index) => {
              newDraft[key][rawkey].blocks[index].inlineStyleRanges = inlineStyleRangesReduction(
                block.inlineStyleRanges
              );
            });
          }
        });
      }
    });

    handleUpdateTemplateProposalSection(
      {
        from,
        id: _id,
        _id,
        pid,
        uid: prop.uid || template.uid || section.uid,
        auid: prop.auid || template.auid || section.uid,
        draft: newDraft,
        edited: new Date(),
        templateUrl: '',
        editEvent: prop.editEvent,
        channel: prop.channel,
        ...rest,
      },
      (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => handleError && handleError(error)
    );
  };

  const inlineStyleRangesReduction = (inlineStyleRanges) => {
    if (!inlineStyleRanges?.length) {
      return [];
    }
    const uniqueStyles = new Map();
    const result = [];

    for (let i = inlineStyleRanges.length - 1; i >= 0; i--) {
      const inlineStyle = inlineStyleRanges[i];
      // dont save highlights
      if (inlineStyle.style === 'HIGHLIGHT') {
        continue;
      }

      if (inlineStyle.style && inlineStyle.style.includes('rgba(')) {
        const key = `${inlineStyle.offset}-${inlineStyle.length}-${inlineStyle.style.substring(
          0,
          4
        )}`;
        if (!uniqueStyles.has(key)) {
          uniqueStyles.set(key, true);
          result.unshift(inlineStyle);
        }
      } else {
        result.unshift(inlineStyle);
      }
    }

    return result;
  };

  const fieldChanged = (fname, raw, name, fieldChangedSuccess = () => {}) => {
    if (prop) {
      prop.editEvent = true;
    }
    localStorage.setItem('editEvent', true);

    if (name === 'header') {
      localStorage.setItem('headerChangeEvent', true);
    }

    const tempProp = prop || template || section;
    if (tempProp?.draft?.[name]?.image && _.isEqual(prop?.draft?.[name]?.raw, raw)) {
      updateLoaderState(true);
    }
    const templateProposalSectionInput = { [`draft.${name}.${fname}`]: raw };

    if (name === 'header' && fname === 'rawtitle' && path === '/t/:tid') {
      templateProposalSectionInput['templateName'] = raw?.blocks[0]?.text;
    }
    saveTemplateProposal(templateProposalSectionInput, fieldChangedSuccess);
  };

  const saveTemplateProposalSectionDraft = (name, fname) => {
    return (es, saveTemplateProposalSectionDraftSuccess = () => {}) => {
      let raw = convertToRaw(es.getCurrentContent());

      if (!_.isEqual(prop?.draft?.[name]?.[fname], raw)) {
        try {
          // Remove AI highlight if it exists
          let selectionState = es.getSelection();
          let newEditorState = es;

          if (selectionState && !selectionState.isCollapsed()) {
            const contentState = es.getCurrentContent();
            const blocks = contentState.getBlockMap();
            let newContentState = contentState;

            blocks.forEach((block) => {
              // remove HIGHLIGHT from the entire block
              newContentState = Modifier.removeInlineStyle(
                newContentState,
                getBlockSelection(block),
                'HIGHLIGHT-AI'
              );
            });

            newEditorState = EditorState.push(es, newContentState, 'change-inline-style');
            raw = convertToRaw(newEditorState.getCurrentContent());
          }
        } catch (error) {
          console.log('No Style');
        }
        fieldChanged(fname, raw, name, saveTemplateProposalSectionDraftSuccess);
      } else {
        saveTemplateProposalSectionDraftSuccess && saveTemplateProposalSectionDraftSuccess(false);
      }
    };
  };

  const handleProposalUndoRedo = (type, draftInfo, from, tempShouldUndoRedoUpdate) => {
    const { past, present, future } = undoableProposalStackRef.current;

    const { _id, pid, uid, auid, editEvent } = propRef.current;

    if (!past?.length && type === 'UNDO') {
      return;
    }

    switch (type) {
      case 'UNDO':
        const previous = past[past.length - 1];
        const newPast = past.slice(0, past.length - 1);

        handleUpdateTemplateProposalSection({
          id: _id,
          _id,
          pid,
          uid: uid,
          auid: auid,
          draft: previous,
          edited: new Date(),
          editEvent,
          channel: prop.channel,
          from: 'handleProposalUndoRedo',
        });
        undoableProposalStackRef.current = {
          past: newPast,
          present: previous,
          future: [present, ...future],
        };
        setUndoableProposalStack({
          past: newPast,
          present: previous,
          future: [present, ...future],
        });
        setShouldUndoRedoUpdate(true);
        break;
      case 'REDO':
        const [next] = future;
        const newFuture = future.slice(1);

        handleUpdateTemplateProposalSection({
          id: _id,
          _id,
          pid,
          uid: uid,
          auid: auid,
          draft: next,
          edited: new Date(),
          editEvent,
          channel: prop.channel,
          from: 'handleProposalUndoRedo',
        });
        undoableProposalStackRef.current = {
          past: [...past, present],
          present: next,
          future: newFuture,
        };
        setUndoableProposalStack({
          past: [...past, present],
          present: next,
          future: newFuture,
        });
        setShouldUndoRedoUpdate(true);
        break;
      default:
        // Delegate handling the action to the passed reducer
        if (!from) {
          const newPresent = { ...draftInfo };

          if (_.isEqual(present, newPresent)) {
            return setUndoableProposalStack(undoableProposalStackRef.current);
          }
          undoableProposalStackRef.current = {
            past: [...past, ...[present]],
            present: newPresent,
            future: [],
          };
          setUndoableProposalStack({
            past: [...past, ...[present]],
            present: newPresent,
            future: [],
          });
        }
        if (tempShouldUndoRedoUpdate) {
          setShouldUndoRedoUpdate(false);
        }
    }
  };

  const loadFonts = (proposalSectionTemplate) => {
    let prelist = [];
    const { language, draft } = proposalSectionTemplate;

    const titleFontFamily = draft?.titleFont?.family;
    const subtitleFontFamily = draft?.subTitleFont?.family;
    const bodyFontFamily = draft?.bodyFont?.family;

    if (language?.toLowerCase() === 'hebrew' || language?.toLowerCase() === 'arabic') {
      const defaultFont = user?.profile?.defaultProposalFont?.trim() || 'Assistant';
      const combinedFontList = (user?.uploadedFonts || propRef.current?.fonts || []).concat(
        fontListHebrew
      );
      prelist = combinedFontList.filter((fontList) =>
        [titleFontFamily, subtitleFontFamily, bodyFontFamily].includes(fontList.family)
      );
      if (!prelist.length) {
        prelist = [
          {
            family: defaultFont,
            source: 'Google',
            weights: [200, 300, 400, 600, 700, 800],
            italic: [],
            fallback: [],
          },
        ];
      }
    } else {
      const defaultFont = user?.profile?.defaultProposalFont?.trim() || 'Poppins';
      const combinedFontList = (user?.uploadedFonts || propRef.current?.fonts || []).concat(
        fontListEnglish
      );
      prelist = combinedFontList.filter((fontList) =>
        [titleFontFamily, subtitleFontFamily, bodyFontFamily].includes(fontList.family)
      );
      if (!prelist.length) {
        prelist = [
          {
            family: defaultFont,
            source: 'Google',
            weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
            type: 'sans-serif',
          },
        ];
      }
    }

    if (!__fontsLoaded) {
      __fontsLoaded = true;

      loadProposalFont(prelist, language, user, propRef.current);
    }
  };

  const handleFetchWixProductsAndServicesAndInfo = (value) => {
    setLoadingWixProductsAndServices(true);
    if (!loadingWixProductsAndServices) {
      fetchWixProductsAndServicesAndInfo(value);
    }
  };

  // we have pid or _id but didnt get the item, waiting for server publish, empty screen
  if (
    isFetchingProposal ||
    isFetchingUser ||
    isFetchingConfiguration ||
    isFetchingTemplate ||
    isFetchingSection ||
    isFetchingOrCreatingWixProposal ||
    (prop &&
      !Object.keys(config)?.length &&
      (params.pid || params.id || params.cid || window.proposalId))
  ) {
    return <Loader />;
  }

  if (!prop && !template && !section) {
    if (!params.pid && !params.id && !params.tid && !params.sid) {
      <>
        {templateNodal && (
          <NoTemplateModal wixEditor={wixEditor} templateWixEditor={templateWixEditor} />
        )}
        <ProposalHeaderBar />
        <EmptyProposal />
      </>;
    }
    if (wixEditor || templateWixEditor) {
      return (
        <>
          {templateNodal && (
            <NoTemplateModal wixEditor={wixEditor} templateWixEditor={templateWixEditor} />
          )}
          <ProposalWixHeaderBar status="empty" />
          <EmptyProposal />
        </>
      );
    }
    return (
      <>
        {templateNodal && (
          <NoTemplateModal wixEditor={wixEditor} templateWixEditor={templateWixEditor} />
        )}
        <ProposalHeaderBar />
        <EmptyProposal />
      </>
    );
  }

  if (
    path === '/d/:pid' ||
    path === '/t/:tid' ||
    path === '/s/:sid' ||
    ((wixEditor || templateWixEditor) && !openWixPreview)
  ) {
    return (
      <Draft
        user={user || userInfo?.fetchUser}
        savingDraftProposal={savingDraftProposal}
        setSavingDraftProposal={setSavingDraftProposal}
        saveProposalDraft={saveTemplateProposalSectionDraft}
        updateProposal={handleUpdateTemplateProposalSection}
        saveProposal={saveTemplateProposal}
        prop={JSON.parse(JSON.stringify(propRef.current || template || section))}
        config={config}
        setProp={setProp}
        downloadPdf={downloadPdf}
        openVariablesList={openVariablesList}
        setOpenVariablesList={setOpenVariablesList}
        scrollingTo={scrollingTo}
        setScrollingTo={setScrollingTo}
        downloadingPDF={downloadingPDF}
        saveUser={saveUser}
        handleUndoRedo={handleProposalUndoRedo}
        subscribeToUpdateProposal={subscribeToUpdateProposal}
        isTemplate={path === '/t/:tid' ? true : false}
        isSection={path === '/s/:sid' ? true : false}
        isHeader={
          path === '/s/:sid'
            ? section?.draft[section?.draft?.sectionorder[0]]?.isHeader ||
              propRef?.current?.draft[propRef?.current?.draft?.sectionorder[0]]?.isHeader
            : false
        }
        isEditingModal={isEditingModal}
        setIsEditingModal={setIsEditingModal}
        showLoaderProp={showLoader}
        updateLoaderState={updateLoaderState}
        propRef={propRef}
        shouldUndoRedoUpdate={shouldUndoRedoUpdate}
        wixProductsAndServices={wixProductsAndServices}
        wixSiteProperties={wixSiteProperties}
        fetchMoreWixProductsAndServicesLoading={fetchMoreWixProductsAndServicesLoading}
        fetchMoreWixProductsAndServices={fetchMoreWixProductsAndServices}
        configText={configText}
        wixEditor={wixEditor}
        templateWixEditor={templateWixEditor}
        openWixPreview={openWixPreview}
        setOpenWixPreview={setOpenWixPreview}
        addItem={addItem}
        tabID={tabID}
        {...props}
      />
    );
  }

  // for wix client preview if state is declined then dont show proposal
  if (
    isWix &&
    clientWixPreview &&
    !isOwner &&
    (prop?.state === 'declined' || prop?.state === 'edit')
  ) {
    return (
      <>
        <ProposalHeaderBar />
        <EmptyProposal />
      </>
    );
  }

  if (
    (path === '/pd/:id' ||
      path === '/cd/:cid' ||
      path === '/PDF/:cid' ||
      path === '/pt/:tid' ||
      path === '/ps/:sid' ||
      (wixEditor && openWixPreview) ||
      (templateWixEditor && openWixPreview) ||
      clientWixPreview) &&
    !isOwner
  ) {
    if (
      (path === '/cd/:cid' || clientWixPreview) &&
      (!propRef?.current?.published ||
        propRef?.current?.published === 'expired' ||
        propRef?.current?.state === 'deleted')
    ) {
      return <EmptyProposal status={propRef?.current?.published} />;
    }

    return (
      <Publish
        user={user || userInfo?.fetchUser}
        prop={prop || template || section}
        propRef={propRef}
        config={config}
        usersTeam={usersTeam}
        setProp={setProp}
        updateProposal={updateProposalPreHandler}
        saveProposal={saveTemplateProposal}
        updateProposalClient={updateProposalClient}
        templateWixPreview={templateWixPreview}
        templateUserWixPreview={openWixPreview && templateWixEditor}
        downloadPdf={downloadPdf}
        downloadingPDF={downloadingPDF}
        subscribeToUpdateProposal={subscribeToUpdateProposal}
        isEditingModal={isEditingModal}
        isTemplate={
          path === '/pt/:tid' || commonUtils.getQueryStringValue('type') === 'template'
            ? true
            : false
        }
        isSection={path === '/ps/:sid' ? true : false}
        isHeader={
          path === '/ps/:sid' ? section?.draft[section?.draft?.sectionorder[0]]?.isHeader : false
        }
        proposalSettings={proposalSettings}
        isSavingForm={isSavingForm}
        setIsSavingForm={setIsSavingForm}
        wixPreview={openWixPreview && wixEditor ? true : false}
        addItem={addItem}
        setOpenWixPreview={setOpenWixPreview}
        clientWixPreview={clientWixPreview}
        preventReload={preventReload}
        {...props}
      />
    );
  }

  if ((template && templateWixPreview) || (clientWixPreview && isOwner)) {
    return (
      <PublishContent
        config={config}
        prop={template || prop}
        templateWixPreview={!!template}
        previewTemplate={!!template}
        userWixPreview={!!prop}
      />
    );
  }

  return (
    <Wizard
      saveProposal={saveTemplateProposal}
      saveUser={saveUser}
      prop={propRef.current || prop}
      user={user || userInfo?.fetchUser}
      config={config}
      subscribeToUpdateProposal={subscribeToUpdateProposal}
      wixProductsAndServices={wixProductsAndServices}
      wixSiteProperties={wixSiteProperties}
      setWixSiteProperties={setWixSiteProperties}
      fetchWixProductsAndServicesAndInfo={handleFetchWixProductsAndServicesAndInfo}
      fetchMoreWixProductsAndServicesLoading={fetchMoreWixProductsAndServicesLoading}
      fetchMoreWixProductsAndServices={fetchMoreWixProductsAndServices}
      updateProposal={updateProposalPreHandler}
      {...props}
    />
  );
};

export default Proposal;
