export const DEFAULT_CURRENCY = 'USD';

export const CURRENCY_SYMBOL = {
  USD: '$',
  CAD: 'CA$',
  EUR: '€',
  AED: 'AED',
  AFN: 'Af',
  ALL: 'ALL',
  AMD: 'AMD',
  ARS: 'AR$',
  AUD: 'AU$',
  AZN: 'man.',
  BAM: 'KM',
  BDT: 'Tk',
  BGN: 'BGN',
  BHD: 'BD',
  BIF: 'FBu',
  BND: 'BN$',
  BOB: 'Bs',
  BRL: 'R$',
  BWP: 'BWP',
  BYN: 'Bɍ',
  BZD: 'BZ$',
  CDF: 'CDF',
  CHF: 'CHF',
  CLP: 'CL$',
  CNY: 'CN¥',
  COP: 'CO$',
  CRC: '₡',
  CVE: 'CV$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'Dkr',
  DOP: 'RD$',
  DZD: 'DZD',
  EEK: 'Ekr',
  EGP: 'EGP',
  ERN: 'Nfk',
  ETB: 'Br',
  GBP: '£',
  GEL: 'GEL',
  GHS: 'GH₵',
  GNF: 'FG',
  GTQ: 'GTQ',
  HKD: 'HK$',
  HNL: 'HNL',
  HRK: 'kn',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  IQD: 'IQD',
  IRR: 'IRR',
  ISK: 'Ikr',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'Ksh',
  KHR: 'KHR',
  KMF: 'CF',
  KRW: '₩',
  KWD: 'KD',
  KZT: 'KZT',
  LBP: 'L.L.',
  LKR: 'SLRs',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'MDL',
  MGA: 'MGA',
  MKD: 'MKD',
  MMK: 'MMK',
  MOP: 'MOP$',
  MUR: 'MURs',
  MXN: 'MX$',
  MYR: 'RM',
  MZN: 'MTn',
  NAD: 'N$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: 'NPRs',
  NZD: 'NZ$',
  OMR: 'OMR',
  PAB: 'B/.',
  PEN: 'S/.',
  PHP: '₱',
  PKR: 'PKRs',
  PLN: 'zł',
  PYG: '₲',
  QAR: 'QR',
  RON: 'RON',
  RSD: 'din.',
  RUB: '₽',
  RWF: 'RWF',
  SAR: 'SR',
  SDG: 'SDG',
  SEK: 'Skr',
  SGD: 'S$',
  SOS: 'Ssh',
  SYP: 'SY£',
  THB: '฿',
  TND: 'DT',
  TOP: 'T$',
  TRY: '₺',
  TTD: 'TT$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  UYU: '$U',
  UZS: 'UZS',
  VEF: 'Bs.F.',
  VND: '₫',
  XAF: 'FCFA',
  XOF: 'CFA',
  YER: 'YR',
  ZAR: 'R',
  ZMK: 'ZK',
  ZWL: 'ZWL$',
};

export const CURRENCY_FORMATS = [
  {
    symbol: '$',
    code: 'USD',
  },
  {
    symbol: '₪',
    code: 'ILS',
  },
  {
    symbol: '¥',
    code: 'JPY',
  },
  {
    symbol: 'CHF',
    code: 'CHF',
  },
  {
    symbol: '€',
    code: 'EUR',
  },
  {
    symbol: '£',
    code: 'GBP',
  },
  {
    symbol: 'R$',
    code: 'BRL',
  },
  {
    symbol: 'Rp',
    code: 'IDR',
  },
  {
    symbol: '₹',
    code: 'INR',
  },
  {
    symbol: '﷼',
    code: 'OMR',
  },
  {
    symbol: 'CA$',
    code: 'CAD',
  },
  {
    symbol: 'AED',
    code: 'AED',
  },
  {
    symbol: 'Af',
    code: 'AFN',
  },
  {
    symbol: 'ALL',
    code: 'ALL',
  },
  {
    symbol: 'AMD',
    code: 'AMD',
  },
  {
    symbol: 'AR$',
    code: 'ARS',
  },
  {
    symbol: 'AU$',
    code: 'AUD',
  },
  {
    symbol: 'man.',
    code: 'AZN',
  },
  {
    symbol: 'KM',
    code: 'BAM',
  },
  {
    symbol: 'Tk',
    code: 'BDT',
  },
  {
    symbol: 'BGN',
    code: 'BGN',
  },
  {
    symbol: 'BD',
    code: 'BHD',
  },
  {
    symbol: 'FBu',
    code: 'BIF',
  },
  {
    symbol: 'BN$',
    code: 'BND',
  },
  {
    symbol: 'Bs',
    code: 'BOB',
  },
  {
    symbol: 'BWP',
    code: 'BWP',
  },
  {
    symbol: 'Bɍ',
    code: 'BYN',
  },
  {
    symbol: 'BZ$',
    code: 'BZD',
  },
  {
    symbol: 'CDF',
    code: 'CDF',
  },
  {
    symbol: 'CL$',
    code: 'CLP',
  },
  {
    symbol: 'CN¥',
    code: 'CNY',
  },
  {
    symbol: 'CO$',
    code: 'COP',
  },
  {
    symbol: '₡',
    code: 'CRC',
  },
  {
    symbol: 'CV$',
    code: 'CVE',
  },
  {
    symbol: 'Kč',
    code: 'CZK',
  },
  {
    symbol: 'Fdj',
    code: 'DJF',
  },
  {
    symbol: 'Dkr',
    code: 'DKK',
  },
  {
    symbol: 'RD$',
    code: 'DOP',
  },
  {
    symbol: 'DZD',
    code: 'DZD',
  },
  {
    symbol: 'Ekr',
    code: 'EEK',
  },
  {
    symbol: 'EGP',
    code: 'EGP',
  },
  {
    symbol: 'Nfk',
    code: 'ERN',
  },
  {
    symbol: 'Br',
    code: 'ETB',
  },
  {
    symbol: 'GEL',
    code: 'GEL',
  },
  {
    symbol: 'GH₵',
    code: 'GHS',
  },
  {
    symbol: 'FG',
    code: 'GNF',
  },
  {
    symbol: 'GTQ',
    code: 'GTQ',
  },
  {
    symbol: 'HK$',
    code: 'HKD',
  },
  {
    symbol: 'HNL',
    code: 'HNL',
  },
  {
    symbol: 'kn',
    code: 'HRK',
  },
  {
    symbol: 'Ft',
    code: 'HUF',
  },
  {
    symbol: 'IQD',
    code: 'IQD',
  },
  {
    symbol: 'IRR',
    code: 'IRR',
  },
  {
    symbol: 'Ikr',
    code: 'ISK',
  },
  {
    symbol: 'J$',
    code: 'JMD',
  },
  {
    symbol: 'JD',
    code: 'JOD',
  },
  {
    symbol: 'Ksh',
    code: 'KES',
  },
  {
    symbol: 'KHR',
    code: 'KHR',
  },
  {
    symbol: 'CF',
    code: 'KMF',
  },
  {
    symbol: '₩',
    code: 'KRW',
  },
  {
    symbol: 'KD',
    code: 'KWD',
  },
  {
    symbol: 'KZT',
    code: 'KZT',
  },
  {
    symbol: 'L.L.',
    code: 'LBP',
  },
  {
    symbol: 'SLRs',
    code: 'LKR',
  },
  {
    symbol: 'Lt',
    code: 'LTL',
  },
  {
    symbol: 'Ls',
    code: 'LVL',
  },
  {
    symbol: 'LD',
    code: 'LYD',
  },
  {
    symbol: 'MAD',
    code: 'MAD',
  },
  {
    symbol: 'MDL',
    code: 'MDL',
  },
  {
    symbol: 'MGA',
    code: 'MGA',
  },
  {
    symbol: 'MKD',
    code: 'MKD',
  },
  {
    symbol: 'MMK',
    code: 'MMK',
  },
  {
    symbol: 'MOP$',
    code: 'MOP',
  },
  {
    symbol: 'MURs',
    code: 'MUR',
  },
  {
    symbol: 'MX$',
    code: 'MXN',
  },
  {
    symbol: 'RM',
    code: 'MYR',
  },
  {
    symbol: 'MTn',
    code: 'MZN',
  },
  {
    symbol: 'N$',
    code: 'NAD',
  },
  {
    symbol: '₦',
    code: 'NGN',
  },
  {
    symbol: 'C$',
    code: 'NIO',
  },
  {
    symbol: 'kr',
    code: 'NOK',
  },
  {
    symbol: 'NPRs',
    code: 'NPR',
  },
  {
    symbol: 'NZ$',
    code: 'NZD',
  },
  {
    symbol: 'B/.',
    code: 'PAB',
  },
  {
    symbol: 'S/.',
    code: 'PEN',
  },
  {
    symbol: '₱',
    code: 'PHP',
  },
  {
    symbol: 'PKRs',
    code: 'PKR',
  },
  {
    symbol: 'zł',
    code: 'PLN',
  },
  {
    symbol: '₲',
    code: 'PYG',
  },
  {
    symbol: 'QR',
    code: 'QAR',
  },
  {
    symbol: 'RON',
    code: 'RON',
  },
  {
    symbol: 'din.',
    code: 'RSD',
  },
  {
    symbol: '₽',
    code: 'RUB',
  },
  {
    symbol: 'RWF',
    code: 'RWF',
  },
  {
    symbol: 'SR',
    code: 'SAR',
  },
  {
    symbol: 'SDG',
    code: 'SDG',
  },
  {
    symbol: 'Skr',
    code: 'SEK',
  },
  {
    symbol: 'S$',
    code: 'SGD',
  },
  {
    symbol: 'Ssh',
    code: 'SOS',
  },
  {
    symbol: 'SY£',
    code: 'SYP',
  },
  {
    symbol: '฿',
    code: 'THB',
  },
  {
    symbol: 'DT',
    code: 'TND',
  },
  {
    symbol: 'T$',
    code: 'TOP',
  },
  {
    symbol: '₺',
    code: 'TRY',
  },
  {
    symbol: 'TT$',
    code: 'TTD',
  },
  {
    symbol: 'NT$',
    code: 'TWD',
  },
  {
    symbol: 'TSh',
    code: 'TZS',
  },
  {
    symbol: '₴',
    code: 'UAH',
  },
  {
    symbol: 'USh',
    code: 'UGX',
  },
  {
    symbol: '$U',
    code: 'UYU',
  },
  {
    symbol: 'UZS',
    code: 'UZS',
  },
  {
    symbol: 'Bs.F.',
    code: 'VEF',
  },
  {
    symbol: '₫',
    code: 'VND',
  },
  {
    symbol: 'FCFA',
    code: 'XAF',
  },
  {
    symbol: 'CFA',
    code: 'XOF',
  },
  {
    symbol: 'YR',
    code: 'YER',
  },
  {
    symbol: 'R',
    code: 'ZAR',
  },
  {
    symbol: 'ZK',
    code: 'ZMK',
  },
  {
    symbol: 'ZWL$',
    code: 'ZWL',
  },
];
