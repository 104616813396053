/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import copy from 'copy-to-clipboard';

import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
// import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import { useMutation /*, useQuery*/ } from '@apollo/client';
import { message, notification } from 'antd';
import { useIntercom } from 'react-use-intercom';

import utils from 'utils/utils';
import Loader from 'components/Loader';
import PublishContent from './components/PublishContent';
import helpers from 'helpers/proposal';
import proposalTimer from './helpers/proposalTimer';
import { ExpiredProposalModal, PublishModals } from './modals';
import { PROPOSAL_SUBSCRIPTION_TOPIC } from 'constants/index';
import { CREATE_CLIENT_STRIPE_PAYMENT_SESSION } from 'graphql/mutations/integrationMutation';
// import { POLLING_INTERVAL } from 'constants/index';
// import { PING_POLLING } from 'graphql/queries/pollingQueries';
import useSessionStorage from 'hooks/useSessionStorage';
import { removeSpamUrls, formatSpamUrls } from 'utils/spamUrl';

import './Publish.scss';

// Handles the rendering and logic of the Proposal in its published mode
// It’s used for either the “Preview” mode by the user (/pd/ url)
// Or the actual link the client gets (/c/ url).
// Also the PDF renderer is using the url (/PDF/) to render the pdf and this is also rendered by these components)

const Publish = (props) => {
  const {
    config,
    propRef,
    user,
    match: { params },
    usersTeam,
    updateProposal,
    updateProposalClient,
    setProp,
    downloadingPDF,
    downloadPdf,
    subscribeToUpdateProposal,
    isTemplate,
    isSection,
    proposalSettings,
    isSavingForm,
    setIsSavingForm,
    wixPreview,
    setOpenWixPreview,
    clientWixPreview,
    templateWixPreview,
    isHeader,
    isEditingModal,
    preventReload,
    templateUserWixPreview,
  } = props;

  const clientView = window.location.pathname.startsWith('/cd/');
  const pdfView = window.location.pathname.startsWith('/PDF/');

  const TAB_ID = Date.now().toString();
  const [session, setSession] = useSessionStorage('session', {
    _id: '',
    visit: 1,
    tab_id: TAB_ID,
    last_visited: Date.now(),
  });

  const [isPublishing, setIsPublishing] = useState(false);
  const [proposalUnPublished, setProposalUnPublished] = useState(false);
  const [proposalPublished, setProposalPublished] = useState(false);
  const [makePayment, setMakePayment] = useState(false);
  const [logTime, setLogTime] = useState(0);
  const [stripe, setStripe] = useState('');
  const [paymentError, setPaymentError] = useState('');
  const [makingPayment, setMakingPayment] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [downloadPDFError, setDownloadPDFError] = useState('');
  // const [lastUpdate, setLastUpdate] = useState('');
  const [isClient, setIsClient] = useState(true);
  const [spamUrls, setSpamUrls] = useState({});

  const history = useHistory();
  const { trackEvent, shutdown } = useIntercom();

  let { prop } = props;
  if (clientView || pdfView) {
    // replace spam url with [dot] to make it non navigable
    prop = removeSpamUrls(prop, formatSpamUrls(prop?.spamUrls) || spamUrls);
  }

  const delegate = (el, evt, sel, handler) => {
    el.addEventListener(evt, function (event) {
      var t = event.target;
      while (t && t !== this) {
        if (t instanceof Element && t.matches(sel)) {
          handler.call(t, event);
        }
        t = t.parentNode;
      }
    });
  };

  useEffect(() => {
    if (prop?.spamUrls) {
      setSpamUrls(formatSpamUrls(prop.spamUrls));
    }
  }, [prop?.spamUrls]);

  /* eslint-disable*/
  useEffect(() => {
    document.body.classList.add('publish-body');

    if (pdfView) {
      shutdown();
      document.body.classList.add('PDF');
    }

    if (!params.cid && !clientWixPreview && !pdfView) {
      document.body.classList.add('publish-preview');
    }

    if (clientView || clientWixPreview) {
      shutdown();
      document.body.classList.add('client-preview');
    }

    delegate(document, 'click', '.section-wrapper a', function (event) {
      event.preventDefault();

      var url = event.target.closest('.section-wrapper a').getAttribute('href');
      if (!url) {
        return;
      }
      // in client view stop if spam url
      else if (clientView && url.includes('[dot]')) {
        return;
      } else if (
        !url.startsWith('http') &&
        url.indexOf('tel') === -1 &&
        url.indexOf('mailto') === -1
      ) {
        url = 'http://' + url;
      }

      try {
        url && window.open(url);
      } catch (err) {
        console.error('ERROR opening URL', url);
      }
    });

    return () => {
      document.body.classList.remove('publish-body');
      document.body.classList.remove('publish-preview');
      document.body.classList.remove('PDF');
      document.removeEventListener('click', () => {});
      setMakePayment(false);
      setLogTime('');
    };
  }, []);

  useEffect(() => {
    proposalTimer.onSavingFormChange(isSavingForm);
  }, [isSavingForm]);

  useEffect(() => {
    let unsubscribe = () => {};
    try {
      unsubscribe = subscribeToUpdateProposal();
    } catch (error) {
      console.log(error);
    }
    return () => unsubscribe && unsubscribe();
  }, []);

  useEffect(() => {
    if (!preventReload) {
      window.onunload = window.onbeforeunload = null;
    }
  }, [preventReload]);

  useEffect(() => {
    if (!prop) {
      return;
    }

    // if logged in user is NOT owner of the proposal OR not logged in then its a client
    const isClientValue =
      (!!params.cid && ((user && user?._id !== prop?.uid) || !user)) ||
      (clientWixPreview && !pdfView);
    setIsClient(isClientValue);

    const _session = {
      ...session,
    };

    if (isClientValue && session?.visit) {
      // first visit of this proposal
      if (_session._id !== params.cid && _session._id !== window.proposalId) {
        _session._id = params.cid || window.proposalId;
        // assign new tab id
        _session.tab_id = Date.now().toString();
        _session.visit = 1;
        delete _session.logIndex;
      }
      // subsequent visit
      else {
        _session.visit = session.visit + 1;
      }

      setSession({
        ..._session,
        last_visited: Date.now(),
      });
    }

    const callLogTimer = () => {
      proposalTimer.logTimer({
        isClient: isClientValue,
        prop: propRef?.current,
        updateProposalClient,
        setLogTime,
        session: _session,
      });
    };

    // if fresh new session
    if (_session.visit === 1 && !pdfView) {
      callLogTimer();
    }

    if (clientView || (clientWixPreview && !pdfView)) {
      if (/Mobi|Android/i.test(navigator.userAgent)) {
        window.document.body.addEventListener(
          'touchstart',
          () => {
            _.once(window.focus);
          },
          { passive: false }
        );
        window.addEventListener('blur', callLogTimer);
      } else {
        window.addEventListener('mouseout', (ev) => {
          ev.preventDefault();
          if (!ev.relatedTarget && !ev.toElement) callLogTimer();
        });
        window.addEventListener('blur', (ev) => {
          ev.preventDefault();
          callLogTimer();
        });
        // callLogTimer wont get trigger
        // window.onunload = window.onbeforeunload = (ev) => {
        //   ev.preventDefault();
        //   callLogTimer();
        // };
      }
    }

    if (isClientValue && prop && clientView) {
      if (prop?.stripe?.stripe_user_id) {
        (async () =>
          import('@stripe/stripe-js').then(async (stripe) =>
            setStripe(
              await stripe.loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
                stripeAccount: prop.stripe.stripe_user_id,
              })
            )
          ))();
      }

      if (/Mobi|Android/i.test(navigator.userAgent)) {
        // mobile!
        window.document.body.addEventListener(
          'touchstart',
          () => {
            proposalTimer.startTimer(logTime);
          },
          { passive: false }
        );
        window.addEventListener('focus', () => {
          proposalTimer.startTimerMobile(logTime);
        });
      } else {
        window.addEventListener(
          'mousemove',
          () => {
            proposalTimer.startTimer(logTime);
          },
          true
        );
      }
    }
  }, []);

  useEffect(() => {
    const stripeStatus = utils.getQueryStringValue('stripestatus');
    const paymentStatus = utils.getQueryStringValue('paymentstatus');

    if (stripeStatus && stripeStatus === 'success') {
      history.push(`/cd/${params.cid}?paymentstatus=success`);
    } else if (stripeStatus === 'canceled') {
      setPaymentError('Payment Declined');
      history.push(`/cd/${params.cid}?paymentstatus=failed`);
    }

    if (paymentStatus) {
      setPaymentStatus(paymentStatus);
    }
  }, [location.search]);
  /* eslint-enable */

  useEffect(() => {
    if (prop && isClient && ((params.cid && clientView) || (clientWixPreview && !pdfView))) {
      proposalTimer.setseen(params, prop, updateProposalClient, clientWixPreview);
    }
  }, [prop, params, isClient, updateProposalClient, clientWixPreview, pdfView, clientView]);

  const [createClientStripeSession] = useMutation(CREATE_CLIENT_STRIPE_PAYMENT_SESSION, {
    async onCompleted(data) {
      // When the customer clicks on the button, redirect them to Checkout.
      const { error } = await stripe.redirectToCheckout({
        sessionId: data.createClientStripePaymentSession,
      });

      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
      if (error) {
        setPaymentError(error);
        setMakingPayment(false);
      } else {
        setMakingPayment(false);
        setPaymentError('');
      }
    },
    onError: (err) => {
      console.log(err);
      setMakingPayment(false);
      setPaymentError('Payment Failed');
    },
  });

  /*
   * Replace polling by subscription
   *
  const {
    data: pollingResponse,
    // startPolling,
    // stopPolling,
  } = useQuery(PING_POLLING, {
    variables: {
      from: 'Publish',
      proposalId: prop._id,
      userId: user?._id || '',
      teamId: user?.teamId || '',
    },
    pollInterval: POLLING_INTERVAL.proposal * 1000,
    fetchPolicy: 'no-cache',
  });

  // console.log("re rendered ?")

  useEffect(() => {
    if (pollingResponse) {
      // if (isFirst) {
      //   startPolling(POLLING_INTERVAL.proposal * 1000);
      //   setIsFirst(false);
      // }
      // console.log("inside polling response")
      let typename,
        proposal = undefined;
      typename = pollingResponse?.pingPolling?.typename;
      proposal = pollingResponse?.pingPolling?.proposal;
      if (
        typename &&
        typename !== 'pong' &&
        lastUpdate !== JSON.stringify(pollingResponse?.pingPolling)
      ) {
        // console.log("updated")
        setLastUpdate(JSON.stringify(pollingResponse?.pingPolling));
        if (
          user &&
          user?._id !== proposal?.uid &&
          user?.teamId !== proposal?.auid &&
          user?._id !== proposal?.auid &&
          user?.teamId !== proposal?.uid
        )
          return;

        if (params.cid && params.cid !== proposal._id) {
          return;
        }

        if (params.id && params.id !== proposal._id) {
          return;
        }

        if (params.sid && params.sid !== proposal._id) {
          return;
        }

        if (params.tid && params.tid !== proposal._id) {
          return;
        }

        if (params.pid && params.pid !== proposal.pid) {
          return;
        }

        if (window.proposalId && window.proposalId !== proposal.wixProposalId) {
          return;
        }

        if (typename === 'UPDATE_PROPOSAL') {
          propRef.current = proposal;
          setProp(proposal);
          return { fetchProposal: proposal };
        }
      }
    }
  }, [
    pollingResponse,
    params,
    propRef,
    setProp,
    user,
    lastUpdate,
    setLastUpdate,
    // isFirst,
    // setIsFirst,
    // startPolling,
  ]);
  */

  /*
   * No manual start and stop polling 
   *
  useEffect(() => {
    if (sessionStorage.getItem('shouldPoll') === 'true') {
      if (!props.shouldPoll) {
        sessionStorage.setItem('shouldPoll', false);
        stopPolling();
      }
    } else {
      if (props.shouldPoll) {
        sessionStorage.setItem('shouldPoll', true);
        startPolling(POLLING_INTERVAL.proposal * 1000);
      }
    }
  });
  */

  useEffect(() => {
    let unsubscribe = () => {};
    try {
      unsubscribe = subscribeToUpdateProposal();
    } catch (error) {
      console.log(error);
    }
    return () => unsubscribe && unsubscribe();
  }, [subscribeToUpdateProposal]);

  if (!prop) {
    return (
      <div className="not-found">
        <h1>Sorry, there is nothing here</h1>
      </div>
    );
  }

  if (!prop || !config) {
    return <Loader />;
  }

  const publishProposal = (publish = true) => {
    if (prop.published && prop.state === 'published' && wixPreview) {
      window?.proposalApi?.send(window.proposalId);
    } else {
      // publish OR unpublish
      setIsPublishing(true);

      if (!prop?.wixProposalId) {
        if (publish) {
          trackEvent('publishprop', {
            id: prop._id,
          });
        } else {
          trackEvent('unpublishprop', {
            id: prop._id,
          });
        }
      }

      const proposalInfo = {
        id: prop._id,
        _id: prop._id,
        pid: prop.pid,
        uid: prop.uid,
        auid: prop.auid,
        published: publish ? new Date(new Date().toUTCString().slice(0, -4)) : null,
        state: prop.state === 'approved' ? prop.state : publish ? 'published' : 'edit',
        prevState: prop.state,
      };

      setTimeout(() => {
        updateProposal(
          {
            variables: {
              topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
                proposalInfo?.channel || proposalInfo?.auid || proposalInfo?.uid
              }`,
              updateType: proposalInfo.state,
              proposal: proposalInfo,
            },
          },
          {
            ...prop,
            ...proposalInfo,
          }
        ).then(({ data, errors }) => {
          if (data) {
            if (publish) {
              if (wixPreview) {
                window?.proposalApi?.send(window.proposalId);
              } else {
                setProposalPublished(true);
              }
            } else {
              setProposalUnPublished(true);
            }
            setIsPublishing(false);
          } else if (errors?.message) {
            setIsPublishing(false);
            notification.error({
              message: 'Proposal Publish Failed',
              description: 'Proposal Status not valid',
            });
          }
          setProp({ ...prop, ...proposalInfo });
        });
      }, 1500);
    }
  };

  const publishedProposalLink = (type) => {
    if (type === 'unshortened') {
      return `${window.location.origin}/cd/${prop._id}`;
    }

    const hostForLink = `.goprospero.com/${helpers.porposalPdfLinkPath(prop._id)}`;

    let pdfLink = user ? `https://${user.domain}${hostForLink}` : '';
    const cuid = user && user._id;

    if (cuid !== prop.uid) {
      const teamMember = usersTeam && usersTeam.find((user) => user._id === prop.uid);
      if (teamMember) {
        pdfLink = `https://${teamMember.domain}${hostForLink}`;
      } else {
        pdfLink = '';
      }
    }

    return pdfLink;
  };

  const copyPropsalLink = (linkType) => {
    setProposalPublished(false);
    copy(publishedProposalLink(linkType));
    message.success('Copied to Clipboard');

    if (!clientView) {
      window.ga('send', 'event', 'click', 'click-copy-link', publishedProposalLink());
      window.ga('send', 'pageview', '/event/click-copy-link');
    }

    trackEvent('click-copy-link', {
      pid: prop?.pid,
      id: prop?._id,
      value: publishedProposalLink(),
    });
  };

  const clientMakesPayment = () => {
    setMakingPayment(true);

    const amount = prop.clientPayableAmount;
    const currency = prop.clientPayableCurrency
      ? prop.clientPayableCurrency
      : prop.stripe.currency
      ? prop.stripe.currency
      : 'usd';

    createClientStripeSession({
      variables: {
        amount,
        currency,
        proposalLabel: prop.clientPayableLabel,
        proposalId: prop._id,
      },
    });
  };

  const { expiryDate, state: proposalState } = prop;
  // Check proposal is expired or not
  if (expiryDate && proposalState !== 'approved' && !pdfView) {
    const isProposalExpired = moment(new Date()).isAfter(moment(new Date(expiryDate)));

    const {
      callButton,
      contactnumber,
      email,
      expiryMessage,
      language,
      draft: { header },
      client,
    } = prop;

    const contactNumber = contactnumber && contactnumber.replace(/\s/g, '');

    const message = expiryMessage || 'This proposal already expired';
    let nameHtmlClean;
    if (header) {
      let nameHtml = header.name;
      if (header.rawname) {
        const names = convertFromRaw(header.rawname);
        nameHtml = stateToHTML(names);
      }
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = nameHtml;
      nameHtmlClean = tempDiv.textContent;
    } else {
      nameHtmlClean = client ? client.contact : '';
    }

    if (isProposalExpired) {
      return (
        <>
          <ExpiredProposalModal
            title="Expired"
            subTitle={message}
            name={nameHtmlClean.split(' ')[0]}
            contactNumber={contactNumber}
            email={email}
            showCallButton
            callButton={callButton}
            language={language?.toLowerCase()}
            config={config}
            isClient={isClient}
          />
        </>
      );
    }
  }

  return (
    <>
      <PublishModals
        proposal={prop}
        user={user}
        history={history}
        downloadingPDF={downloadingPDF}
        paymentError={paymentError}
        setPaymentError={setPaymentError}
        copyPropsalLink={copyPropsalLink}
        downloadPdf={downloadPdf}
        publishedProposalLink={publishedProposalLink}
        proposalUnPublished={proposalUnPublished}
        proposalPublished={proposalPublished}
        makePayment={makePayment}
        makingPayment={makingPayment}
        paymentStatus={paymentStatus}
        setProposalUnPublished={setProposalUnPublished}
        setProposalPublished={setProposalPublished}
        setMakePayment={setMakePayment}
        clientMakesPayment={clientMakesPayment}
        setPaymentStatus={setPaymentStatus}
        downloadPDFError={downloadPDFError}
        setDownloadPDFError={setDownloadPDFError}
        isEditingModal={isEditingModal}
      />
      <PublishContent
        copyPropsalLink={copyPropsalLink}
        publishedProposalLink={publishedProposalLink}
        isPublishing={isPublishing}
        config={config}
        prop={prop}
        user={user}
        setProp={setProp}
        updateProposalClient={updateProposalClient}
        templateWixPreview={templateWixPreview}
        templateUserWixPreview={templateUserWixPreview}
        publishProposal={publishProposal}
        setMakePayment={setMakePayment}
        isTemplate={isTemplate}
        isSection={isSection}
        isHeader={isHeader}
        downloadPdf={downloadPdf}
        location={props.location}
        proposalSettings={proposalSettings}
        isSavingForm={isSavingForm}
        setIsSavingForm={setIsSavingForm}
        wixPreview={wixPreview}
        setOpenWixPreview={setOpenWixPreview}
        clientWixPreview={clientWixPreview}
        isEditingModal={isEditingModal}
        spamUrls={spamUrls}
        setProposalPublished={setProposalPublished}
      />
    </>
  );
};

Publish.defaultProps = {
  cuser: '',
  prop: '',
  propRef: {},
  config: null,
  usersTeam: [],
  downloadingPDF: false,
  isTemplate: false,
  isSection: false,
  updateProposalClient: () => {},
  setProp: () => {},
  wixPreview: false,
  setOpenWixPreview: () => {},
  clientWixPreview: false,
};

Publish.propTypes = {
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  prop: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  propRef: PropTypes.instanceOf(Object),
  config: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object).isRequired,
  usersTeam: PropTypes.arrayOf(PropTypes.shape({})),
  setProp: PropTypes.func,
  updateProposal: PropTypes.func.isRequired,
  updateProposalClient: PropTypes.func,
  downloadingPDF: PropTypes.bool,
  isTemplate: PropTypes.bool,
  isSection: PropTypes.bool,
  downloadPdf: PropTypes.func.isRequired,
  wixPreview: PropTypes.bool,
  clientWixPreview: PropTypes.bool,
  setOpenWixPreview: PropTypes.func,
  subscribeToUpdateProposal: PropTypes.func.isRequired,
};

export default Publish;
