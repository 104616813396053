import React, { useReducer, useEffect } from 'react';
import { Col, Row, Slider, Divider, Button } from 'antd';
import { HuePicker } from 'react-color';

import './BorderPicker.scss';

const initialState = {
  borderWidth: 0,
  borderRadius: 0,
  borderColor: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setBorderWidth':
      return { ...state, borderWidth: action.borderWidth };
    case 'setBorderRadius':
      return { ...state, borderRadius: action.borderRadius };
    case 'setBorderColor':
      return { ...state, borderColor: action.borderColor };
    case 'reset':
      return initialState;
    default:
      throw new Error('Unexpected action');
  }
};

const BorderPicker = ({ border, onChange, save, cancel }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (border) {
      dispatch({ type: 'setBorderWidth', borderWidth: border?.borderWidth });
      dispatch({ type: 'setBorderRadius', borderRadius: border?.borderRadius });
      dispatch({ type: 'setBorderColor', borderColor: border?.borderColor });
    }
  }, []); //eslint-disable-line

  const onSave = () => {
    save(state);
  };

  const handleChange = (value, dispatchType, stateType) => {
    dispatch({ type: dispatchType, [stateType]: value });
    onChange({ ...state, [stateType]: value });
  };

  return (
    <Row className="border-picker" gutter={16}>
      <Col className="gutter-row" span={12}>
        <small className="image-crop-label">Border Width {state.borderWidth}</small>
        <Slider
          step={1}
          min={0}
          max={20}
          value={state.borderWidth}
          onChange={(value) => handleChange(value, 'setBorderWidth', 'borderWidth')}
        />
      </Col>
      <Col className="gutter-row" span={12}>
        <small className="image-crop-label">Border Radius {state.borderRadius}</small>
        <Slider
          step={1}
          min={0}
          max={160}
          value={state.borderRadius}
          onChange={(value) => handleChange(value, 'setBorderRadius', 'borderRadius')}
        />
      </Col>
      <Divider />
      <Col className="gutter-row" span={24}>
        <small className="image-crop-label">Color {state.borderColor?.hex}</small>
        <HuePicker
          color={state.borderColor}
          onChange={(value) => handleChange(value, 'setBorderColor', 'borderColor')}
        />
      </Col>

      <Col className="gutter-row border-picker-button" span={24}>
        <Row>
          <Button
            size="middle"
            type="button"
            className="Prosprich-editor-components-popover-confirm"
            onClick={onSave}>
            Save
          </Button>
          <Button
            size="middle"
            type="button"
            className="Prosprich-editor-components-popover-cancel"
            onClick={cancel}>
            Cancel
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default BorderPicker;
