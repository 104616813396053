import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import utils from 'utils/utils';
import { sanitizeString } from 'utils/xss';
import { useLazyQuery, useReactiveVar, useMutation } from '@apollo/client';

import {
  Row,
  Col,
  Dropdown,
  Menu,
  Space,
  Popover,
  Input,
  Button,
  Select,
  AutoComplete,
  Tooltip,
} from 'antd';

import MenuIcon from 'components/Icons/MenuIcon';
import FailedIcon from 'components/Icons/FailedIcon';
import SuccessIcon from 'components/Icons/SuccessIcon';
import EditIcon from 'components/Icons/EditIcon';
import EyeIcon from 'components/Icons/EyeIcon';
import FreshbookIcon from 'components/Icons/FreshbookIcon';
import QuickbookIcon from 'components/Icons/QuickbookIcon';
import XeroIcon from 'components/Icons/XeroIcon';
import Tour from 'components/Tour';
import MorningInvoiceIcon from 'components/Icons/MorningInvoiceIcon';
import ProposalItemTag from './components/ProposalItemTag';

import {
  PROPOSAL_STATUS_COLORS,
  PROPOSAL_ITEM_ACTIONS,
  TRASH_PROPOSAL_ITEM_ACTIONS,
  PROPOSAL_STATUS,
} from 'constants/index';

import { cache, getUserId, userVar, proposalsClientVar, userProfileVar } from 'graphql/cache';
import { USER_DETAILS_UPDATE } from 'graphql/mutations/userMutations';
import { GET_PROPOSALS_CLIENTS } from 'graphql/queries/proposalQueries';
import { proposalItem } from 'helpers/grid';

import './ProposalItem.scss';

const { SubMenu } = Menu;

const Item = styled(Row)`
  border-left: ${(props) =>
    `4px solid ${
      PROPOSAL_STATUS_COLORS[props.status] && PROPOSAL_STATUS_COLORS[props.status].main
    }`};
`;

const StatusLabel = styled.div`
  background: ${(props) => [props.status] && PROPOSAL_STATUS_COLORS[props.status].background};
  color: ${(props) =>
    PROPOSAL_STATUS_COLORS[props.status] && PROPOSAL_STATUS_COLORS[props.status].main};
`;

const { Option } = Select;

const ProposalItem = ({
  teamInfo,
  pnum,
  name,
  avatar,
  client,
  watched,
  quote,
  opened,
  emailLogs,
  trash,
  lastSeen,
  clientIP,
  status,
  onClickItem,
  proposalId,
  isLocked,
  variables,
  isDeletable,
  tags,
  tagsData,
  proposalIndex,
  setTagWrapperWidth,
  proposalList,
  ...rest
}) => {
  const user = useReactiveVar(userVar);
  const cachedProfile = useReactiveVar(userProfileVar);
  const proposalsClients = useReactiveVar(proposalsClientVar);

  const profile = { ...cachedProfile };
  const [visible, toggleEdit] = useState(false);
  const [visibleTags, setvisibleTags] = useState(false);
  const [clientVisible, toggleClientEdit] = useState(false);
  const [proposalTitle, setProposalTitle] = useState(name);
  const [proposalTitleError, setProposalTitleError] = useState('');
  const [clientName, setClientName] = useState(client);
  const [clientNameError, setClientNameError] = useState('');
  const [clientNames, setClientNames] = useState(proposalsClients);
  const tagRef = useRef(null);
  const tourRef = useRef(null);
  const [openTour, setOpenTour] = useState(false);

  const tourSteps = [
    {
      title: 'Speed up your workflow',
      description:
        'Save time by turning your proposal into a reusable template for quick customization and faster project setup.',
      placement: 'topRight',
      target: () => tourRef.current,
    },
  ];

  useEffect(() => {
    if (status === 'PUBLISHED' && !profile.seenTour && proposalList.length === 1) {
      setOpenTour(true);
    }
  }, [profile.seenTour, status, proposalList]);

  useEffect(() => {
    if (name !== proposalTitle) setProposalTitle(name);
  }, [name]); //eslint-disable-line

  useEffect(() => {
    if (tagRef.current?.offsetWidth) {
      // calculate width of tag wrapper div
      // calculating width for only for 1 item is enough as rest will be same
      setTagWrapperWidth(tagRef.current.offsetWidth - 145);
    }
  }, [tagRef, tagsData]); //eslint-disable-line

  const [updateProfile] = useMutation(USER_DETAILS_UPDATE, {
    errorPolicy: 'none',
    async onCompleted() {
      cache.evict('fetchUser');
      userProfileVar(profile);
    },
  });

  const closeTour = (saveTemplate = false) => {
    profile['seenTour'] = true;
    updateProfile({
      variables: {
        id: getUserId(),
        profile: profile,
      },
    });
    userProfileVar(profile);
    setOpenTour(false);
    saveTemplate && onClickItem({ actionType: 'save_as_template', proposalId });
  };

  const handleVisibleChange = (visible) => {
    closeTour(visible);
  };

  const [loadProposalClients] = useLazyQuery(GET_PROPOSALS_CLIENTS, {
    skip: !getUserId() || proposalsClients.length,
    variables: {
      uid: getUserId(),
      fetchPolicy: 'cache-and-network',
    },
    onCompleted: ({ fetchProposals }) => {
      let tempClientNames = _.compact(
        _.map(
          fetchProposals?.proposals,
          (p) => p.client && p.client.contact && { value: p.client.contact }
        )
      );

      tempClientNames = _.uniqBy(tempClientNames, 'value');

      setClientNames(tempClientNames);
      proposalsClientVar(tempClientNames);
    },
  });

  const handleClick = (e) => {
    e.domEvent.stopPropagation();

    const { key } = e;
    let actionType = trash
      ? TRASH_PROPOSAL_ITEM_ACTIONS[key]?.key
      : PROPOSAL_ITEM_ACTIONS[key]?.key;
    if (!actionType) {
      PROPOSAL_ITEM_ACTIONS['item_2'].value.forEach((item, itemKey) => {
        if (key === item.key) {
          actionType = item.key;
        }
      });
    }
    onClickItem({
      actionType: actionType,
      proposalId,
    });
  };

  const handleKeyCommand = (e) => {
    if (e.key === 'Escape') {
      const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
      if (isNotCombinedKey) {
        window.removeEventListener('keydown', handleKeyCommand, true);
        toggleEdit(visible);
      }
    }
  };

  const saveByEnter = (e) => {
    const x = e.which || e.keyCode;
    if (e.key === 'Enter' || x === 13) {
      const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
      if (isNotCombinedKey) {
        onClickItem({
          actionType: 'update_title',
          proposalId,
          value: proposalTitle,
        });
        toggleEdit(false);
      }
    }
  };

  const canShareProposal =
    teamInfo?.userRole === 'manager' ||
    (teamInfo?.userId &&
      (teamInfo?.userId === teamInfo?.uid || teamInfo?.userId === teamInfo?.auid))
      ? true
      : false;

  const menu = () => {
    let proposalItemsActions = JSON.parse(JSON.stringify(PROPOSAL_ITEM_ACTIONS));
    if (!isDeletable) {
      delete proposalItemsActions.item_7;
    }

    if (status !== PROPOSAL_STATUS.published && status !== PROPOSAL_STATUS.seen) {
      delete proposalItemsActions.item_10;
    }
    return (
      <Menu onClick={handleClick} className="proposal-actions">
        {Object.keys(proposalItemsActions).map((item) => {
          if (proposalItemsActions[item].key === 'copy_link') {
            const disabled = isLocked || status === 'DRAFT';
            return (
              <Menu.Item
                key={item}
                // disabled={disabled}
                className={`${disabled ? 'ant-dropdown-menu-item-disabled' : ''}`}>
                <Tooltip title={!isLocked && disabled && 'Publish proposal to copy link'}>
                  <div>{proposalItemsActions[item].value}</div>
                </Tooltip>
              </Menu.Item>
            );
          }

          return proposalItemsActions[item].key === 'approved_declned' ? (
            status !== 'DECLINED' && status !== 'APPROVED' && (
              <Menu.Item key={item} className="approved-declined" disabled={isLocked}>
                <Row gutter={10}>
                  <Col
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickItem({ actionType: 'approve', proposalId });
                    }}>
                    <Space>
                      <SuccessIcon />
                      <span>Approve</span>
                    </Space>
                  </Col>
                  <Col
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickItem({ actionType: 'decline', proposalId });
                    }}>
                    <Space>
                      <FailedIcon />
                      <span>Decline</span>
                    </Space>
                  </Col>
                </Row>
              </Menu.Item>
            )
          ) : proposalItemsActions[item].key === 'share_proposal' &&
            !canShareProposal ? null : (status === 'DECLINED' || status === 'APPROVED') &&
            proposalItemsActions[item].key !== 'invoice submenu' ? (
            <Menu.Item
              key={item}
              disabled={isLocked && proposalItemsActions[item].key !== 'download_pdf'}>
              {proposalItemsActions[item].value}
            </Menu.Item>
          ) : proposalItemsActions[item].key === 'invoice submenu' ? (
            <SubMenu
              key={item}
              disabled={isLocked}
              title="Generate Invoice"
              onTitleClick={(e) => {
                e.domEvent.stopPropagation();
              }}>
              {!isLocked &&
                proposalItemsActions[item].value.map((subitems) => (
                  <Menu.Item
                    key={subitems.key}
                    icon={
                      subitems.icon === 'FRESHBOOK' ? (
                        <FreshbookIcon />
                      ) : subitems.icon === 'QUICKBOOK' ? (
                        <QuickbookIcon />
                      ) : subitems.icon === 'MORNINGINVOICE' ? (
                        <MorningInvoiceIcon />
                      ) : (
                        <XeroIcon />
                      )
                    }>
                    {subitems.value}
                  </Menu.Item>
                ))}
            </SubMenu>
          ) : (
            proposalItemsActions[item].key !== 'change_to_previous_state' && (
              <Menu.Item
                className={proposalItemsActions[item].key === 'delete' && 'delete-text'}
                key={item}
                disabled={isLocked && proposalItemsActions[item].key !== 'download_pdf'}>
                {proposalItemsActions[item].value}
              </Menu.Item>
            )
          );
        })}
      </Menu>
    );
  };

  const trashMenu = () => {
    return (
      <Menu onClick={handleClick} className="proposal-actions">
        {Object.keys(TRASH_PROPOSAL_ITEM_ACTIONS).map((item) => {
          return TRASH_PROPOSAL_ITEM_ACTIONS[item].key === 'delete' ? (
            <Menu.Item className="delete-text" key={item}>
              {TRASH_PROPOSAL_ITEM_ACTIONS[item].value}
            </Menu.Item>
          ) : (
            <Menu.Item className="restore-text" key={item}>
              {TRASH_PROPOSAL_ITEM_ACTIONS[item].value}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const proposalName = utils.changeVariable(variables, proposalTitle, user?.profile?.dateFormat);
  let proposalClientName = utils.changeVariable(variables, clientName, user?.profile?.dateFormat);
  proposalClientName = proposalClientName.replace(/<\/?p>/g, '');

  return (
    <Item
      className="item proposal-item"
      status={status}
      justify="middle"
      align="top"
      onClick={() => onClickItem({ actionType: 'edit', proposalId })}>
      <Col
        {...proposalItem.proposal}
        className="value value-proposal-title"
        {...(proposalIndex === 0 ? { ref: tagRef } : {})}>
        <div className={`proposal-name-wrapper ${avatar ? 'with-avatar' : ''}`}>
          {avatar && <div className="avatar-wrapper">{avatar}</div>}
          <div className="name-wrapper">
            <div className="proposal-item-title" title={proposalName}>
              <span className="title">{proposalName}</span>

              <Popover
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onVisibleChange={() => {
                  if (!visible) {
                    window.addEventListener('keydown', handleKeyCommand, true);
                  } else {
                    window.removeEventListener('keydown', handleKeyCommand, true);
                  }
                  toggleEdit(!visible);
                }}
                trigger="click"
                visible={visible}
                content={
                  <div className="edit-container" onClick={(e) => e.stopPropagation()}>
                    <Input
                      value={proposalTitle}
                      onChange={(e) => {
                        if (proposalTitleError) setProposalTitleError('');
                        setProposalTitle(
                          !!sanitizeString(e.target.value).trim()
                            ? sanitizeString(e.target.value)
                            : ''
                        );
                      }}
                      onKeyPress={saveByEnter}
                    />
                    <p className="error-field">{proposalTitleError}</p>
                    <div className="button-wrapper">
                      <Space>
                        <Button
                          onClick={() => {
                            if (!proposalTitle) {
                              return setProposalTitleError('Proposal name cannot be empty.');
                            }

                            onClickItem({
                              actionType: 'update_title',
                              proposalId,
                              value: proposalTitle,
                            });
                            toggleEdit(false);
                            setProposalTitle(name);
                          }}>
                          Save
                        </Button>
                        <Button
                          onClick={() => {
                            window.removeEventListener('keydown', handleKeyCommand, true);
                            toggleEdit(false);
                            setProposalTitle(name);
                            setProposalTitleError('');
                          }}>
                          Cancel
                        </Button>
                      </Space>
                    </div>
                  </div>
                }>
                <EditIcon className={`edit-icon ${visible ? 'proposal-item-name-active' : ''}`} />
              </Popover>
            </div>

            <div
              className="sub-header-wrapper"
              onClick={(e) => {
                e.stopPropagation();
              }}>
              <Popover
                overlayClassName="proposal-number-popover proposal-item-view-info-popover"
                content={'Proposal ID Number'}>
                <label className="subproposal">{pnum}</label>
              </Popover>
              <div className="seprator" />
              <div
                className={`proposal-tags-wrapper ${tags?.length ? 'tags-available' : ''} ${
                  visibleTags ? 'active' : ''
                }`}>
                <ProposalItemTag
                  tags={tags || []}
                  tagsData={tagsData || []}
                  visibleTags={visibleTags}
                  setvisibleTags={setvisibleTags}
                  handleKeyCommand={handleKeyCommand}
                  proposalList={proposalList}
                  {...rest}
                />
              </div>
            </div>
          </div>
        </div>
      </Col>

      <Col {...proposalItem.client} className="value value-prospect-name">
        <span className="value-client">{proposalClientName}</span>

        <Popover
          onClick={(e) => {
            e.stopPropagation();
            loadProposalClients();
          }}
          onVisibleChange={() => toggleClientEdit(!clientVisible)}
          trigger="click"
          visible={clientVisible}
          content={
            <div className="edit-container" onClick={(e) => e.stopPropagation()}>
              <AutoComplete
                filterOption={(inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                value={proposalClientName}
                onChange={(value) => {
                  if (clientNameError) setClientNameError('');
                  setClientName(!!sanitizeString(value).trim() ? sanitizeString(value) : '');
                }}>
                {clientNames.map((client, clientIndex) => (
                  <Option key={`${client.value}${clientIndex}`} value={client.value}>
                    {client.value}
                  </Option>
                ))}
              </AutoComplete>
              <p className="error-field">{clientNameError}</p>

              <div className="button-wrapper">
                <Space>
                  <Button
                    onClick={() => {
                      if (!proposalClientName) {
                        return setClientNameError('Prospect name cannot be empty');
                      }
                      onClickItem({
                        actionType: 'update_client',
                        proposalId,
                        value: proposalClientName,
                      });
                      toggleClientEdit(false);
                    }}>
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      toggleClientEdit(false);
                      setClientName(client);
                      setClientNameError('');
                    }}>
                    Cancel
                  </Button>
                </Space>
              </div>
            </div>
          }>
          <EditIcon className={`edit-icon ${clientVisible ? 'proposal-item-name-active' : ''}`} />
        </Popover>
      </Col>

      <Col {...proposalItem.watched} className="value value-watched">
        <Popover
          overlayClassName="proposal-item-view-info-popover"
          content={'The total amount of minutes the client watched your proposal'}>
          <span
            onClick={(e) => {
              e.stopPropagation();
              onClickItem({ actionType: 'open_logs', proposalId });
            }}>
            {watched}
          </span>
        </Popover>
        {watched ? (
          <EyeIcon
            className="open-logs-icon"
            onClick={(e) => {
              e.stopPropagation();
              onClickItem({ actionType: 'open_logs', proposalId });
            }}
          />
        ) : null}
      </Col>

      <Col {...proposalItem.opened} className="value value-opened">
        <Popover
          overlayClassName="proposal-item-view-info-popover"
          content={
            <span>
              The total number of times your client opened your proposal
              {lastSeen && <br></br>}
              {lastSeen ? ` Last opened: ${moment(lastSeen).format('MMM D, YYYY HH:mm')} ` : ''}
              {clientIP && <br></br>}
              {clientIP ? `Signature IP:  ${clientIP}` : ''}
            </span>
          }>
          <span
            onClick={(e) => {
              e.stopPropagation();
              onClickItem({ actionType: 'open_logs', proposalId });
            }}>
            {opened}
          </span>
        </Popover>
        {opened || emailLogs?.length ? (
          <EyeIcon
            className="open-logs-icon"
            onClick={(e) => {
              e.stopPropagation();
              onClickItem({ actionType: 'open_logs', proposalId });
            }}
          />
        ) : null}
      </Col>

      <Col {...proposalItem.quote} className="value value-quote">
        <Popover
          overlayClassName="proposal-item-view-info-popover"
          content={
            <span>
              {quote}
              <hr />
              The price range the client can choose from. The price can change based on Value cost
              option or Table cost option with checkboxes.
            </span>
          }>
          {quote.substring(0, 20)}
        </Popover>
      </Col>

      <Col {...proposalItem.status} className="value value-status">
        {status && (
          <StatusLabel status={status} className="status">
            {status.toLowerCase()}
          </StatusLabel>
        )}
      </Col>

      <Col {...proposalItem.action} className="value action-column">
        <Dropdown
          className="proposal-item-menu"
          overlay={trash ? (isDeletable ? trashMenu : null) : menu}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          trigger="click">
          <a
            ref={tourRef}
            className="ant-dropdown-link"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}>
            <MenuIcon />
          </a>
        </Dropdown>
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}>
          <Tour
            open={openTour}
            onClose={closeTour}
            steps={tourSteps}
            indicatorsRender={false}
            visibleChange={handleVisibleChange}
            icon={<MenuIcon />}
            onSubmit={() => closeTour(true)}
          />
        </div>
      </Col>
    </Item>
  );
};

ProposalItem.defaultProps = {
  lastSeen: '',
  clientIP: '',
  clientNames: [],
  opened: null,
  teamInfo: null,
  isLocked: false,
  loadProposalClients: () => {},
  tags: [],
  tagsData: [],
};

ProposalItem.propTypes = {
  pnum: PropTypes.string,
  proposalId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  watched: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  opened: PropTypes.number,
  onClickItem: PropTypes.func.isRequired,
  loadProposalClients: PropTypes.func.isRequired,
  clientNames: PropTypes.instanceOf(Array),
  status: PropTypes.oneOf([
    'PUBLISHED',
    'DOWNLOADED',
    'APPROVED',
    'DRAFT',
    'OPENED',
    'APPROVED',
    'DECLINED',
    'DELETED',
  ]).isRequired,
  lastSeen: PropTypes.string,
  clientIP: PropTypes.string,
  teamInfo: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.instanceOf(null)]),
  isLocked: PropTypes.bool,
  tags: PropTypes.array,
  tagsData: PropTypes.array,
};

export default ProposalItem;
