import { Button, Col, Divider, Dropdown, Form, Input, InputNumber, Row, notification } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import './Accordion.scss';
import { useMemo, useRef, useState } from 'react';
import { Editor } from '../FroalaEditor';
import {
  ArrowDownDropdown,
  ArrowUpDropdown,
  EyeIcon3,
  SendIcon,
  WindowHeaderIcon,
} from 'components/Icons';
import { useMutation } from '@apollo/client';
import { UPDATE_EMAIL_SETTINGS } from 'graphql/mutations/emailSettingsMutations';
import { GET_EMAIL_SETTINGS } from 'graphql/queries/emailSettingsQueries';
import { EmailPreviewModal, PreviewContent } from '../Preview';
import { direction } from 'direction';

import * as yup from 'yup';
import { TestMailModal } from 'pages/Emails/Modals/TestMail';
import { cache } from 'graphql/cache';
import useWindowDimensions from 'hooks/useWindowDimensions';

export const AccordionContent = ({
  emailKey,
  config,
  data,
  generalSettings,
  isDisabled = false,
  allSettings,
}) => {
  const showCountsFor = { sent: 'Sent', open: 'Opened', clicked: 'Clicked' };
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Col className="accordion-item">
      <div>
        <Row justify="space-between">
          <Col style={{ alignSelf: 'center' }}>
            <Row className="email-title">
              <span>{config.title}</span>
            </Row>
            <Row>
              <p className="email-desc">
                {config.isReminder
                  ? config.description.replace('[X]', data.reminder)
                  : config.description}
              </p>
            </Row>
          </Col>
          <Col style={{ alignSelf: 'center' }}>
            <Row justify="space-between" style={{ gap: '83px' }} wrap={false} align="middle">
              <Row className="info-list" wrap={false}>
                {Object.keys(showCountsFor).map((key) => (
                  <TextWithCount title={showCountsFor[key]} count={data[key]} />
                ))}
              </Row>
              <ActionButton
                icon={isOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
                text="Customize"
                onClick={() => setIsOpen(!isOpen)}
              />
            </Row>
          </Col>
        </Row>
      </div>
      <Row style={{ width: '100%' }}>
        {isOpen && (
          <ContentSection
            data={data}
            emailKey={emailKey}
            generalSettings={generalSettings}
            config={config}
            isDisabled={isDisabled}
            allSettings={allSettings}
          />
        )}
      </Row>
    </Col>
  );
};

export const ActionButton = ({ text, onClick, icon = <CaretDownOutlined />, ...props }) => {
  return (
    <Button className="email-action-button" {...props} icon={icon} onClick={onClick}>
      {text}
    </Button>
  );
};

export const TextWithCount = ({ title, count }) => {
  return (
    <Row className="email-status-info">
      <span className="text">{title}</span>
      <span className="count">{count}</span>
    </Row>
  );
};

export const MenuList = ({ open = false, title, items, onItemClick }) => {
  const [isOpen, setIsOpen] = useState(open);
  return (
    <Row
      className="menu"
      style={{ flexDirection: 'column', ...(isOpen ? { borderBottom: '1px solid #EBEBEB' } : {}) }}>
      <Row
        className="title"
        style={{ borderBottom: '1px solid #EBEBEB' }}
        onClick={() => setIsOpen(!isOpen)}>
        <span className="title-text">{title}</span>
        {isOpen ? <ArrowDownDropdown /> : <ArrowUpDropdown />}
      </Row>
      {isOpen && (
        <Row className="variable-list">
          {items.map((item, index) => (
            <span className="variable" onClick={() => onItemClick(item)}>
              {item.label}
            </span>
          ))}
        </Row>
      )}
    </Row>
  );
};

export const VariableDropdown = ({ field, items, onVariableSelect }) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const onItemClick = (variable) => {
    onVariableSelect(field, variable.key);
    setVisible(false);
  };

  const menu = (
    <>
      {Object.keys(items).map((key, index) => (
        <MenuList
          key={key}
          open={index === 0}
          title={key}
          items={items[key]}
          onItemClick={onItemClick}
        />
      ))}
    </>
  );
  return (
    <Dropdown
      overlayClassName="variable-select-dropdown"
      overlay={menu}
      trigger={['click']}
      onVisibleChange={handleVisibleChange}
      visible={visible}>
      <ActionButton text="Add variables" className="variable-dropdown" />
    </Dropdown>
  );
};

export const ContentSection = ({
  data,
  emailKey,
  generalSettings,
  config,
  withSidePreview = true,
  onSubmit,
  isDisabled = false,
  allSettings,
  height,
}) => {
  const [form] = Form.useForm();
  const editorRef = useRef(null);
  const savedRangeRef = useRef(null);

  const [formData, setFormData] = useState({ ...data });
  const [previewModal, setPreviewModal] = useState(false);
  const [testMailModal, setTestMailModal] = useState(false);
  const [updateSettings, { loading }] = useMutation(UPDATE_EMAIL_SETTINGS);
  const { width: screenWidth } = useWindowDimensions();

  const showPreviewOption = screenWidth <= 1540 || !withSidePreview;

  const schema = useMemo(() => {
    return yup.object().shape({
      subjectLine: yup.object().shape({
        value: yup
          .string()
          .trim()
          .required('The subject line is required.')
          .min(10, 'The subject line must be between 10 and 100 characters long.')
          .max(100, 'The subject line must be between 10 and 100 characters long.'),
        RTL: yup.boolean(),
      }),
      previewText: yup.object().shape({
        value: yup
          .string()
          .trim()
          .required('The preview text is required.')
          .min(10, 'The preview text must be between 10 and 100 characters long.')
          .max(100, 'The preview text must be between 10 and 100 characters long.'),
        RTL: yup.boolean(),
      }),
      emailBody: yup.object().shape({
        value: yup
          .string()
          .trim()
          .required('The email body is required.')
          .max(1000, 'The email body must be between 10 and 1000 characters long.'),
        RTL: yup.boolean(),
      }),
      buttonText: yup.object().shape({
        value: yup
          .string()
          .trim()
          .required('The button text is required.')
          .min(5, 'The button text must be between 5 and 50 characters long.')
          .max(50, 'The button text must be between 5 and 50 characters long.'),
        RTL: yup.boolean(),
      }),
      reminder: yup
        .number()
        .required('The reminder days is required.')
        .typeError('Number only.')
        .round()
        .min(
          emailKey === 'secondReminderToProspect' && config?.isReminder
            ? allSettings?.reminderToProspect?.reminder + 1
            : 1,
          emailKey === 'secondReminderToProspect' && config?.isReminder
            ? '2nd reminder must be greater than 1st reminder.'
            : 'Reminder must be at least 1 day.'
        )
        .max(
          emailKey === 'reminderToProspect' && config?.isReminder
            ? allSettings?.secondReminderToProspect?.reminder - 1
            : 99,
          emailKey === 'reminderToProspect' && config?.isReminder
            ? '1st reminder must be less than 2nd reminder.'
            : 'The reminder must be set within a maximum of 99 days.'
        ),
    });
  }, [allSettings, config, emailKey]);

  const yupSync = {
    async validator({ field }, value) {
      await schema.validateSyncAt(field, { [field]: value });
    },
  };

  const onVariableSelect = (field, variable) => {
    if (field === 'emailBody') {
      if (editorRef.current) {
        // Focus the editor to ensure the cursor is active
        editorRef.current.events.focus(true);
        editorRef.current.selection.restore(savedRangeRef.current);

        // Insert text at the last cursor position
        editorRef.current.html.insert(variable);

        // Optionally trigger the contentChanged event
        editorRef.current.events.trigger('contentChanged');
      }
      return;
    }
    const fieldValue = Object.assign({}, form.getFieldValue(field));
    fieldValue.value = `${fieldValue.value} ${variable}`;
    form.setFieldsValue({ [field]: fieldValue });
    setFormData({ ...formData, [field]: fieldValue });
  };

  const subjectVariables = {
    Proposal: [
      {
        label: '{{proposal.name}}',
        key: '{{proposal.name}}',
      },
      {
        label: '{{proposal.title}}',
        key: '{{proposal.title}}',
      },
      {
        label: '{{proposal.number}}',
        key: '{{proposal.number}}',
      },
      {
        label: '{{proposal.quote}}',
        key: '{{proposal.quote}}',
      },
      {
        label: '{{proposal.discount}}',
        key: '{{proposal.discount}}',
      },
      {
        label: '{{proposal.subTotal}}',
        key: '{{proposal.subTotal}}',
      },
      {
        label: '{{proposal.tax}}',
        key: '{{proposal.tax}}',
      },
      {
        label: '{{proposal.total}}',
        key: '{{proposal.total}}',
      },
      {
        label: '{{proposal.sentDate}}',
        key: '{{proposal.sentDate}}',
      },
      {
        label: '{{proposal.createdDate}}',
        key: '{{proposal.createdDate}}',
      },
      {
        label: '{{proposal.expireDate}}',
        key: '{{proposal.expireDate}}',
      },
    ],
    Sender: [
      {
        label: '{{sender.fullName}}',
        key: '{{sender.fullName}}',
      },
      {
        label: '{{sender.phone}}',
        key: '{{sender.phone}}',
      },
      {
        label: '{{sender.company}}',
        key: '{{sender.company}}',
      },
    ],
    Client: [
      {
        label: '{{client.firstName}}',
        key: '{{client.firstName}}',
      },
      {
        label: '{{client.lastName}}',
        key: '{{client.lastName}}',
      },
      {
        label: '{{client.fullName}}',
        key: '{{client.fullName}}',
      },
      {
        label: '{{client.email}}',
        key: '{{client.email}}',
      },
      {
        label: '{{client.phone}}',
        key: '{{client.phone}}',
      },
      {
        label: '{{client.company}}',
        key: '{{client.company}}',
      },
      {
        label: '{{client.VAT}}',
        key: '{{client.VAT}}',
      },
      {
        label: '{{client.city}}',
        key: '{{client.city}}',
      },
      {
        label: '{{client.postalCode}}',
        key: '{{client.postalCode}}',
      },
      {
        label: '{{client.state}}',
        key: '{{client.state}}',
      },
      {
        label: '{{client.country}}',
        key: '{{client.country}}',
      },
    ],
  };

  const onSettingUpdate = (values) => {
    updateSettings({
      variables: {
        settings: { [emailKey]: values },
      },
      onCompleted: (response) => {
        const cacheEmailSettings = cache.readQuery({ query: GET_EMAIL_SETTINGS });
        if (cacheEmailSettings && response.updateEmailSettings) {
          cache.writeQuery({
            query: GET_EMAIL_SETTINGS,
            data: {
              fetchEmailSettings: {
                ...cacheEmailSettings.fetchEmailSettings,
                ...response.updateEmailSettings,
              },
            },
          });
        }
        notification.success({ description: 'Saved successfully.' });
      },
    });
  };

  const onReset = () => {
    form.resetFields();
    setFormData(data);
  };

  const openSendTestMail = () => {
    form
      .validateFields()
      .then(async () => {
        setTestMailModal(true);
      })
      .catch((error) => {
        notification.error({
          description: 'There are errors in your form. Please review and try again',
        });
      });
  };

  return (
    <Form
      form={form}
      style={{ width: '100%', background: withSidePreview ? 'rgba(245, 246, 250, 0.40)' : '#fff' }}
      initialValues={data}
      layout="vertical "
      autoComplete="off"
      onValuesChange={(changedValues, allValues) => {
        setFormData(allValues);
      }}
      onFinish={(values) => {
        onSubmit ? onSubmit(values) : onSettingUpdate(values);
      }}>
      <Row className="accordion-content">
        <Col span={24} style={withSidePreview ? { maxWidth: '600px' } : {}}>
          {config?.isReminder && (
            <>
              <Form.Item name="reminder" noStyle rules={[yupSync]}>
                <CustomNumberField isDisabled={isDisabled} />
              </Form.Item>
              <Form.Item shouldUpdate={true} noStyle>
                {({ getFieldError, getFieldValue }) => {
                  const usernameErrors = getFieldError('reminder');
                  return usernameErrors.length > 0 ? (
                    <div
                      role="alert"
                      style={{ color: '#ff4d4f' }}
                      className="ant-form-item-explain-error">
                      <div>{usernameErrors[0]}</div>
                    </div>
                  ) : null;
                }}
              </Form.Item>
            </>
          )}
          {config?.isReminder && <Divider style={{ margin: '24px 0' }} />}
          <Form.Item
            name="subjectLine"
            rules={[yupSync]}
            label={
              <Row justify="space-between" className="form-label">
                <span className="label">Subject Line</span>
                {!isDisabled && (
                  <VariableDropdown
                    field={'subjectLine'}
                    onVariableSelect={onVariableSelect}
                    items={subjectVariables}
                  />
                )}
              </Row>
            }>
            <RTLInput disabled={isDisabled} />
          </Form.Item>
          <Form.Item
            name="previewText"
            label={
              <Row justify="space-between" className="form-label">
                <span className="label">Preview Text</span>
                {!isDisabled && (
                  <VariableDropdown
                    field={'previewText'}
                    onVariableSelect={onVariableSelect}
                    items={subjectVariables}
                  />
                )}
              </Row>
            }
            rules={[yupSync]}>
            <RTLInput disabled={isDisabled} />
          </Form.Item>
          <Form.Item
            name="emailBody"
            rules={[yupSync]}
            label={
              <Row justify="space-between" className="form-label">
                <span className="label">Email Body</span>
                {!isDisabled && (
                  <VariableDropdown
                    field={'emailBody'}
                    onVariableSelect={onVariableSelect}
                    items={subjectVariables}
                  />
                )}
              </Row>
            }>
            <Editor
              disabled={isDisabled}
              height={height}
              editorRef={editorRef}
              savedRangeRef={savedRangeRef}
            />
          </Form.Item>
          <Form.Item
            name="buttonText"
            label={
              <Row justify="space-between" className="form-label">
                <span className="label">Button text</span>
                {!isDisabled && (
                  <VariableDropdown
                    field={'buttonText'}
                    onVariableSelect={onVariableSelect}
                    items={subjectVariables}
                  />
                )}
              </Row>
            }
            rules={[yupSync]}>
            <RTLInput disabled={isDisabled} />
          </Form.Item>
          {!isDisabled && (
            <>
              <Divider style={{ margin: '0 0 24px 0' }} />
              <Row>
                <div className="test-mail-section" onClick={openSendTestMail}>
                  <SendIcon />
                  <span>Send test email</span>
                </div>
              </Row>

              <Row style={{ margin: '24px 0 0 0', gap: '9px' }} align="middle">
                <Button loading={loading} htmlType="submit" className="save-btn">
                  Save Changes
                </Button>
                <Button onClick={onReset} className="reset-btn" type="link">
                  Reset
                </Button>
                {showPreviewOption && (
                  <Button
                    onClick={() => setPreviewModal(true)}
                    style={{ marginLeft: 'auto' }}
                    className="reset-btn"
                    type="link">
                    Preview
                  </Button>
                )}
              </Row>
            </>
          )}
        </Col>
        {!showPreviewOption && (
          <Col span={12} style={{ maxWidth: '504px' }}>
            <Row className="email-preview-section">
              <Col className="gutter-row">
                <WindowHeaderIcon />
                <PreviewContent
                  generalSettings={generalSettings}
                  formData={formData}
                  emailKey={emailKey}
                />
              </Col>
              <Row justify="center" className="preview-button" style={{ width: '100%' }}>
                <Button
                  type="text"
                  onClick={() => setPreviewModal(true)}
                  icon={<EyeIcon3 />}
                  className="preview-btn">
                  Preview
                </Button>
              </Row>
            </Row>
          </Col>
        )}
        <EmailPreviewModal
          isOpen={previewModal}
          generalSettings={generalSettings}
          formData={formData}
          emailKey={emailKey}
          onCancel={() => setPreviewModal(false)}
          hasAttachment={config.hasAttachment}
        />
        <TestMailModal
          isOpen={testMailModal}
          onCancel={() => setTestMailModal(false)}
          emailKey={emailKey}
          formData={formData}
        />
      </Row>
    </Form>
  );
};

export const RTLInput = ({ onChange, value, ...props }) => {
  const onValueChange = (newValue) => {
    const RTL = direction(newValue) === 'rtl';
    onChange({ value: newValue, RTL });
  };
  return (
    <Input
      style={value?.RTL ? { direction: 'rtl' } : {}}
      value={value.value}
      onChange={(e) => onValueChange(e.target.value)}
      {...props}
    />
  );
};

const CustomNumberField = ({ optionInfo, isDisabled, ...props }) => {
  return (
    <Row className="reminder-section" align="middle">
      <span>after</span>
      <InputNumber
        disabled={isDisabled}
        {...props}
        min={1}
        max={99}
        precision={0}
        style={{ width: '64px' }}
      />
      <span>days after the proposal was sent.</span>
    </Row>
  );
};
