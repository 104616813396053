import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, message, Popover, Slider, Tooltip, Button, Form, Input } from 'antd';
import { SketchPicker } from 'react-color';
import { ResizableBox } from 'react-resizable';
import { useIntercom } from 'react-use-intercom';
import { ArrowRightOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import getRaw from '../../utils/getRaw';
import PropTypes from 'prop-types';
import _ from 'lodash';

import RichEditor from 'pages/Proposal/components/RichEditor/RichEditor';
import proposalHelpers from 'helpers/proposal';
import PaintBucketIcon from 'components/Icons/PaintBucketIcon';
import BinIcon from 'components/Icons/BinIcon';
import AddLibraryIcon from 'components/Icons/AddLibraryIcon';
import EyeShowIcon from 'components/Icons/EyeShowIcon';
import EyeHideIcon from 'components/Icons/EyeHideIcon';
import RestoreCoverIcon from 'components/Icons/RestoreCoverIcon';
import SaveAsDefaultModal from '../../../Modals/SaveAsDefaultModal';
import Uploader from 'components/Uploader';
import proposalUtils from '../../utils';
import utils from 'utils/utils';
import utilsHelpers from 'helpers';

import ContentLibraryTree from 'pages/ContentLibrary/components/ContentLibraryTree/ContentLibraryTree';

import { resizeImageForS3 } from 'utils/uploadToS3';

import 'react-resizable/css/styles.css';
import './HeaderSection.scss';

// In charge of rendering the header section of the Proposal template for editing
// You can upload images to this section as logo or cover photo,
// the images are resized in the client side and uploaded to amazon S3 and only the urls are saved in the Proposal object

const HeaderSection = ({
  language,
  name,
  proposal,
  user,
  saveUser,
  saveProposal,
  changeToolBarSideMenu,
  config,
  configText,
  saveProposalDraft,
  zoomValue,
  setOpenVariablesList,
  setScrollingTo,
  setSavingDraftProposal,
  shouldUndoRedoUpdate,
  setDisablePreview,
  onClickItem,
  showAddToLibrary,
  setShowAddToLibrary,
  saveSectionToLibrary,
  isSection,
  coverDroppable,
  sectionDroppable,
  isTemplate,
  ...rest
}) => {
  const inputFileRef = useRef(null);
  const latestRgbaRef = useRef(null);
  const { trackEvent } = useIntercom();
  const { draft } = proposal;

  const changeEvent = localStorage.getItem('headerChanged');
  const headerChanged = JSON.parse(changeEvent);
  const editEvent = localStorage.getItem('headerChangeEvent');
  const edited = JSON.parse(editEvent);
  const [selectedFolderKey, setSelectedFolderKey] = useState('');
  const [section, setSection] = useState('');
  const [color, setColor] = useState(null);
  const [titleWidth, setTitleWidth] = useState(null);
  const [titleDisplay, setTitleDisplay] = useState(null);
  const [saveLogoAsDefaultModal, showLogoAsDefaultModal] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [upload, uploadStatus] = Uploader();
  const [showProgress, toggleShowProgress] = useState(false);
  const [imageName, setImageName] = useState('');
  const [hideCover, setHideCover] = useState();
  const [form] = Form.useForm();

  /*eslint-disable*/
  useEffect(() => {
    if (
      !section ||
      shouldUndoRedoUpdate ||
      section?.cover !== draft[name]?.cover ||
      section?.hidecover !== draft[name]?.hidecover ||
      JSON.parse(JSON.stringify(section)) !== JSON.parse(JSON.stringify(draft[name]))
    ) {
      setSection(draft[name]);
      setHideCover(draft[name]?.hidecover);
    }
    if (headerChanged) {
      setSection(draft[name]);
      localStorage.removeItem('headerChanged');
    }
  }, [draft[name], shouldUndoRedoUpdate]);

  useEffect(() => {
    if (!titleWidth && section?.titleStyle?.titleWidth) {
      setTitleWidth(section.titleStyle.titleWidth);
    }
    if (
      !titleDisplay &&
      section?.titleStyle?.titleDisplay &&
      section.titleStyle.titleDisplay !== titleDisplay
    ) {
      setTitleDisplay(section.titleStyle.titleDisplay || 'block');
    }
  }, [section]);

  useEffect(() => {
    if (section?.color) {
      setColor(proposalHelpers.torgb(section.color));
    }
    if (!isSection && !draft[name].originalState) {
      const headerData = JSON.parse(JSON.stringify(draft[name]));
      headerData.rawby = getRaw(headerData.by);
      headerData.rawcontact = getRaw(headerData.contact);

      delete headerData.color;
      delete headerData.logo;
      delete headerData.cover;
      delete headerData.headerConfig;

      saveProposal({
        'draft.header.originalState': headerData,
      });
    }
    if (!isSection && proposal?.draft?.titleFont && !draft[name].headerConfig) {
      const headerConfigData = proposal?.draft?.titleFont;
      saveProposal({
        'draft.header.headerConfig': headerConfigData,
      });
    }
  }, [section]);

  /*eslint-enable*/
  const resetHeaderText = () => {
    if (draft[name].originalState) {
      const headerData = JSON.parse(JSON.stringify(draft[name].originalState));
      headerData.color = section.color;
      headerData.cover = section.cover;
      headerData.logo = section.logo;
      headerData.originalState = section.originalState;
      headerData.headerConfig = section.headerConfig;
      headerData.rawby = getRaw(section.by);
      headerData.rawcontact = getRaw(section.contact);
      saveProposal({
        'draft.header': headerData,
      });
      window.localStorage.setItem('headerChangeEvent', false);
      setSection((prev) => ({ ...prev, ...headerData }));
    }
  };

  if (!proposal.draft || !section) {
    return null;
  }

  const draftHeaderConfig = draft[name]?.headerConfig || draft?.titleFont;

  const getHeaderCoverSize =
    draftHeaderConfig && draftHeaderConfig.cover ? draftHeaderConfig.cover : false;

  // const getHeaderCoverTextAlignment =
  //   proposal?.language?.toLowerCase() === 'hebrew'
  //     ? 'right'
  //     : draft.titleFont && draft.titleFont.coverTextAlignment
  //       ? draft.titleFont.coverTextAlignment
  //       : false;

  const getHeaderCoverTextAlignment = draftHeaderConfig?.coverTextAlignment || false;

  const getHeaderLogoPosition =
    draftHeaderConfig && draftHeaderConfig.logoPosition ? draftHeaderConfig.logoPosition : false;

  let sectionAlignment = 'middle';
  let headerAlignmentClass = '';
  let logoPositionClass = 'logoposition-left';

  const headerStyle = {
    position: 'relative',
    overflow: 'visible',
  };

  if (isSection && !section.logo && !showProgress) {
    logoPositionClass = 'logoposition-right';
  }

  if (getHeaderCoverSize) {
    if (getHeaderCoverSize === 'top') {
      headerStyle.height = '100%';
    } else if (getHeaderCoverSize === 'full') {
      headerStyle.height = '1250px';
    }
  }

  if (getHeaderCoverTextAlignment) {
    switch (getHeaderCoverTextAlignment) {
      case 'left':
        headerAlignmentClass = 'proposalheader-left';
        break;
      case 'right':
        headerAlignmentClass = 'proposalheader-right';
        break;
      case 'center':
        headerAlignmentClass = 'proposalheader-center';
        break;
      case 'custom':
        headerAlignmentClass = 'proposalheader-custom';
        break;
      default:
        headerAlignmentClass = 'proposalheader-left';
    }
  }

  if (getHeaderLogoPosition) {
    switch (getHeaderLogoPosition) {
      case 'left':
        logoPositionClass = 'logoposition-left';
        break;
      case 'right':
        logoPositionClass = 'logoposition-right';
        break;
      default:
        logoPositionClass = 'logoposition-left';
    }
  }

  const { date } = proposal;

  if (!section.logo && !showProgress && new Date(date) < new Date(2020, 3, 21)) {
    sectionAlignment = 'middle';
  } else if (!section.logo && !showProgress && new Date(date) > new Date(2020, 3, 21)) {
    sectionAlignment = 'full';
  } else if (section.logo && headerAlignmentClass === 'proposalheader-custom') {
    sectionAlignment = 'custom';
  }

  const uploadLogo = () => {
    inputFileRef.current.click();
  };

  const removeLogo = (e) => {
    inputFileRef.current.value = '';
    setImageName('');
    e.preventDefault();
    e.stopPropagation();
    saveProposal({
      ['draft.' + name + '.logo']: '',
    });
    setSection((prev) => ({
      ...prev,
      logo: null,
    }));
  };

  const removeCover = (e) => {
    e.preventDefault();
    e.stopPropagation();
    saveProposal({
      ['draft.' + name + '.cover']: '',
    });
    setSection((prev) => ({
      ...prev,
      cover: null,
    }));
  };
  const selectHeader = () => {
    changeToolBarSideMenu({ key: 'library', type: 'Headers' });
  };

  const selectCover = () => {
    changeToolBarSideMenu({ key: 'library', type: 'Covers' });
  };

  const onLogoSelect = async (e) => {
    if (!e.target.files) return;

    window.ga('send', 'event', 'click-upload-logo', proposal._id, window.location.href);

    if (!proposal?.wixProposalId) {
      trackEvent('click-upload-logo');
    }

    try {
      const [file] = e.target.files;
      if (file) {
        setImageName(file?.name);
        toggleShowProgress(true);

        const size = { width: 640, height: 480 };

        const reSizedImage = await resizeImageForS3({
          file: file,
          path: 'logo',
          size,
        });

        const { url } = await upload(reSizedImage, `props/${proposal._id}/logo`);
        // eslint-disable-next-line
        setLogoUrl(url);
        saveProposal(
          {
            ['draft.' + name + '.logo']: url,
          },
          () => {
            const temporarySection = section;
            temporarySection.logo = url;
            setSection(temporarySection);
          }
        );

        toggleShowProgress(false);
        if (!user?.roles?.includes('editor')) {
          showLogoAsDefaultModal(true);
        }
      }
    } catch (error) {
      message.error(error.message);
      setImageName('');
      toggleShowProgress(false);
    }
  };

  const saveLogoAsDefault = () => {
    if (!proposal?.wixProposalId) {
      trackEvent('reuse-logo');
    }

    if (logoUrl) {
      saveUser({
        variables: {
          user: {
            id: user._id,
            branding: {
              companyLogo: logoUrl,
            },
          },
        },
      });

      message.success('Default logo saved sucessfully');

      setLogoUrl(null);
      showLogoAsDefaultModal(false);
    }
  };

  const onResizeTitleUnderLine = (e, data) => {
    const { size } = data;
    const { width } = size;

    if (width !== titleWidth) {
      setTitleWidth(width);
    }
  };

  const onResizeStop = (e, data) => {
    const { size } = data;
    const { width } = size;

    saveProposal({
      ['draft.' + name + '.titleStyle']: {
        titleWidth: width,
        titleDisplay: 'block',
      },
    });
  };

  const toogleTitleUnderLine = (value) => {
    const updatedTitleDisplay = value ? 'block' : 'none';

    setTitleDisplay(updatedTitleDisplay);
    if (!titleWidth) {
      setTitleWidth(28);
    }

    saveProposal({
      ['draft.' + name + '.titleStyle']: {
        ...(!titleWidth ? { titleWidth: 28 } : {}),
        titleDisplay: updatedTitleDisplay,
      },
    });
  };

  const saveColor = _.debounce(() => {
    if (latestRgbaRef.current !== null) {
      saveProposal({
        ['draft.' + name + '.color']: latestRgbaRef.current,
      });
      latestRgbaRef.current = null; // Reset the ref after calling saveProposal
    }
  }, 1000);

  const onColorChange = (selectedColor) => {
    const {
      rgb: { r, g, b, a },
    } = selectedColor;
    const rgba = `rgba(${r},${g},${b},${a})`;

    setColor(rgba);
    latestRgbaRef.current = rgba; // Update the ref with the latest rgba value
  };

  const showHideCover = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!proposal?.wixProposalId) {
      trackEvent('clicked-hide-cover');
    }

    if (section.hidecover === true) {
      setHideCover('false');
      saveProposal({
        ['draft.' + name + '.hidecover']: false,
      });
    } else {
      setHideCover('true');
      saveProposal({
        ['draft.' + name + '.hidecover']: true,
      });
    }
  };

  const onSelect = (selectedKeys) => {
    setSelectedFolderKey(selectedKeys[0]);
  };

  const handleAddToLibrarySubmit = () => {
    form
      .validateFields()
      .then((values) => {
        // Custom validation
        const noWhiteSpace = () => {
          return !/^[\s]*$/.test(values.newSectionName);
        };
        if (values.newSectionName.length && noWhiteSpace() === true) {
          saveSectionToLibrary({
            newSectionName: values.newSectionName,
            sectionName: 'header',
            selectedFolderKey,
            language: language,
            isHeader: true,
          });
          form.resetFields();
          setSelectedFolderKey('');
        } else {
          message.error('Header title cannot be blank');
          setShowAddToLibrary('HEADER');
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
    setShowAddToLibrary('');
  };

  const languageRTL = ['hebrew', 'arabic'].includes(proposal?.language?.toLowerCase());
  const titleLineDisplay = titleDisplay && titleDisplay === 'block';

  return (
    <div
      id="proposal-headersection"
      className={`proposal-headersection ${
        coverDroppable ? 'calculate-droppable-height' : ''
      } ${headerAlignmentClass}`}
      style={headerStyle}>
      <div
        className={`cover ${coverDroppable ? 'has-opacity-lowwered' : ''}`}
        style={{
          backgroundImage: `url("${utilsHelpers.formatS3Link(utils.replaceHttp(section?.cover))}")`,
        }}
      />
      <div className="header-color" style={{ backgroundColor: color }} />
      {isSection && (
        <div className={`cover-buttons ${languageRTL ? 'rtl' : ''}`}>
          <span onClick={showHideCover}>{hideCover === true ? 'Show' : 'Hide'} Cover</span>
          <span onClick={uploadLogo}>{section.logo ? 'Change' : 'Add'} logo</span>
          <span onClick={selectCover}>{section.cover ? 'Change' : 'Add'} Cover</span>
          <Popover
            placement="topLeft"
            content={
              <SketchPicker
                color={color}
                onChange={onColorChange}
                onChangeComplete={() => saveColor()}
                onClick={(event) => event.preventDefault()}
              />
            }
            trigger="click"
            overlayClassName="editor-color-picker">
            Overlay
          </Popover>
          <Tooltip title="Remove Cover">
            <span className={section.cover ? '' : 'is-disabled'} onClick={removeCover}>
              <BinIcon />
            </span>
          </Tooltip>
        </div>
      )}
      {!isSection && (
        <>
          <div className={`cover-buttons ${languageRTL ? 'rtl' : ''}`}>
            <span onClick={selectHeader}>Change header</span>
            <Tooltip title={`${hideCover === true ? 'Show' : 'Hide'} cover`}>
              <span onClick={showHideCover}>
                {hideCover === true ? <EyeHideIcon /> : <EyeShowIcon />}
              </span>
            </Tooltip>
            <Tooltip title="Reset to default text">
              <span
                className={!edited ? 'is-disabled' : ''}
                disabled={!edited}
                onClick={resetHeaderText}>
                <RestoreCoverIcon />
              </span>
            </Tooltip>
            <Tooltip title="Add to library">
              <span
                onClick={() => onClickItem({ actionType: 'addToLibrary', sectionName: 'HEADER' })}>
                <AddLibraryIcon />
              </span>
            </Tooltip>
          </div>
          <div className={`cover-buttons second ${languageRTL ? 'rtl' : ''}`}>
            <span onClick={selectCover}>{section.cover ? 'Change' : 'Add'} Cover</span>
            <Tooltip title="Change Overlay Color">
              <Popover
                placement="topLeft"
                content={
                  <SketchPicker
                    color={color}
                    onChange={onColorChange}
                    onChangeComplete={() => saveColor()}
                    onClick={(event) => event.preventDefault()}
                  />
                }
                trigger="click"
                overlayClassName="editor-color-picker">
                <PaintBucketIcon />
              </Popover>
            </Tooltip>
            <Tooltip title="Remove Cover">
              <span className={section.cover ? '' : 'is-disabled'} onClick={removeCover}>
                <BinIcon />
              </span>
            </Tooltip>
          </div>
          <div className={`cover-buttons third ${!section.cover ? 'no-cover' : ''}`}>
            <span onClick={uploadLogo}>{section.logo ? 'Change' : 'Add'} logo</span>
          </div>
        </>
      )}

      {showAddToLibrary && showAddToLibrary === 'HEADER' && (
        <div className="section-action-add-to-library" onMouseLeave={() => setShowAddToLibrary('')}>
          <Col>
            <Row className="section-action-add-to-library-header">Save as</Row>
            <Row className="section-action-add-to-library-input">
              <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                  modifier: 'public',
                  newSectionName: '',
                }}>
                <Form.Item
                  name="newSectionName"
                  rules={[{ required: true, message: 'Header name is required' }]}>
                  <Input maxLength={250} placeholder="Header name" />
                </Form.Item>
              </Form>
            </Row>
            <Row className="section-action-add-to-library-list">
              <ContentLibraryTree onSelect={onSelect} type="headers" onUpdate={() => {}} />
            </Row>
            <Button
              className="section-action-add-to-library-button"
              block={true}
              onClick={handleAddToLibrarySubmit}>
              Save Header
            </Button>
          </Col>
        </div>
      )}

      <Row
        className="header-title-container"
        style={
          headerAlignmentClass === 'proposalheader-custom'
            ? getHeaderCoverSize === 'top'
              ? { height: '18em', marginBottom: '6em' }
              : { height: '43em' }
            : {}
        }>
        {(section.logo || showProgress) &&
          (sectionAlignment === 'middle' || sectionAlignment === 'custom') && (
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              className="logo-section-col"
              style={{
                display: 'flex',
                alignItems: 'start',
                position: sectionAlignment === 'custom' ? 'absolute' : 'inherit',
                zIndex: 11,
              }}>
              <div className="logo-section">
                <img
                  className="logo"
                  alt="logo"
                  style={section.logo ? {} : { opacity: 0 }}
                  src={utils.replaceHttp(section?.logo)}
                />
                {showProgress ? (
                  <div className="logo-upload-progress">
                    <p className="progress-title">Uploading Image...</p>
                    <p className="upload-image-name" title={imageName}>
                      {imageName}
                    </p>
                    <div className="spacer" />
                    <Slider value={uploadStatus.percent} tooltipVisible />
                  </div>
                ) : (
                  <div className="logo-hover" onClick={uploadLogo}>
                    <img
                      className="wrapper-logo"
                      src={utils.S3Image('application/', 'selectWhite.png')}
                      width="21"
                      alt="logo-select"
                    />
                    <a className="remove-logo w-inline-block" onClick={removeLogo}>
                      <img
                        src={utils.S3Image('application/', 'removeWhite.png')}
                        width="11"
                        alt="logo-remove"
                        style={section.logo ? {} : { opacity: 0 }}
                      />
                    </a>
                  </div>
                )}
              </div>
            </Col>
          )}

        <Col
          className="text-section-col"
          xs={sectionAlignment === 'middle' ? 24 : 24}
          sm={sectionAlignment === 'middle' ? 16 : 24}
          md={sectionAlignment === 'middle' ? 16 : 24}
          lg={sectionAlignment === 'middle' ? 16 : 24}>
          {headerAlignmentClass === 'proposalheader-custom' && (
            <h1
              className="titles proposalheaderwhite lightgrey"
              style={proposalHelpers.getStyleObj(proposal, 'title2', sectionAlignment)}>
              <RichEditor
                id={`${name}_subtitle`}
                prop={proposal}
                config={config}
                configText={configText}
                raw={section.rawsubtitle}
                content={section.subtitle}
                simple={0}
                sectionName={name}
                sectionType="rawsubtitle"
                saveProposalDraft={saveProposalDraft}
                setDisablePreview={setDisablePreview}
                setSavingDraftProposal={setSavingDraftProposal}
                blockRenderMap={proposalUtils.getBlockRenderMap()}
                zoomValue={zoomValue}
                setOpenVariablesList={setOpenVariablesList}
                setScrollingTo={setScrollingTo}
                dropDisabled
                {...rest}
              />
            </h1>
          )}

          <Row>
            <h1
              className="titles proposalheaderwhite"
              style={proposalHelpers.getStyleObj(proposal, 'title1')}>
              <RichEditor
                id={`${name}_title`}
                prop={proposal}
                config={config}
                configText={configText}
                raw={section.rawtitle}
                content={section.title}
                simple={0}
                sectionName={name}
                sectionType="rawtitle"
                saveProposalDraft={saveProposalDraft}
                setDisablePreview={setDisablePreview}
                setSavingDraftProposal={setSavingDraftProposal}
                zoomValue={zoomValue}
                blockRenderMap={proposalUtils.getBlockRenderMap()}
                setOpenVariablesList={setOpenVariablesList}
                setScrollingTo={setScrollingTo}
                dropDisabled
                {...rest}
              />
            </h1>

            <div className="resizable-container">
              <ResizableBox
                className={`box box3 ${titleLineDisplay ? '' : 'react-resizable-none'}`}
                width={titleLineDisplay ? titleWidth || 28 : 0}
                height={40}
                minConstraints={[28, 40]}
                maxConstraints={[sectionAlignment === 'custom' ? 750 : 520, 40]}
                axis={titleLineDisplay ? 'x' : 'none'}
                onResize={onResizeTitleUnderLine}
                onResizeStop={onResizeStop}
                handle={
                  titleLineDisplay && (
                    <Tooltip title="Drag to resize">
                      <ArrowRightOutlined
                        style={{
                          color: proposalHelpers.getStyleObj(proposal, 'title2').color || 'white',
                        }}
                      />
                    </Tooltip>
                  )
                }
                resizeHandles={
                  (languageRTL && getHeaderCoverTextAlignment !== 'left') ||
                  getHeaderCoverTextAlignment === 'right'
                    ? ['w']
                    : ['e']
                }>
                <React.Fragment>
                  {titleLineDisplay ? (
                    <>
                      <div
                        className="title-under-line"
                        style={{
                          borderColor: proposalHelpers.getStyleObj(proposal, 'title2').color,
                          width: `${titleWidth}px`,
                        }}
                      />
                      <Tooltip title="Hide Underline">
                        <EyeOutlined
                          style={{
                            color: proposalHelpers.getStyleObj(proposal, 'title2').color || 'white',
                          }}
                          onClick={() => toogleTitleUnderLine(false)}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <Tooltip title="Show Underline">
                      <EyeInvisibleOutlined
                        style={{
                          color: proposalHelpers.getStyleObj(proposal, 'title2').color || 'white',
                        }}
                        onClick={() => toogleTitleUnderLine(true)}
                      />
                    </Tooltip>
                  )}
                </React.Fragment>
              </ResizableBox>
            </div>
          </Row>

          {headerAlignmentClass !== 'proposalheader-custom' && (
            <h1
              className="titles proposalheaderwhite lightgrey"
              style={proposalHelpers.getStyleObj(proposal, 'title2')}>
              <RichEditor
                id={`${name}_subtitle`}
                prop={proposal}
                config={config}
                configText={configText}
                raw={section.rawsubtitle}
                content={section.subtitle}
                simple={0}
                sectionName={name}
                sectionType="rawsubtitle"
                saveProposalDraft={saveProposalDraft}
                setDisablePreview={setDisablePreview}
                setSavingDraftProposal={setSavingDraftProposal}
                blockRenderMap={proposalUtils.getBlockRenderMap()}
                zoomValue={zoomValue}
                setOpenVariablesList={setOpenVariablesList}
                setScrollingTo={setScrollingTo}
                dropDisabled
                {...rest}
              />
            </h1>
          )}
        </Col>

        {/* 
        {getHeaderLogoPosition && (sectionAlignment === 'middle' || sectionAlignment === 'custom') && (
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            className="logo-section-col"
            style={{
              display: logoPositionClass === 'logoposition-right' ? 'flex' : 'none',
              alignItems: 'start',
              position: sectionAlignment === 'custom' ? 'absolute' : 'inherit',
              zIndex: 9,
            }}>
            <div className="logo-section">
              <img
                className="logo"
                alt="logo"
                style={section.logo ? {} : { opacity: 0 }}
                src={section.logo && section.logo.replace('http://', 'https://')}
              />
              {showProgress ? (
                <div className="logo-upload-progress">
                  <p className="progress-title">Uploading Image...</p>
                  <p className="upload-image-name" title={imageName}>
                    {imageName}
                  </p>
                  <div className="spacer" />
                  <Slider value={uploadStatus.percent} tooltipVisible />
                </div>
              ) : (
                <div className="logo-hover" onClick={uploadLogo}>
                  <img
                    className="wrapper-logo"
                    src={utils.S3Image('application/', 'selectWhite.png')}
                    width="21"
                    alt="logo-select"
                  />
                  <a className="remove-logo w-inline-block" onClick={removeLogo}>
                    <img
                      src={utils.S3Image('application/', 'removeWhite.png')}
                      width="11"
                      alt="logo-remove"
                      style={section.logo ? {} : { opacity: 0 }}
                    />
                  </a>
                </div>
              )}
            </div>
          </Col>
        )} */}
      </Row>

      <Row className="header-by-container">
        {languageRTL && section.logo && headerAlignmentClass !== 'proposalheader-custom' ? (
          <Col md={8} xs={0} />
        ) : (
          ''
        )}
        <Col
          md={
            sectionAlignment === 'middle'
              ? {
                  span: 6,
                  offset: languageRTL || logoPositionClass === 'logoposition-right' ? 0 : 8,
                }
              : { span: 12 }
          }
          lg={
            sectionAlignment === 'middle'
              ? {
                  span: 6,
                  offset: languageRTL || logoPositionClass === 'logoposition-right' ? 0 : 8,
                }
              : { span: 12 }
          }
          xs={{ span: 12 }}
          sm={{ span: 12 }}>
          <div className="contact-by-values" style={proposalHelpers.getStyleObj(proposal, 'body1')}>
            <RichEditor
              id={`${name}_by`}
              prop={proposal}
              config={config}
              configText={configText}
              raw={section.rawby}
              content={section.by === '' || section.by ? section.by : 'By'}
              simple={0}
              sectionName={name}
              sectionType="rawby"
              saveProposalDraft={saveProposalDraft}
              setDisablePreview={setDisablePreview}
              setSavingDraftProposal={setSavingDraftProposal}
              blockRenderMap={proposalUtils.getBlockRenderMap()}
              zoomValue={zoomValue}
              setOpenVariablesList={setOpenVariablesList}
              setScrollingTo={setScrollingTo}
              dropDisabled
              {...rest}
            />
          </div>
          <h3 className="contact-by" style={proposalHelpers.getStyleObj(proposal, 'title1')}>
            <RichEditor
              id={`${name}_name`}
              prop={proposal}
              config={config}
              configText={configText}
              raw={section.rawname}
              content={section.name}
              simple={0}
              sectionName={name}
              sectionType="rawname"
              saveProposalDraft={saveProposalDraft}
              setDisablePreview={setDisablePreview}
              setSavingDraftProposal={setSavingDraftProposal}
              blockRenderMap={proposalUtils.getBlockRenderMap()}
              zoomValue={zoomValue}
              setOpenVariablesList={setOpenVariablesList}
              setScrollingTo={setScrollingTo}
              dropDisabled
              {...rest}
            />
          </h3>
        </Col>
        <Col
          className={`contact-by-values-${sectionAlignment}`}
          xs={12}
          sm={12}
          md={sectionAlignment === 'middle' ? 10 : 12}
          lg={sectionAlignment === 'middle' ? 10 : 12}>
          <div>
            <div
              className="contact-by-values"
              style={proposalHelpers.getStyleObj(proposal, 'body1')}>
              <RichEditor
                id={`${name}_contact`}
                prop={proposal}
                config={config}
                configText={configText}
                raw={section.rawcontact}
                content={section.contact === '' || section.contact ? section.contact : 'Contact'}
                simple={0}
                sectionName={name}
                sectionType="rawcontact"
                saveProposalDraft={saveProposalDraft}
                setDisablePreview={setDisablePreview}
                setSavingDraftProposal={setSavingDraftProposal}
                blockRenderMap={proposalUtils.getBlockRenderMap()}
                zoomValue={zoomValue}
                setOpenVariablesList={setOpenVariablesList}
                setScrollingTo={setScrollingTo}
                dropDisabled
                {...rest}
              />
            </div>
            <h3 className="contact-by" style={proposalHelpers.getStyleObj(proposal, 'title1')}>
              <RichEditor
                id={`${name}_email`}
                prop={proposal}
                config={config}
                configText={configText}
                raw={section.rawemail}
                content={section.email}
                simple={0}
                sectionName={name}
                sectionType="rawemail"
                saveProposalDraft={saveProposalDraft}
                setDisablePreview={setDisablePreview}
                setSavingDraftProposal={setSavingDraftProposal}
                blockRenderMap={proposalUtils.getBlockRenderMap()}
                zoomValue={zoomValue}
                setOpenVariablesList={setOpenVariablesList}
                setScrollingTo={setScrollingTo}
                dropDisabled
                {...rest}
              />
            </h3>
          </div>
        </Col>
      </Row>

      <input
        type="file"
        className="file_bag"
        accept="image/*"
        ref={inputFileRef}
        onChange={onLogoSelect}
        style={{ display: 'none', position: 'absolute', left: -10000 }}
      />

      {saveLogoAsDefaultModal && (
        <SaveAsDefaultModal
          onCancel={() => showLogoAsDefaultModal(false)}
          onConfirm={saveLogoAsDefault}
          prompt={'Save Logo As Default?'}
        />
      )}

      {
        // prevent section drop on top on section
        sectionDroppable && <div className="prevent-drop-mask" />
      }
    </div>
  );
};

HeaderSection.defaultProps = {
  config: '',
};

HeaderSection.propTypes = {
  proposal: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  saveProposal: PropTypes.func.isRequired,
  changeToolBarSideMenu: PropTypes.func.isRequired,
  saveProposalDraft: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  setDisablePreview: PropTypes.func.isRequired,
  config: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  configText: PropTypes.func,
};

export default HeaderSection;
