import { Button, Divider, Form, InputNumber, notification, Row, Spin, Switch, Tooltip } from 'antd';
import './PublishedProposalModal.scss';
import { AddUserIcon, EditIcon2, EmailOutlineIcon, EyeIcon3, InfoIcon, SendIcon2 } from 'components/Icons';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { GET_EMAIL_SETTINGS } from 'graphql/queries/emailSettingsQueries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { EmailPreviewModal } from 'pages/Emails/Components/Preview';
import { CustomizeEmailSettingModal } from './CustomizeEmailSettingModal';
import { AddRecipientPopover, EditRecipientPopover, PopoverComponent } from './RecipientModals';
import { UPDATE_PROPOSAL_EMAIL_SETTINGS } from 'graphql/mutations/emailSettingsMutations';
import { PROPOSAL_SUBSCRIPTION_TOPIC } from 'constants/index';
import moment from 'moment';

const schema = yup.object().shape({
  publishedProposalToProspect: yup.object().shape({
    // reminder: yup.number().optional(),
    status: yup.boolean(),
  }),
  reminderToProspect: yup.object().shape({
    status: yup.boolean().required(),
    reminder: yup
      .number()
      .transform((value, originalValue) => (originalValue === '' ? 0 : value))
      .when('status', {
        is: true,
        then: (schema) =>
          schema
            .required('The reminder days is required.')
            .min(1, 'Reminder must be at least 1 day.')
            .max(99, 'The reminder must be set within a maximum of 99 days.'),
        otherwise: (schema) => schema.nullable(),
      }),
  }),
  secondReminderToProspect: yup
    .object()
    .shape({
      status: yup.boolean(),
      reminder: yup
        .number()
        .transform((value, originalValue) => (originalValue === '' ? 0 : value))
        .when('status', {
          is: true,
          then: (schema) =>
            schema
              .required('The reminder days is required.')
              .min(1, 'Reminder must be at least 1 day.')
              .max(99, 'The reminder must be set within a maximum of 99 days.'),
          otherwise: (schema) => schema.nullable(),
        }),
    })
    .test('reminderToProspect.status', 'Please turn on the 1st reminder.', function (field) {
      return !(this.parent.reminderToProspect.status === false && field.status === true);
    })
    .test(
      'reminderToProspect.reminder',
      '2nd reminder must be greater than 1st reminder.',
      function (field) {
        return !(
          this.parent.reminderToProspect.reminder >= field.reminder && field.status === true
        );
      }
    ),
  signedPDFToProspect: yup.object().shape({
    // reminder: yup.number().optional(),
    status: yup.boolean(),
  }),
});

export const EmailSettingsSection = ({ onClose, proposal, onSettingUpdate }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [form] = Form.useForm();
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openCustomizeSettingModal, setCustomizeSettingModal] = useState(false);
  const [templates, setTemplates] = useState({});
  const [recipients, setRecipients] = useState([]);
  const currentSetting = useRef();
  const [isFormValid, setIsFormValid] = useState(false);
  const [operation, setOperation] = useState('');
  const [proposalInfo, setProposalInfo] = useState(proposal);

  useEffect(() => {
    setProposalInfo(proposal);
  }, [proposal]);

  const [updateEmailSettings, { loading: saving }] = useMutation(UPDATE_PROPOSAL_EMAIL_SETTINGS);

  const options = {
    publishedProposalToProspect: {
      title: 'Send proposal to the prospect',
      status: false,
      isReminder: false,
      label: 'Proposal',
    },
    reminderToProspect: {
      title: 'Send 1st reminder after',
      reminder: 5,
      label: '1st reminder',
      status: false,
      isReminder: true,
    },
    secondReminderToProspect: {
      title: 'Send 2nd reminder after',
      reminder: 4,
      label: '2nd reminder',
      status: false,
      isReminder: true,
    },
    signedPDFToProspect: {
      title: 'Send signed copy to the prospect',
      label: 'Signed copy',
      status: true,
      hasAttachment: true,
    },
  };
  const [validationError, setValidationError] = useState({});

  const yupSync = {
    async validator({ field }, value) {
      let obj = form.getFieldsValue();
      const keys = field.split('.');

      if (keys.length > 1) {
        obj[keys[0]][keys[1]] = value;
      } else {
        obj[keys[0]] = value;
      }

      try {
        await schema.validateSyncAt(field.split('.')?.[0], obj);
        setValidationError((prev) => ({ ...prev, [keys[0]]: undefined }));
      } catch (error) {
        setValidationError((prev) => ({ ...prev, [keys[0]]: error?.errors?.[0] }));
        throw error;
      }
    },
  };

  const [fetchEmailSettings, { data: { fetchEmailSettings: emailSettings } = {}, loading }] =
    useLazyQuery(GET_EMAIL_SETTINGS, {
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (isOpened && !emailSettings) {
      fetchEmailSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  useEffect(() => {
    if (proposal.emailSettings) {
      setRecipients(proposal.emailSettings.recipients);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (emailSettings) {
      const formValues = {};
      const templates = {};
      Object.keys(options).forEach((key) => {
        formValues[key] = proposal?.emailSettings?.[key] || {
          ...options[key],
          reminder: emailSettings[key]?.reminder,
        };

        if( proposal?.emailSettings?.[key]?.customize) {
          templates[key] = proposal.emailSettings[key]?.customize;
        }
      });
      form.setFieldsValue(formValues);
      setTemplates(templates);

      if (proposal?.emailSettings?.recipients && proposal.emailSettings.recipients.length > 0) {
        setRecipients(proposal.emailSettings.recipients);
      } else if (proposal?.draft?.variables?.client) {
        const clientVariable = proposal.draft?.variables?.client;
        if (clientVariable.email.value) {
          setRecipients([
            {
              firstName: clientVariable.firstName?.value || '',
              lastName: clientVariable.lastName?.value || '',
              email: clientVariable.email?.value,
            },
          ]);
        }
      }
      checkFormValidity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailSettings, proposal.emailSettings]);

  const onViewClick = (settingKey) => {
    currentSetting.current = {
      emailKey: settingKey,
      data: emailSettings[settingKey],
    };
    setOpenPreviewModal(true);
  };

  const onCustomizeEmail = () => {
    setOpenPreviewModal(false);
    setCustomizeSettingModal(true);
  };

  const onSubmit = async (values, operation = 'send') => {
    if (recipients.length === 0) {
      setValidationError({ ...validationError, recipients: 'Please add at least one recipient.' });
      return;
    }
    const removeExtraFields = (obj) =>
      Object.entries(obj).reduce((a, [k, v]) => {
        if (!['open', 'sent', 'clicked'].includes(k)) {
          a[k] = v;
        }
        return a;
      }, {});

    const settings = Object.keys(values).reduce((a, k) => {
      a[k] = {
        ...values[k],
        ...(templates?.[k]
          ? { customize: removeExtraFields({ ...templates[k], reminder: values[k].reminder }) }
          : {}),
      };
      return a;
    }, {});

    update(
      {
        ...settings,
        recipients,
      },
      operation,
      (data) => {
        if (data?.updateProposalEmailSettings) {
          notification.success({
            message:
              operation === 'save'
                ? 'Email settings have been saved'
                : 'Success! The proposal has been emailed.',
            style: {
              width: '460px',
            },
          });
          if (operation === 'send') {
            onClose();
          }
          setProposalInfo((prev) => ({
            ...prev,
            emailSettings: data.updateProposalEmailSettings?.emailSettings,
          }));
        }
      }
    );
  };

  const update = (settings, operation, onCompleted) => {
    setOperation(operation);
    updateEmailSettings({
      variables: {
        emailSettings: settings,
        operation: operation,
        propId: proposal?._id,
        topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
          proposal?.channel || proposal?.auid || proposal?.uid
        }`,
      },
      onCompleted: (data) => {
        if (data?.updateProposalEmailSettings) {
          onCompleted(data);
          onSettingUpdate?.(data.updateProposalEmailSettings?.emailSettings);
        }
      },
      onError: (error) => {
        console.log(error, 'error');
        notification.error({
          description: 'Something went wrong. Please try again later.',
        });
      },
    });
  };

  const updateRecipient = (recipientsList) => {
    update(
      {
        ...proposal.emailSettings,
        recipients: recipientsList,
      },
      'save',
      () => {
        notification.success({
          description: 'Recipients have been updated.',
        });
      }
    );
  };

  const onSave = () => {
    const errors = form.getFieldsError();
    const isValid = errors.every((field) => !field.errors.length);

    if (isValid) {
      const fieldsValues = form.getFieldsValue();
      onSubmit(fieldsValues, 'save');
    }
  };

  const onAddRecipient = (recipient) => {
    if (isOpened) {
      setRecipients((prev) => [...prev, recipient]);
      checkFormValidity();
    } else {
      updateRecipient([...proposal.emailSettings?.recipients, recipient]);
    }
  };

  const onEditRecipient = (recipients) => {
    if (isOpened) {
      setRecipients(recipients);
      checkFormValidity();
    } else {
      updateRecipient(recipients);
    }
  };

  const checkFormValidity = () => {
    const errors = form.getFieldsError();
    const isValid = errors.every((field) => !field.errors.length);
    console.log(errors, 'errors'); // Check if all fields are valid
    setIsFormValid(isValid);
  };

  const shouldDisabled = (index) => {
    const optionKeys = Object.keys(options);
    let disabled = false;

    for (let i = index; i < optionKeys.length; i++) {
      if (proposalInfo?.emailSettings?.[optionKeys[i]]?.sent === true) {
        disabled = true;
        break;
      }
    }
    return disabled;
  };

  return (
    <Row className="email-settings-wrapper">
      <Row
        className="header-row"
        style={isOpened ? { borderBottom: '1px solid #e2e3e8' } : { paddingBottom: '0px' }}
        onClick={() => setIsOpened(!isOpened)}>
        <Row className="header-text" justify="center">
          <EmailOutlineIcon />
          <span>Send email & auto-reminders</span>
        </Row>
        <div className="action-icon">{isOpened ? <UpOutlined /> : <DownOutlined />}</div>
      </Row>
      <Row style={{ width: '100%' }}>
        {loading ? (
          <>
            {!isOpened && <Divider style={{ margin: '16px 0px 0px 0px' }} />}
            <Row
              style={{ height: isOpened ? '200px' : '40px', width: '100%' }}
              justify="center"
              align="middle">
              <Spin size={isOpened ? 'default' : 'small'} />
            </Row>
          </>
        ) : isOpened ? (
          <Form
            form={form}
            initialValues={options}
            onFinish={onSubmit}
            onFieldsChange={checkFormValidity}
            className="proposal-email-settings-form">
            {Object.keys(options).map((settingKey, index) => {
              return (
                <Row style={{ gap: '4px' }}>
                  <Row
                    key={settingKey}
                    style={{ gap: '8px', width: '100%' }}
                    wrap={false}
                    align="middle">
                    <Form.Item
                      name={[settingKey, 'status']}
                      valuePropName="checked"
                      noStyle
                      rules={[yupSync]}
                      dependencies={[
                        [
                          settingKey === 'reminderToProspect'
                            ? 'secondReminderToProspect'
                            : 'reminderToProspect',
                          'status',
                        ],
                        ['reminderToProspect', 'reminder'],
                        ['secondReminderToProspect', 'reminder'],
                      ]}>
                      <Switch size="small" disabled={shouldDisabled(index)} />
                    </Form.Item>
                    <Form.Item
                      name={[settingKey, 'reminder']}
                      noStyle
                      dependencies={[
                        ['reminderToProspect', 'status'],
                        ['secondReminderToProspect', 'status'],
                        [
                          settingKey === 'reminderToProspect'
                            ? 'secondReminderToProspect'
                            : 'reminderToProspect',
                          'reminder',
                        ],
                      ]}
                      rules={options[settingKey]?.isReminder ? [yupSync] : null}>
                      <CustomNumberField
                        optionInfo={options[settingKey]}
                        sentInfo={proposalInfo?.emailSettings?.[settingKey]}
                        disabled={shouldDisabled(index)}
                      />
                    </Form.Item>
                    <div className="btn-view" onClick={() => onViewClick(settingKey)}>
                      <EyeIcon3 />
                    </div>
                  </Row>
                  <Form.Item shouldUpdate={true} noStyle>
                    {({ getFieldError, getFieldValue }) => {
                      const reminderErrors = getFieldError([settingKey, 'reminder']);
                      return reminderErrors.length > 0 &&
                        getFieldValue([settingKey, 'reminder']) ? (
                        <div
                          role="alert"
                          style={{ color: '#ff4d4f' }}
                          className="ant-form-item-explain-error">
                          <div>{reminderErrors[0]}</div>
                        </div>
                      ) : null;
                    }}
                  </Form.Item>
                </Row>
              );
            })}
            <Divider style={{ margin: '0' }} />
            <Row
              className="receipt-Recipients"
              style={{ gap: '16px' }}
              wrap={false}
              justify="space-between"
              align="top">
              <Row flex={1} className="email-list" justify="start">
                <div>
                  <b>Recipient:</b>{' '}
                  {recipients.length > 0 ? (
                    recipients.map((recipient) => recipient.email).join('; ')
                  ) : (
                    <span style={{ color: '#ff4d4f'}}>No recipient</span>
                  )}
                </div>
              </Row>
              <Row wrap={false} align="top" style={{ gap: '8px' }}>
                {recipients.length > 0 && (
                  <div className="btn-view">
                    <PopoverComponent
                      icon={<EditIcon2 />}
                      content={
                        <EditRecipientPopover
                          recipients={recipients}
                          onEditRecipient={setRecipients}
                        />
                      }
                    />
                  </div>
                )}
                <div className="btn-view">
                  <PopoverComponent
                    icon={<AddUserIcon />}
                    content={
                      <AddRecipientPopover
                        recipients={recipients}
                        onAddRecipient={onAddRecipient}
                      />
                    }
                  />
                </div>
              </Row>
            </Row>
            <Row className="footer-section">
              {proposalInfo.sharedViaMail === true || !recipients.length ? (
                <Tooltip title={proposalInfo.sharedViaMail === true ? "Proposal has already been sent." : "Recipient's email is required"}>
                  <div>
                    <Button
                      htmlType="submit"
                      disabled={true}
                      className="send-proposal-btn"
                      icon={<SendIcon2 />}>
                      Send Proposal
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                <Button
                  htmlType="submit"
                  loading={saving && operation === 'send'}
                  disabled={
                    proposalInfo.sharedViaMail === true ||
                    !isFormValid ||
                    recipients.length === 0 ||
                    (saving && operation !== 'send')
                  }
                  className="send-proposal-btn"
                  icon={<SendIcon2 />}>
                  Send Proposal
                </Button>
              )}
              <Button
                loading={saving && operation === 'save'}
                onClick={onSave}
                className="send-proposal-btn"
                disabled={
                  !isFormValid || recipients.length === 0 || (saving && operation !== 'save')
                }>
                Save
              </Button>
            </Row>
            <Row className="email-list" align='middle' style={{ gap: '4px' }}>
              <InfoIcon /><span style={{lineHeight:"12px"}}>Emails might land in your recipient's Promotions tab.</span>
            </Row>
          </Form>
        ) : proposalInfo?.emailSettings?.recipients?.length > 0 ? (
          <>
            <Divider style={{ margin: '16px 0px' }} />
            <Row
              className="receipt-Recipients"
              style={{ gap: '16px', width: '100%', padding: '0px 16px 16px 16px' }}
              wrap={false}
              justify="space-between"
              align="middle">
              <Row flex={1} className="email-list" justify="start">
                <div>
                  <b>Recipient:</b>{' '}
                  {proposalInfo?.emailSettings?.recipients.length > 0 ? (
                    proposalInfo?.emailSettings?.recipients
                      .map((recipient) => recipient.email)
                      .join('; ')
                  ) : (
                    <span style={{ color: '#ff4d4f' }}>No recipient</span>
                  )}
                </div>
              </Row>
              <Row wrap={false} align="top" style={{ gap: '8px', alignSelf: 'flex-start' }}>
                {proposalInfo?.emailSettings?.recipients.length > 0 && (
                  <div className="btn-view">
                    <PopoverComponent
                      icon={<EditIcon2 />}
                      content={
                        <EditRecipientPopover
                          recipients={proposalInfo?.emailSettings?.recipients}
                          onEditRecipient={onEditRecipient}
                        />
                      }
                    />
                  </div>
                )}
                <div className="btn-view">
                  <PopoverComponent
                    icon={<AddUserIcon />}
                    content={
                      <AddRecipientPopover
                        recipients={proposalInfo?.emailSettings?.recipients}
                        onAddRecipient={onAddRecipient}
                      />
                    }
                  />
                </div>
              </Row>
            </Row>
          </>
        ) : (
          <div style={{ paddingTop: '16px' }}></div>
        )}
      </Row>
      <EmailPreviewModal
        isOpen={openPreviewModal}
        emailKey={currentSetting.current?.emailKey}
        formData={
          templates?.[currentSetting.current?.emailKey] ||
          emailSettings?.[currentSetting.current?.emailKey]
        }
        generalSettings={emailSettings?.generalSettings}
        onCancel={() => setOpenPreviewModal(false)}
        hasAttachment={options[currentSetting.current?.emailKey]?.hasAttachment}
        staticHeader={true}
        onCustomizeEmail={onCustomizeEmail}
      />
      <CustomizeEmailSettingModal
        isOpen={openCustomizeSettingModal}
        emailKey={currentSetting.current?.emailKey}
        formData={
          templates?.[currentSetting.current?.emailKey] ||
          emailSettings?.[currentSetting.current?.emailKey]
        }
        onCancel={() => setCustomizeSettingModal(false)}
        onCustomize={(emailKey, values) => {
          setTemplates((prev) => ({ ...prev, [emailKey]: values }));
          setCustomizeSettingModal(false);
        }}
        config={options[currentSetting.current?.emailKey]}
        generalSettings={emailSettings?.generalSettings}
      />
    </Row>
  );
};

const CustomNumberField = ({ optionInfo, sentInfo, ...props }) => {
  return (
    <Row className="reminder-section">
      {sentInfo?.sent === true ? (
        <span className="text">
          {optionInfo?.label} sent on {moment(sentInfo?.date).format('DD MMM, YYYY')}
        </span>
      ) : (
        <Row style={{ gap: '8px' }} align="middle">
          <span className="text">{optionInfo.title}</span>
          {optionInfo.isReminder && (
            <InputNumber
              className="custom-number-input"
              {...props}
              type="number"
              inputMode="numeric"
              min={1}
              precision={0}
            />
          )}
          {optionInfo.isReminder && <span className="text">days</span>}
        </Row>
      )}
    </Row>
  );
};
