const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

const uuid = () => {
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

const objectId = (m = Math, d = Date, h = 16, s = (s) => m.floor(s).toString(h)) =>
  s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const isImage = (url) => /\.(?:gif|png|jpe?g|svg|bmp|webp)$/i.test(url);

const transformReadableText = (str) => {
  let data = str.split('\n').map((i) => i.split('='));
  let output = {};
  data.forEach((d) => {
    [, output[d[0]]] = d;
  });
  return output;
};

const getBrowserName = async () => {
  const { userAgent } = navigator;
  let browserName = '';
  let nameOffset, verOffset;
  let isBrave = await navigator?.brave?.isBrave();

  if (isBrave) {
    browserName = 'Brave Browser';
  } else if ((verOffset = userAgent.indexOf('Opera')) !== -1) {
    browserName = 'Opera';
  } else if ((verOffset = userAgent.indexOf('MSIE')) !== -1) {
    browserName = 'Microsoft Internet Explorer';
  } else if ((verOffset = userAgent.indexOf('Edg')) !== -1) {
    browserName = 'Edge';
  } else if (
    (verOffset = userAgent.indexOf('Chrome')) !== -1 ||
    (verOffset = userAgent.indexOf('CriOS')) !== -1
  ) {
    browserName = 'Chrome';
  } else if ((verOffset = userAgent.indexOf('Safari')) !== -1) {
    browserName = 'Safari';
  } else if ((verOffset = userAgent.indexOf('Firefox')) !== -1) {
    browserName = 'Firefox';
  } else if (
    (nameOffset = userAgent.lastIndexOf(' ') + 1) < (verOffset = userAgent.lastIndexOf('/'))
  ) {
    browserName = userAgent.substring(nameOffset, verOffset);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }

  return browserName;
};

const getOS = () => {
  let OSName = 'Unknown OS';
  if (navigator.userAgent.indexOf('Win') !== -1) OSName = 'Windows';
  if (navigator.userAgent.indexOf('Mac') !== -1) OSName = 'MacOS';
  if (navigator.userAgent.indexOf('X11') !== -1) OSName = 'UNIX';
  if (navigator.userAgent.indexOf('Linux') !== -1) OSName = 'Linux';

  return OSName;
};

const getDeviceName = () => {
  const navigatorBracketRegex = /\(([^)]+)\)/;
  const deviceMacRegex = /Mac/;
  const deviceAndroidRegex = /Android/;
  const deviceWindowsPhoneRegex = /Windows Phone/;
  const deviceWindowRegex = /Windows/;
  const deviceLinuxRegex = /Linux/;

  const iOSRegex = /\(([^)]+);/;
  const androidRegex = /; ([^)]+) Build/;
  const androidNameRegex = /; ([^)]+)/;

  const { userAgent } = navigator;
  const [deviceInfo] = userAgent.match(navigatorBracketRegex);

  let deviceName;

  if (deviceInfo.match(deviceMacRegex)) [, deviceName] = deviceInfo?.match(iOSRegex);
  else if (deviceInfo.match(deviceAndroidRegex))
    deviceName = deviceInfo?.match(androidRegex)?.[1]?.match(androidNameRegex)?.[1] || '';
  else if (deviceInfo.match(deviceWindowsPhoneRegex)) deviceName = 'Windows Phone';
  else if (deviceInfo.match(deviceWindowRegex)) deviceName = 'Windows';
  else if (deviceInfo.match(deviceLinuxRegex)) deviceName = 'Linux';
  else deviceName = 'Unknown';

  return deviceName;
};

const formatS3Link = (url) => {
  return url
    ? `${decodeURI(
        encodeURI(
          url
            ?.trim()
            ?.replace(process.env.REACT_APP_S3_URL, process.env.REACT_APP_CLOUD_FRONT_URL)
            ?.replace(
              'https://s3.us-west-2.amazonaws.com/prospero/',
              process.env.REACT_APP_CLOUD_FRONT_URL
            )
            ?.replace(
              'https://s3-us-west-2.amazonaws.com/prospero/',
              process.env.REACT_APP_CLOUD_FRONT_URL
            )
        )
      )}`
    : undefined;
};

// input => shorten(Bitcoin, 3, ...)
// output => Bit...
const shorten = (str, len, prefix) => {
  if (!str) return '';
  if (typeof str !== 'string' || str.length <= len) return str;
  return `${str.substring(0, len)}${prefix}`;
};

const getNumberWithOrdinal = (n) => {
  var s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

const prefixCurrencySymbol = (amount, currency, locale) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  });
  const formatted = formatter.format(amount);
  return formatted;
};

export const formatDateByRegion = (locale) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const formatter = new Intl.DateTimeFormat(locale, options);
  const date = new Date();
  const dayPlaceholder = date.getDate().toString().padStart(2, '0');
  const monthPlaceholder = (date.getMonth() + 1).toString().padStart(2, '0');
  const yearPlaceholder = date.getFullYear().toString();

  const formatted = formatter.format(date);
  return formatted
    .replace(dayPlaceholder, 'DD')
    .replace(monthPlaceholder, 'MM')
    .replace(yearPlaceholder, 'YYYY');
};

const isIOS = () => {
  const iosQuirkPresent = function () {
    const audio = new Audio();

    audio.volume = 0.5;
    return audio.volume === 1; // volume cannot be changed from "1" on iOS 12 and below
  };

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isAppleDevice = navigator.userAgent.includes('Macintosh');
  const isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)

  return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
};

const isTablet = () => {
  // Check if the device supports touch
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  // Check if the screen size is within tablet dimensions
  const isTabletSize = window.innerWidth >= 600 && window.innerWidth <= 1280;

  // Return true if all conditions are met
  return isTouchDevice && isTabletSize;
};

const rgbaToHexA = (r, g, b, a) => {
  const toHex = (n) => {
    const hex = n.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  const alpha = Math.round(a * 255);

  return `#${toHex(r)}${toHex(g)}${toHex(b)}${toHex(alpha)}`;
};

const helpers = {
  uuid,
  validateEmail,
  isImage,
  transformReadableText,
  getBrowserName,
  getOS,
  getDeviceName,
  formatS3Link,
  objectId,
  getNumberWithOrdinal,
  shorten,
  prefixCurrencySymbol,
  formatDateByRegion,
  isIOS,
  isTablet,
  rgbaToHexA,
};

export default helpers;
