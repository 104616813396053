import moment from 'moment';
import { Tooltip, Table, Divider, Row, Col } from 'antd';
import { getProposalTitle } from "helpers/proposal";
import './ActivityModal.scss';
import { useState } from 'react';

export const ActivityModal = ({ proposal, user }) => {
  const [activeTab, setActiveTab] = useState(0);
  const proposalLinkColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Opened',
      dataIndex: 'date',
      key: 'date',
      render: (date) => {
        if (date.length < 11) {
          // for 28-11-2022
          date = date.split('/').reverse().join('-');
          return <>{moment(date).format('MMM D, YYYY')}</>;
        }
        /* NEED this if showing invalid date for some
        else if (date.length < 17) {
          // for 28-11-2022 06:25
          date = date.split(' ');
          date[0] = date[0].split('/').reverse().join('-')
          date = date.join(' ')
        }
        */
        return <>{moment(date).format('MMM D, YYYY HH:mm')}</>;
      },
    },
    {
      title: 'Watched',
      dataIndex: 'watched',
      key: 'watched',
      render: (watched) => {
        let minutes = watched ? `${Math.floor((watched * 1000) / 60000)}` : null;
        let seconds = watched ? `${(((watched * 1000) % 60000) / 1000).toFixed(0)}` : null;

        if (minutes?.length < 2) minutes = `0${minutes}`;
        if (seconds?.length < 2 && seconds > 10) seconds = `${seconds}0`;
        else if (seconds && seconds < 10) seconds = `0${seconds}`;

        return (
          <>
            {minutes || '00'}:{seconds || '00'} <span className="open_logs_span">min</span>
          </>
        );
      },
    },
    {
      title: 'Device',
      dataIndex: 'deviceInfo',
      key: 'deviceInfo',
      render: (deviceInfo) => (
        <Tooltip
          overlayClassName="device-info-tooltip"
          title={
            deviceInfo ? (
              <div className="device-info-container">
                <Row>
                  <Col>{deviceInfo?.deviceName}</Col>
                  <Divider type="vertical" />
                  <Col>{deviceInfo?.deviceType}</Col>
                </Row>
                <Row>{deviceInfo.deviceAgent}</Row>
              </div>
            ) : null
          }>
          {deviceInfo?.deviceType}
        </Tooltip>
      ),
    },
    {
      title: 'Browser',
      dataIndex: 'deviceBrowser',
      key: 'deviceInfo',
      render: (_, { deviceInfo }) => <>{deviceInfo?.deviceBrowser || ''}</>,
    },
    {
      title: 'IP',
      dataIndex: 'clientIP',
      key: 'clientIP',
      render: (clientIP) => <>{clientIP.split('-')[0] || ''}</>,
    },
  ];

  const emailColumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: "30px",
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: "160px",
      render: (date) => {
        if (date.length < 11) {
          // for 28-11-2022
          date = date.split('/').reverse().join('-');
          return <>{moment(date).format('MMM D, YYYY')}</>;
        }
        /* NEED this if showing invalid date for some
        else if (date.length < 17) {
          // for 28-11-2022 06:25
          date = date.split(' ');
          date[0] = date[0].split('/').reverse().join('-')
          date = date.join(' ')
        }
        */
        return <>{moment(date).format('MMM D, YYYY HH:mm')}</>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'event',
      width: "70px",
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: "140px",
    },
    {
      title: 'Recipient',
      dataIndex: 'recipient',
      key: 'recipient',
      width: "100px",
      render: (recipient, item) => {
        return item.recipient || '-';
      },
    }
  ];


  const tabs = ['Proposal Link', 'Emails'];
  return (
    <>
      <div className="content">{getProposalTitle({ proposal, user })}</div>
      <Row className='activity-tabs'>
        {tabs.map((t, index) => <span className={`tab ${index === activeTab ? 'active' : ''}`} onClick={() => setActiveTab(index)}>{t}</span>)}
      </Row>
      <Table
        rowKey={'index'}
        columns={activeTab === 0 ? proposalLinkColumns : emailColumns}
        dataSource={( activeTab === 0 ? proposal?.openLogs : proposal?.emailLogs )|| []}
        pagination={{
          pageSize: 5,
        }}
      />
    </>
  );
};
