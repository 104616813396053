import { gql } from '@apollo/client';

export const UPDATE_PROPOSAL_SUBSCRIPTION = gql`
  subscription subscribeProposal($topic: String) {
    updateProposalSubscription(topic: $topic) {
      proposal {
        _id
        pid
        pnum
        uid
        auid
        name
        state
        draft
        email
        channel
        client {
          contact
          name
          desc
          address
          text
          email
        }
        seenTime
        seenCount
        lastSeen
       emailLogs {
        date
        recipient
        type
        event
      }
        openLogs {
          date
          clientIP
          duration
          watched
          deviceInfo {
            deviceType
            deviceOS
            deviceBrowser
            deviceName
            deviceAgent
          }
        }
        date
        project {
          name
          type
          othertype
          name
          desc
          goals
        }
        approvedmanually
        version
        sawyou
        pricing
        curr
        deliverables
        sawmilestones
        superPowers
        milestones
        curr
        language
        expiryDate
        expiryMessage
        currency
        callButton
        contactButton
        contactnumber
        clientPayableAmount
        clientPayableLabel
        clientPayableCurrency
        clientPayableLink
        scripts
        chatwayScripts
        stripe
        payment {
          status
          amount
          currency
          payment_intend
          created
        }
        published
        priceSeperator
        decimalPrecision
        enablePayment
        editEvent
        enableAITool
        userway
        creatorUserway
        dateFormat
        wixConnectedInstance
        editEvent
        disableSig
        signatureEmails
        selectedOption
        prevState
        payedfor
        redirectTo
        redirectAfter
        proposalRedirectTo
        proposalRedirectAfter
        sigOptions
        contactOptions {
          option
          value
          enabled
        }
        sigtype
        signature {
          name
          myname
          additionalSignature
          signatureBackgroundColor
          signatureTextColor
          additionalSignatureBackgroundColor
          additionalSignatureTextColor
          email
          date
          clientIP
          device
        }
        attachments {
          name
          type
          url
          size
        }
        isEditing {
          name
          uid
          tabID
        }
        importedProposal
        fromUserTemplate
        isBasicTemplate
        wixProposalId
        wixPricing {
          lineItems {
            quantity
            name
            description
            price
            pricesBreakdown {
              totalPriceAfterTax
              taxDetails {
                taxRate
                taxableAmount
                taxable
                totalTax
                taxName
                rateBreakdown {
                  rate
                  taxableAmount
                  tax
                }
              }
              totalDiscount
              price
              priceBeforeDiscounts
              lineItemPrice
              fullPrice
            }
            itemType {
              preset
            }
          }
          appliedDiscounts {
            discountType
            lineItemIds
          }
          additionalFees {
            name
            priceBeforeTax
            taxDetails {
              taxRate
              taxableAmount
              taxable
              totalTax
              taxName
              rateBreakdown {
                rate
                taxableAmount
                tax
              }
            }
            price
          }
          calculatedTotals {
            priceSummary {
              total
              subtotal
              discount
              additionalFees
            }
          }
          paymentSettings {
            type
            singlePaymentSchedule {
              dueDate
              dueDateOffset
              issueDate
              deposit {
                percentage
                amount
              }
              paymentStatus
              amountPaid
            }
            multiplePaymentSchedule {
              items {
                dueDate
                dueDateOffset
                issueDate
                deposit {
                  percentage
                  amount
                }
                issueDateOffset
                title
                percentage
                amount
                paymentStatus
                amountPaid
              }
            }
          }
          currencyCode
          locale {
            country
            language
          }
        }
        isWixTemplate
        chatway
        spamUrls
        sharedViaMail
        emailSettings {
          publishedProposalToProspect {
            status
            sent
            date
            reminder
            customize {
              subjectLine {
                RTL
                value
              }
              previewText {
                RTL
                value
              }
              emailBody {
                RTL
                value
              }
              buttonText {
                RTL
                value
              }
              reminder
            }
          }
          reminderToProspect {
            status
            sent
            date
            reminder
            customize {
              subjectLine {
                RTL
                value
              }
              previewText {
                RTL
                value
              }
              emailBody {
                RTL
                value
              }
              buttonText {
                RTL
                value
              }
              reminder
            }
          }
          secondReminderToProspect {
            status
            sent
            date
            reminder
            customize {
              subjectLine {
                RTL
                value
              }
              previewText {
                RTL
                value
              }
              emailBody {
                RTL
                value
              }
              buttonText {
                RTL
                value
              }
              reminder
            }
          }
          signedPDFToProspect {
            status
            sent
            date
            reminder
            customize {
              subjectLine {
                RTL
                value
              }
              previewText {
                RTL
                value
              }
              emailBody {
                RTL
                value
              }
              buttonText {
                RTL
                value
              }
              reminder
            }
          }
          recipients {
            firstName
            lastName
            email
          }
        }
      }
      typename
    }
  }
`;

export const UPDATE_DASHBOARD_PROPOSAL_SUBSCRIPTION = gql`
  subscription subscribeProposal($topic: String) {
    updateProposalSubscription(topic: $topic) {
      proposal {
        _id
        pid
        uid
        pnum
        name
        state
        draft
        client {
          contact
        }
        seenTime
        seenCount
        date
        lastSeen
        channel
        project {
          name
          type
          othertype
          desc
        }
        approvedmanually
        deliverables
        version
        sawyou
        pricing
        curr
        deliverables
        sawmilestones
        superPowers
        milestones
        signature {
          clientIP
        }
        sharedViaMail
        emailSettings {
          publishedProposalToProspect {
            status
            sent
            date
            reminder
            customize {
              subjectLine {
                RTL
                value
              }
              previewText {
                RTL
                value
              }
              emailBody {
                RTL
                value
              }
              buttonText {
                RTL
                value
              }
              reminder
            }
          }
          reminderToProspect {
            status
            sent
            date
            reminder
            customize {
              subjectLine {
                RTL
                value
              }
              previewText {
                RTL
                value
              }
              emailBody {
                RTL
                value
              }
              buttonText {
                RTL
                value
              }
              reminder
            }
          }
          secondReminderToProspect {
            status
            sent
            date
            reminder
            customize {
              subjectLine {
                RTL
                value
              }
              previewText {
                RTL
                value
              }
              emailBody {
                RTL
                value
              }
              buttonText {
                RTL
                value
              }
              reminder
            }
          }
          signedPDFToProspect {
            status
            sent
            date
            reminder
            customize {
              subjectLine {
                RTL
                value
              }
              previewText {
                RTL
                value
              }
              emailBody {
                RTL
                value
              }
              buttonText {
                RTL
                value
              }
              reminder
            }
          }
          recipients {
            firstName
            lastName
            email
          }
        }
      }
      typename
    }
  }
`;
