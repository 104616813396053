import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
    <path
      d="M6.24407 11.6271C6.64284 12.0876 7.35716 12.0876 7.75593 11.6271L10.7632 8.15466C11.3241 7.50701 10.864 6.5 10.0073 6.5L3.99275 6.5C3.136 6.5 2.67595 7.50701 3.23682 8.15465L6.24407 11.6271Z"
      fill="#2F2C88"
    />
  </svg>
);

const ArrowDownDropdown = (props) => <Icon component={svg} {...props} />;

export default ArrowDownDropdown;
