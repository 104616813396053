import { useMemo } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/align.min.js';
import { useRef } from 'react';
import { direction } from 'direction';
import './Editor.scss';

const generateUniqueId = () => {
  return 'editor-' + Math.random().toString(36).slice(2, 12);
};

export const Editor = ({ value, onChange, disabled = false, editorRef, savedRangeRef, height }) => {
  const editorId = useRef(generateUniqueId());

  const key = useMemo(() => {
    return generateUniqueId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.RTL]);

  const handleEditorChange = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const RTL = direction(doc.body.textContent) === 'rtl';
    onChange({ value: content, RTL });
  };

  const saveCursorPosition = () => {
    if (editorRef.current) {
      savedRangeRef.current = editorRef.current.selection.save(); // Save the cursor position
    }
  };

  return (
    <FroalaEditor
      key={key}
      tag="textarea"
      model={value.value}
      config={{
        editorClass: `${editorId.current} custom-editor`,
        height,
        key: process.env.REACT_APP_FROALA_EDITOR_KEY,
        attribution: false,
        toolbarButtons: {
          moreAction: {
            buttons: ['undo', 'redo'],
          },
          moreText: {
            buttons: ['bold', 'italic', 'underline'],
          },
          moreParagraph: {
            buttons: ['alignLeft', 'alignCenter', 'alignRight'],
          },
        },
        events: {
          initialized: function () {
            const editor = this;
            editorRef.current = editor;
            editorRef.current = this;
            if (disabled) editor.edit.off();

            this.events.on('blur', saveCursorPosition);
          },
        },
        pluginsEnabled: ['align', 'undo', 'redo'],
        direction: value?.RTL ? 'rtl' : 'ltr',
      }}
      onModelChange={handleEditorChange}></FroalaEditor>
  );
};
