import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Input, Row, Tooltip } from 'antd';
import './HyperLinkSection.scss';
import { HyperLinkIcon } from 'components/Icons';
import { isValidUrlFormat, urlMatch } from 'utils/hyperlink';

export const HyperLinkSection = ({
  link,
  position = {},
  dispatch,
  onSubmit,
  columnIndex,
  numberOfColumns,
  isRtl,
  uniqueKey,
}) => {
  const [url, setUrl] = useState('');
  const [urlError, setUrlError] = useState('');
  const [isValidUrl, setIsValidUrl] = useState(false);

  useEffect(() => {
    window.addEventListener('click', onCancel);
    if (link) {
      setUrl(link);
    }
    setIsValidUrl(!link || isValidUrlFormat(link));

    return () => {
      window.removeEventListener('click', onCancel);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  const onSave = () => {
    if (!!url && !urlMatch(url)) {
      setUrlError('error');
      return;
    }
    onSubmit(url);
    onCancel();
  };

  const onUrlChange = (e) => {
    setUrl(e.target.value);
    setIsValidUrl(e.target.value === '' || isValidUrlFormat(e.target.value));
  };

  const onCancel = () => {
    dispatch({
      type: 'setDisplayHyperlinkInput',
      displayHyperlinkInput: false,
    });
  };

  /*
  align image hyperlink modal to right for below condition
  for 2 column last section (1st index)
  for 3 column last section (2nd index)
  for 4 column last 2 section (2nd, 3rd index)
  */
  const positionX =
    (numberOfColumns === 2 && columnIndex === 1) ||
    ([3, 4].includes(numberOfColumns) && columnIndex > 1)
      ? { right: -85 }
      : { left: -85 };

  // for RTL swap right and left
  if (isRtl) {
    if (positionX.left) {
      positionX.right = positionX.left - 85;
      delete positionX.left;
    } else {
      positionX.left = 5;
      delete positionX.right;
    }
  }

  return (
    <>
      <div
        className="hyperlink-content"
        id={`img-hyperlink-${uniqueKey}`}
        style={{ ...position, ...positionX }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}>
        <Row wrap={false} className="hyperlink-wrappper" gutter={20}>
          <Col>
            <Input
              className={`hyperlink-input ${urlError}`}
              addonBefore={
                <Tooltip
                  overlayClassName="link-input-icon"
                  title="Enter a URL (e.g. https://yourlink.com), phone number (e.g. tel:+123456789), or email link (e.g. mailto:email@yourlink.com)">
                  <HyperLinkIcon />
                </Tooltip>
              }
              value={url}
              autoFocus
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onKeyDown={(e) => e.key === 'Enter' && onSave()}
              onChange={onUrlChange}
              placeholder="https://yourlink.com, tel:+123456789, mailto:email@yourlink.com"
            />
          </Col>
          <Button
            className={`hyperlink-btn ${isValidUrl && 'btn-valid'}`}
            type="primary"
            disabled={!isValidUrl}
            onClick={onSave}>
            Save
          </Button>
        </Row>
      </div>
    </>
  );
};

HyperLinkSection.propTypes = {
  link: PropTypes.string,
  onSubmit: PropTypes.func,
  dispatch: PropTypes.func,
  position: PropTypes.object,
};
