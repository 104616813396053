import ImageTools from './imageTools';
import utils from './utils';

const resizeImageForS3 = ({ file, path, size }) => {
  return new Promise((resolve, reject) => {
    if (file) {
      if (
        (path === 'signatures' ||
          path === 'logo' ||
          path === 'profile' ||
          path === 'testimonial') &&
        file.size > 2000000
      ) {
        reject(new Error('The file is too large. You can upload images up to 2MB.'));
      } else if (path === 'covers' && file.size > 1500000) {
        reject(new Error('The file is too large. You can upload images up to 1.5MB.'));
      } else if (path === 'attachments' && file.size > 3000000) {
        reject(new Error('Attachment file is too large. You can upload an attachment up to 3MB.'));
      } else if ((path === 'media' || path === 'sections') && file.size > 3000000) {
        reject(new Error('The file is too large. You can upload images up to 3MB.'));
      } else if (path === 'externalproposals' && file.size > 10000000) {
        reject(new Error('The file is too large. You can upload proposal up to 10MB.'));
      }
    }

    // dont resize gif file
    if (path === 'media' || file.type === 'image/gif') return resolve(file);

    ImageTools.resize(file, size, (blob, didItResize) => {
      let fileToUpload = null;
      if (didItResize) {
        if (utils.isSafari()) {
          blob.name = file && file.name;
          fileToUpload = blob;
        } else {
          fileToUpload = new File([blob], (file && file.name) || 'somefile.png', {
            type: file?.type || 'image/png',
          });
        }
      } else {
        fileToUpload = file;
      }
      return resolve(fileToUpload);
    });
  });
};

export { resizeImageForS3 };
