import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import UsersIcon from 'components/Icons/UsersIcon';
import CloseIcon from 'components/Icons/CloseIcon';
import images from 'constants/images';

import './SignupFinish.scss';

const SignupFinish = ({ createNewProposal, addUser, wixConnected, onCancel }) => {
  return (
    <div className="tutorial-container">
      <CloseIcon className="tutorial-close-icon" onClick={onCancel} />
      <div className="floating-object" />
      <div />
      <div className="floating-object" />
      <div className="tutorial-content">
        <div className="logo">
          <img src={images.LOGO} alt="prospero-logo" height="22" />
        </div>
        <div className={`tutorial-info text-center ${wixConnected && 'tutorial-info-large'}`}>
          {wixConnected ? (
            <p>
              <span>Wix Is Now Connected!</span>
              <br />
              <span>We synced all your services, products and prices with our proposals app</span>
            </p>
          ) : (
            <>
              <h3>You're All Set! 🙌</h3>
              <p>We hope you’ll enjoy Prospero</p>
            </>
          )}
        </div>
        <div className="ant-row tutorial-video-container text-center">
          <iframe
            height="200"
            src="https://www.youtube.com/embed/aBVv55Xvp6k"
            title="Prospero"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          <div className="play-prompt">
            <span>🎓</span>&nbsp;<a className="label">Watch the Prospero tutorial now</a>
          </div>
        </div>
        <div className="row">
          <Button
            size="large"
            type="text"
            block={true}
            className="tutorial-button invite-button"
            onClick={addUser}
            text={'INVITE YOUR TEAM TO COLLABORATE'}
            icon={<UsersIcon />}
          />
        </div>
        <div className="row">
          <Button
            type="primary"
            size="large"
            block={true}
            className="tutorial-button create-proposal-button"
            danger={true}
            onClick={createNewProposal}
            text={'CREATE YOUR FIRST PROPOSAL'}
          />
        </div>
      </div>
    </div>
  );
};

SignupFinish.propTypes = {
  createNewProposal: PropTypes.func.isRequired,
  addUser: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default SignupFinish;
