import { v4 as uuidv4 } from 'uuid';
import { EditorState, convertToRaw, genKey } from 'draft-js';

import { defaultFormFields } from 'pages/Proposal/constants/constants';
import { decorator } from '../../RichEditor/lib';
import utils from '../../RichEditor/utils';
import { testimonial } from 'constants/index';

const newSectionWrapper = (entityType, entityMutability, data = null) => {
  let newsection = {
    raw: {
      entityMap: {},
      blocks: [],
    },
  };

  const keyValue = Object.keys(newsection.raw.entityMap).length;

  newsection.raw.blocks.push({
    key: genKey(),
    text: ' ',
    type: 'atomic',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [
      {
        offset: 0,
        length: 1,
        key: keyValue ? keyValue : 0,
      },
    ],
    data: {},
  });

  newsection.raw.entityMap = {
    ...newsection.raw.entityMap,
    [keyValue]: {
      data: data,
      type: entityType,
      mutability: entityMutability,
    },
  };

  return newsection;
};

const handleInsertForm = async (prop) => {
  let newsection = {};

  const data = [...defaultFormFields];

  let editorState = EditorState.createEmpty(decorator);
  const contentState = editorState.getCurrentContent();
  let newConvertedRaw = convertToRaw(contentState);
  let newRawContent = { raw: newConvertedRaw };
  Object.assign(newsection, newRawContent);

  const keyValue = Object.keys(newsection.raw.entityMap).length;

  newsection.raw.blocks.push({
    key: genKey(),
    text: ' ',
    type: 'atomic',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [
      {
        offset: 0,
        length: 1,
        key: keyValue ? keyValue : 0,
      },
    ],
    data: {},
  });

  newsection.raw.entityMap = {
    ...newsection.raw.entityMap,
    [keyValue]: {
      type: 'form',
      mutability: 'MUTABLE',
      data: {
        data,
        config: { editable: true, columns: 1 },
        language: prop?.language?.toLowerCase(),
      },
    },
  };

  return newsection;
};

const handleInsertHTML = () => {
  return newSectionWrapper('html', 'MUTABLE', { htmlCode: '<span></span>' });
};

const handleInsertDivider = async () => {
  return newSectionWrapper('divider', 'IMMUTABLE', {});
};

const handleInsertText = () => {
  let newsection = {
    raw: {
      entityMap: {},
      blocks: [],
    },
  };

  newsection.raw.blocks.push({
    key: genKey(),
    text: '\n\n',
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {},
  });

  newsection.raw.entityMap = {
    ...newsection.raw.entityMap,
  };
  return newsection;
};

const handleInsertTable = () => {
  const data = [];
  const row = 3;
  const column = 3;

  for (let r = 0; r < row; r++) {
    data[r] = {};
    data[r].id = r + 1;
    for (let c = 0; c < column; c++) {
      data[r][c + 1] = '-';
    }
  }

  const tableData = {
    data: data,
    config: {
      topRowColor: 'lightgrey',
      rowColor: 'white',
    },
  };

  return newSectionWrapper('table', 'MUTABLE', tableData);
};

const handleInsertPriceMiles = async (ditype) => {
  let newsection = {};

  let editorState = EditorState.createEmpty(decorator);

  const es = await utils.insertTeXBlock(editorState, ditype);
  const contentState = es.getCurrentContent();
  let newConvertedRaw = convertToRaw(contentState);

  let newRawContent = { raw: newConvertedRaw };
  Object.assign(newsection, newRawContent);

  const keyValue = Object.keys(newsection.raw.entityMap).length;

  newsection.raw.entityMap = {
    ...newsection.raw.entityMap,
    [keyValue]: {
      type: 'TOKEN',
      mutability: 'IMMUTABLE',
      data: {
        texcontent: {
          pricing: {
            strategy: 'table',
          },
          curr: 'ABC',
          deliverables: {
            chosen: {
              'OPTION 1': {
                total: '',
                list: [
                  {
                    id: uuidv4(),
                    name: 'test rate for qa engineer per hour and deployment rate for prod ',
                    description: '',
                    price: 0,
                    type: null,
                    chosen: false,
                    selected: false,
                  },
                ],
              },
            },
          },
          roundTotal: false,
          priceSeperator: 'none',
        },
        config: {
          topRowColor: 'lightgrey',
          rowColor: 'white',
        },
      },
    },
  };

  return newsection;
};

const handleInsertBlockQuote = () => {
  let newsection = {
    raw: {
      entityMap: {},
      blocks: [],
    },
  };

  newsection.raw.blocks.push({
    key: genKey(),
    text: '\n',
    type: 'blockquote',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {},
  });

  newsection.raw.entityMap = {
    ...newsection.raw.entityMap,
  };
  return newsection;
};

const handleInsertGallery = () => {
  return newSectionWrapper('gallery', 'MUTABLE', { data: [] });
};

const handleInsertImage = async (imgUrl, config = {}) => {
  return newSectionWrapper('image', 'IMMUTABLE', { src: imgUrl, config });
};

const handleInsertVideo = async (url) => {
  return newSectionWrapper('media', 'IMMUTABLE', url);
};

const handleInsertTestimonial = () => {
  return newSectionWrapper('testimonial', 'MUTABLE', {
    data: [testimonial.data, testimonial.data, testimonial.data],
    config: {},
  });
};

const getSectionElements = {
  handleInsertTable,
  handleInsertPriceMiles,
  handleInsertHTML,
  handleInsertForm,
  handleInsertDivider,
  handleInsertText,
  handleInsertBlockQuote,
  handleInsertGallery,
  handleInsertImage,
  handleInsertVideo,
  handleInsertTestimonial,
};

export default getSectionElements;
