import React, { Fragment, useState, useEffect, forwardRef, useRef, useMemo } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { Col, Row } from 'antd';
import { convertFromRaw, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import _ from 'lodash';

import Input from 'components/Input';
import TeXHTMLString from 'pages/Proposal/components/RichEditor/components/TeX/TeXHTMLString';
import TestimonialPublish from 'pages/Proposal/components/RichEditor/components/TestimonialComponent/TestimonialPublish';
import { WixPriceMilesPublish } from 'pages/Proposal/components/RichEditor/wix-components';
import blockStyle from 'pages/Proposal/components/Publish/helpers/blockStyle';
import proposalHelpers from 'helpers/proposal';
import helpers from 'helpers';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { PROPOSAL_SUBSCRIPTION_TOPIC } from 'constants/index';
import { fontListEnglish, fontListHebrew } from 'pages/Proposal/constants/constants';
import utils from 'utils/utils';

import './PublishSection.scss';

const ReactGridLayout = WidthProvider(RGL);

const findSimpleSectionLayOut = ({ position, proposalTextCell }) => {
  const simpleSectionLayout = {
    simpleSectionTitleSpan: 8,
    simpleSectionItemsSpan: 16,
    sectionContainerStyle: {},
    headerStyle: {},
    proposalTextCell,
  };

  if (!position) return simpleSectionLayout;
  if (position === 'top') {
    simpleSectionLayout.sectionContainerStyle = {
      // paddingBottom: '30px',
      display: 'flex',
    };
    simpleSectionLayout.headerStyle = {
      width: '100%',
    };
    simpleSectionLayout.simpleSectionTitleSpan = 24;
    simpleSectionLayout.simpleSectionItemsSpan = 24;
  } else if (position === 'top-middle') {
    simpleSectionLayout.sectionContainerStyle = {
      display: 'flex',
    };
    simpleSectionLayout.headerStyle = {
      width: '100%',
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    };
    simpleSectionLayout.simpleSectionTitleSpan = 24;
    simpleSectionLayout.simpleSectionItemsSpan = 24;
  } else if (position === 'none') {
    simpleSectionLayout.headerStyle = {
      display: 'none',
    };
    simpleSectionLayout.simpleSectionTitleSpan = 24;
    simpleSectionLayout.simpleSectionItemsSpan = 24;
  } else if (position === 'large') {
    simpleSectionLayout.simpleSectionTitleSpan = 4;
    simpleSectionLayout.simpleSectionItemsSpan = 20;
  }
  return simpleSectionLayout;
};

const PublishSection = forwardRef((props, ref) => {
  const { isMobile: _isMobile, width: screenWidth } = useWindowDimensions();

  const {
    name,
    prop,
    configText,
    ispdf,
    updateProposalClient,
    showValidationError,
    setIsSavingForm,
    proposalSettings,
    screen,
    wixPreview,
    clientWixPreview,
    templateWixPreview,
    userWixPreview,
  } = props;

  const isMobile = _isMobile || screen === 'mobile';
  const isWix =
    prop?.wixProposalId?.length ||
    wixPreview ||
    clientWixPreview ||
    templateWixPreview ||
    userWixPreview;

  const sectionRef = useRef(prop.draft[name]);
  const focusedInputRef = useRef('');
  const [section, setSection] = useState('');
  // when editing prevent section update
  const [edited, setEdited] = useState(false);
  const [fonts, setFonts] = useState({});

  useEffect(() => {
    if (!edited) {
      setSection(prop.draft[name]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, prop.draft]);

  const setSectionRef = (data) => {
    focusedInputRef.current = '';
    sectionRef.current = data;
    setSection(data);
  };

  const getBody =
    prop.draft.bodyFont && prop.draft.bodyFont.fontSize ? prop.draft.bodyFont.fontSize : false;
  const titlePosition =
    section?.titlePosition ||
    (prop.draft.titleFont && prop.draft.titleFont.position ? prop.draft.titleFont.position : false);

  let fontSizeValue = 0;
  if (getBody) [fontSizeValue] = getBody.match(/\d+/);

  let titlehtml =
    templateWixPreview && name === 'milestones2' ? configText('payment terms') : section?.title;
  if (section?.rawtitle) {
    const titlecs = convertFromRaw(section.rawtitle);
    titlehtml =
      templateWixPreview && name === 'milestones2'
        ? configText('payment terms')
        : stateToHTML(titlecs, blockStyle({ language: prop.language }));
  }

  let subtitlehtml = section?.subtitle ? section.subtitle : null;
  if (section?.subrawtitle) {
    const subtitlecs = convertFromRaw(section.subrawtitle);
    subtitlehtml = stateToHTML(subtitlecs, blockStyle({ language: prop.language }));
  }

  useEffect(() => {
    if (focusedInputRef.current) {
      const txt = document.getElementById(focusedInputRef.current);
      txt?.focus();
      var length = txt?.value?.length;
      txt?.setSelectionRange(0, length);
    }
  }, [focusedInputRef.current]); // eslint-disable-line

  const debouncedSave = useRef(
    _.debounce((updatedSection, prop) => {
      let proposalInfo = {
        id: prop._id,
        _id: prop._id,
        pid: prop.pid,
        uid: prop.uid,
      };

      proposalInfo = Object.assign(proposalInfo, {
        draft: _.extend(prop.draft, updatedSection),
      });

      updateProposalClient({
        notifyOnNetworkStatusChange: true,
        variables: {
          topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
            proposalInfo?.channel || proposalInfo?.auid || proposalInfo?.uid
          }`,
          updateType: 'edit',
          proposal: proposalInfo,
        },
      }).then(
        () => setIsSavingForm(false),
        () => setIsSavingForm(false)
      );
    }, 1000)
  ).current;

  const handleFormEdit = (e) => {
    if (e.target.className.match('formfieldpublish')) {
      setIsSavingForm(true);

      const [elementId, blockIndex, sectionName] = e.target?.id?.split('-');
      const formData =
        sectionRef?.current?.raw?.entityMap[blockIndex] &&
        sectionRef?.current?.raw?.entityMap[blockIndex]?.data?.data;
      const atomicType = sectionRef?.current?.raw?.entityMap[blockIndex]?.type;

      if (atomicType === 'form' && formData && name === sectionName.replaceAll('&hyphen&', '-')) {
        const tempFormData = formData.map((form) => {
          if (form?.key === elementId.replaceAll('&space&', ' ').replaceAll('&hyphen&', '-')) {
            form.value = e.target.value?.trim();
            if (form.required) {
              form.isFilled = true;
            }
          }
          return form;
        });

        let { entityMap } = JSON.parse(JSON.stringify(sectionRef?.current.raw));

        entityMap[blockIndex].data.data = tempFormData;

        let updatedSection = {
          [name]: _.extend(sectionRef?.current, {
            raw: {
              blocks: sectionRef?.current.raw.blocks,
              entityMap,
            },
          }),
        };

        debouncedSave(updatedSection, prop);
        setSectionRef(updatedSection[name]);
      }
    }
  };

  const handleFormFocusIn = (e) => {
    if (e.target.className.match('formfieldpublish')) {
      focusedInputRef.current = e.target?.id;
    } else {
      focusedInputRef.current = '';
    }
  };

  const handleCheckboxEdit = (e) => {
    if (e.target.className === 'priceTableCheckbox') {
      const ids = e.target.id?.split('-');
      const optionIndex = parseInt(e.target['value']) || 0;
      const tempIds = [...ids];
      const tempSectionName =
        ids?.length > 8 ? tempIds.splice(ids?.length - 5)?.join('-') : ids[ids?.length - 1];

      if (name === tempSectionName) {
        const blockIndex = ids?.length > 8 ? ids[ids?.length - 7] : ids[ids?.length - 3];
        const selectedItemId = tempIds.slice(0, 5)?.join('-');

        const tempSection = sectionRef?.current;
        const content =
          tempSection?.raw?.entityMap[blockIndex] &&
          tempSection?.raw?.entityMap[blockIndex]?.data?.texcontent;
        const atomicType = tempSection?.raw?.entityMap[blockIndex]?.type;

        if (content && atomicType === 'TOKEN') {
          let json;
          try {
            json = JSON.parse(content);
          } catch (exx) {
            return '[pricing block]';
          }

          if (json?.deliverables) {
            let { entityMap } = JSON.parse(JSON.stringify(tempSection.raw));

            const keys = Object.keys(json.deliverables.chosen);
            json.deliverables.chosen[keys[optionIndex]].list = json.deliverables.chosen[
              keys[optionIndex]
            ].list.map((item) => {
              if (item.id === selectedItemId) {
                item.checked = e.target.checked;
              }
              return item;
            });

            entityMap[blockIndex].data.texcontent = JSON.stringify(json);

            let updatedSection = {
              [name]: _.extend(tempSection, {
                raw: {
                  blocks: tempSection.raw.blocks,
                  entityMap,
                },
              }),
            };

            // prevent section update for 60 sec
            setEdited(true);
            setTimeout(() => setEdited(false), 60000);

            // updating proposal, so prevent any other simultaneous updates
            setIsSavingForm(true);
            debouncedSave(updatedSection, prop);
            setSectionRef(updatedSection[name]);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (window.location.href.indexOf('/cd/') !== -1 || clientWixPreview) {
      document.addEventListener('focusout', handleFormEdit);
      document.addEventListener('focusin', handleFormFocusIn);
    }
    return () => {
      document.removeEventListener('focusout', handleFormEdit);
      document.removeEventListener('focusin', handleFormFocusIn);
    };
  }, []); //eslint-disable-line

  useEffect(() => {
    if (window.location.href.indexOf('/cd/') !== -1 || clientWixPreview) {
      document.addEventListener('click', handleCheckboxEdit);
    }
    return () => {
      document.removeEventListener('click', handleCheckboxEdit);
    };
  }, []); // eslint-disable-line

  const proposalTextCell = proposalHelpers.getStyleObj(prop, 'body2');

  if (fontSizeValue && fontSizeValue <= 14) {
    proposalTextCell.marginTop = '10px';
    proposalTextCell.letterSpacing = '1px';
  }

  // const simpleSectionLayout = findSimpleSectionLayOut({
  //   position: titlePosition,
  //   proposalTextCell,
  // });
  const simpleSectionLayout = useMemo(
    () =>
      findSimpleSectionLayOut({
        position: titlePosition,
        proposalTextCell,
      }),
    [titlePosition, proposalTextCell]
  );

  const getComponentType = (texContent) => {
    const [contentType] = Object.keys(JSON.parse(texContent));
    return contentType;
  };

  const getFontDetail = (fontFamily) => {
    if (!fontFamily) {
      return null;
    }

    return (
      prop.language?.toLowerCase() === 'hebrew' || prop.language?.toLowerCase() === 'arabic'
        ? fontListHebrew
        : fontListEnglish
    ).find((item) => item.family === fontFamily);
  };

  const getSectionHtml = (raw, text, fullWidthImage) => {
    let html = text;
    // eslint-disable-next-line no-empty
    if (raw) {
      const textcs = convertFromRaw(raw);
      html = stateToHTML(textcs, {
        blockRenderers: {
          atomic: (block) => {
            let data,
              type = undefined;

            try {
              const contentState = ContentState.createFromBlockArray([block]);
              const ent = block.getEntityAt(0);
              const entity = contentState.getEntity(ent);
              data = entity?.data;
              type = entity?.type;
            } catch (e) {
              // nothing in entityMap if block is atomic
              return null;
            }

            if (data && data.texcontent) {
              const blockKey = block.getKey();
              let blockIndex;

              section?.raw?.blocks.forEach((b) => {
                if (b.key === blockKey) {
                  blockIndex = b.entityRanges?.[0]?.key;
                }
              });

              return ReactDOMServer.renderToStaticMarkup(
                <div className={`pricing-milestone-container ${getComponentType(data.texcontent)}`}>
                  {isWix ? (
                    <WixPriceMilesPublish
                      templateWixPreview={templateWixPreview}
                      prop={props.prop}
                      texcontent={data.texcontent}
                      wixPreview={wixPreview}
                      userWixPreview={userWixPreview}
                      configText={configText}
                    />
                  ) : (
                    <TeXHTMLString
                      content={data.texcontent}
                      configText={configText}
                      props={props}
                      blockIndex={blockIndex}
                    />
                  )}
                </div>
              );
            }

            if (data && data.data && type === 'table') {
              const tableData = data.data;
              const tableConfig = data.config;

              const list = tableData.map((p, rowIndex) => {
                return (
                  <tr
                    className="grey2"
                    key={p.id}
                    style={
                      rowIndex === 0
                        ? { backgroundColor: tableConfig?.topRowColor }
                        : { backgroundColor: tableConfig?.rowColor }
                    }>
                    {Object.keys(p)
                      .filter((k) => k !== 'id')
                      .map((k) => {
                        return (
                          <td className="grey1" key={`${p.id}${k}`}>
                            {p[k]}
                          </td>
                        );
                      })}
                  </tr>
                );
              });

              const tableHtml = () => (
                <figure className="table-content-container">
                  <table cellSpacing="3" style={{ align: 'center' }} className="table-container">
                    <tbody>{list}</tbody>
                  </table>
                </figure>
              );

              const tableStaticHtml = ReactDOMServer.renderToStaticMarkup(tableHtml());
              return tableStaticHtml;
            }

            if (data && data.data && type === 'testimonial') {
              const { data: testimonials, config } = data;

              const testimonialHtml = (
                <TestimonialPublish
                  bodyFont={props.prop?.draft?.bodyFont}
                  language={prop.language?.toLowerCase()}
                  testimonials={testimonials}
                  config={config}
                  fonts={fonts}
                  setFonts={setFonts}
                  getFontDetail={getFontDetail}
                />
              );

              return ReactDOMServer.renderToStaticMarkup(testimonialHtml);
            }

            if (data && data.data && type === 'form') {
              const formData = JSON.parse(JSON.stringify(data.data));
              const formConfig = data.config;
              const blockKey = block.getKey();
              let blockIndex;

              section?.raw?.blocks.forEach((b) => {
                if (b.key === blockKey) {
                  blockIndex = b.entityRanges[0].key;
                }
              });
              let formTitle = formData.find((elem) => elem.head);
              if (formTitle) {
                if (formTitle.custom) {
                  formTitle = formTitle.key;
                } else {
                  formTitle = configText('Please fill the form below');
                }
              } else {
                formTitle = configText('Please fill the form below');
              }

              const formHtml = () => (
                <figure>
                  <div className="form-view">
                    <Row className="form-view-header">
                      <h3>{formTitle}</h3>
                    </Row>
                    <Row className="form-view-fields-row">
                      <div
                        id="form-list-items"
                        className={`form-list-items ${
                          formConfig.columns === 2 ? 'form-list-two-columns' : ''
                        }`}>
                        {_.map(formData, (item, listIndex) =>
                          item.selected ? (
                            <div
                              key={`${item.key}`}
                              // onClick={() => handleItem(listIndex, item.selected ? false : true)}
                              className={
                                item.selected ? 'form-list-item selected' : 'form-list-item'
                              }>
                              <label>{configText(item.key)}</label>{' '}
                              {item.required ? <span className="required">*</span> : null}
                              <Input
                                type="text"
                                placeholder={`${configText('Enter')} ${configText(item.key)}`}
                                id={`${item?.key
                                  ?.replaceAll(' ', '&space&')
                                  ?.replaceAll('-', '&hyphen&')}-${blockIndex}-${name?.replaceAll(
                                  '-',
                                  '&hyphen&'
                                )}`}
                                className={`formfieldpublish ${
                                  item.required && item.value === '' && showValidationError
                                    ? 'error-input'
                                    : ''
                                }`}
                                value={item.value}
                                disabled={
                                  window.location.href.indexOf('/cd/') === -1 &&
                                  window.location.href.indexOf('/PDF/') === -1 &&
                                  !clientWixPreview
                                    ? true
                                    : false
                                }
                              />
                              {item.required && item.value === '' && showValidationError ? (
                                <span className="form-error-text">
                                  {configText('Please enter')} {configText(item.key)}
                                </span>
                              ) : null}
                            </div>
                          ) : (
                            <Fragment key={listIndex}></Fragment>
                          )
                        )}
                      </div>
                    </Row>
                  </div>
                </figure>
              );

              const formStaticHtml = ReactDOMServer.renderToStaticMarkup(formHtml());
              return formStaticHtml;
            }

            if (data && data.data && type === 'gallery') {
              const galleryImages = data.data;

              const galleryHtml = () => (
                <ReactGridLayout
                  className="layout"
                  height={150}
                  width={1200}
                  rowHeight={30}
                  margin={[2, 2]}
                  preventCollision={false}
                  isDraggable={false}
                  isResizable={false}
                  style={galleryImages.length ? {} : { display: 'none' }}>
                  {galleryImages.map((galleryImg) => (
                    <div
                      key={galleryImg.i}
                      style={{
                        position: 'initial',
                        backgroundImage: `url("${helpers.formatS3Link(galleryImg?.image)}")`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '50% 50%',
                      }}
                      data-grid={{
                        i: galleryImg.i,
                        x: galleryImg.x,
                        y: galleryImg.y,
                        w: galleryImg.w,
                        h: galleryImg.h,
                      }}
                    />
                  ))}
                </ReactGridLayout>
              );

              const galleryStaticHtml = ReactDOMServer.renderToStaticMarkup(galleryHtml());
              return galleryStaticHtml;
            }

            if (data && data.src && (type === 'image' || type === 'IMAGE')) {
              const imgContent = helpers.formatS3Link(data?.src);
              const imgWidth =
                (data?.config?.size?.width && window?.screen?.width > 480) ||
                (window?.screen?.width < 480 && data?.config?.size?.width < 360)
                  ? `${data?.config?.size?.width}px`
                  : '100%';
              const imgHeight = data?.config?.size?.height
                ? `${data?.config?.size?.height}`
                : '100%';
              const imgShadow = data?.config?.shadow;
              const imgBorder = data?.config?.border;
              const { opacity } = data?.config || {};

              const imgBoxShadow = `rgba(${imgShadow?.hueColor?.rgb?.r || 0},${
                imgShadow?.hueColor?.rgb?.g || 0
              },${imgShadow?.hueColor?.rgb?.b || 0},${imgShadow?.alphaColor?.rgb?.a || 1}) ${
                imgShadow?.xOffset || 0
              }px ${imgShadow?.yOffset || 0}px ${imgShadow?.blur || 0}px ${
                imgShadow?.spread || 0
              }px`;

              const imgBorderStyle =
                `${imgBorder?.borderWidth || 0}px solid ${
                  imgBorder?.borderColor?.hex || 'black'
                }` || 'none';
              const imgBorderRadiusStyle = `${imgBorder?.borderRadius || 0}px`;

              let imageStyle = `box-shadow: ${imgBoxShadow}; border: ${imgBorderStyle}; border-radius: ${imgBorderRadiusStyle}; opacity: ${
                opacity ?? 1
              };`;

              if (imgHeight) {
                imageStyle =
                  imageStyle + `aspect-ratio: ${data.config?.size?.width || 900} / ${imgHeight};`; //`height:${imgHeight};`;
              }
              if (imgWidth && !fullWidthImage) {
                imageStyle = imageStyle + `width:${imgWidth};`;
              }

              imageStyle = imageStyle + `margin: 20px 0px 10px 0px;`;

              const imgTag = `<img alt=""  src="${imgContent}" style="${imageStyle}" />`;
              return `<figure class="image-figure"  style="text-align:${data?.config?.alignment};">
                       ${
                         data?.hyperlink
                           ? `<a  href="${data?.hyperlink}" onClick="(function(e){
                            e.preventDefault();
                            e.stopPropagation();
                            window.open('${data?.hyperlink}', '_blank').focus();
                            return false;
                        })(arguments[0]);return false;">
                        ${imgTag}
                      </a>`
                           : imgTag
                       }
                </figure>`;
            }

            if (data && data.htmlCode && type === 'html') {
              const { htmlCode, config } = data;
              const htmlWidth = `${config?.size?.width || 530}px` || 'auto';
              const htmlHeight = `${config?.size?.height || 300}px` || 'auto';

              return `<div class="html-content" style="height: ${htmlHeight};width: ${htmlWidth}">
                  <div>${htmlCode}</div>
                </div>`;
            }

            if (data && type === 'divider') {
              return `<div style="border: 1px solid;margin-top: 1em;"></div>`;
            }

            if (data && data.html) {
              if (!ispdf) {
                const videoHtml = data.html;
                const temp = document.createElement('div');
                temp.innerHTML = videoHtml;

                const theIframe = temp.firstChild;
                let aspect = 56.25;

                const width = Number(theIframe.width);
                const height = Number(theIframe.height);
                if (width && height) {
                  aspect = (height / width) * 100;
                }

                return `
                  <figure>
                    <div class="media-component" style="text-align:center">
                        <div class="embed-wrap" style="padding-bottom:${aspect}%;">${videoHtml}</div>
                    </div>
                  </figure>
                `;
              }

              return `
                  <figure>
                    <div class="media-component" style="text-align:center">
                        <img class="pdf-media-thumb" src="${data.thumbnail_url}" />
                        <div class="origlink">${data.original_link}</div>
                      </div>
                  </figure>
                `;
            }

            return null;
          },
        },
        ...blockStyle({ language: prop.language }),
      });
    }

    html = html?.replace(/<.?p.?><.?br.?><.?\/.?p.?>$/, '');

    html = html
      ? utils.changeVariable(props?.prop?.draft?.variables, html, proposalSettings?.dateFormat)
      : html;

    return html;
  };

  const getSpan = (span, index) => {
    if (span) {
      return span;
    } else {
      if (section.numberOfColumns === 2) {
        if (section.typeOfColumns === 'two') {
          return 12;
        } else if (section.typeOfColumns === 'tworight') {
          if (index === 0) return 18;
          else return 6;
        } else if (section.typeOfColumns === 'twoleft') {
          if (index === 0) return 6;
          else return 18;
        }
      } else if (section.numberOfColumns === 3) {
        return 8;
      } else if (section.numberOfColumns === 4) {
        return 6;
      } else {
        24;
      }
    }
  };

  const mainSection = (raw, text, index, span, style, className) => {
    const sectionStyle =
      style ||
      Object.assign(
        { ...(isMobile && { float: 'unset' }) },
        index === 0
          ? isMobile
            ? { paddingBottom: '8px' }
            : { paddingRight: '8px' }
          : index
          ? isMobile
            ? { paddingBottom: '8px' }
            : { paddingLeft: '8px' }
          : {}
      );
    const sectionSpan = screen === 'mobile' ? 24 : getSpan(span, index);

    return (
      <Col
        key={index}
        span={sectionSpan}
        style={sectionStyle}
        className={className}
        dangerouslySetInnerHTML={{ __html: getSectionHtml(raw, text) }}
      />
    );
  };

  const renderMainSection = () => {
    if (section?.numberOfColumns && section?.numberOfColumns !== 1) {
      const sectionContentStyle = Object.assign(
        simpleSectionLayout.proposalTextCell,
        isMobile ? { flexDirection: 'column' } : {}
      );

      return (
        <Col
          span={simpleSectionLayout.simpleSectionItemsSpan}
          className={`simple-section-content ${isMobile ? 'column' : ''} ${
            screen !== 'mobile' && section.numberOfColumns > 1
              ? 'simple-section-content-multiple'
              : ''
          }`}
          style={sectionContentStyle}>
          {_.times(section.numberOfColumns, (index) =>
            mainSection(
              section[`${index === 0 ? 'raw' : `raw${index}`}`],
              section[`${index === 0 ? 'text' : `text${index}`}`],
              index
            )
          )}
        </Col>
      );
    } else {
      return mainSection(
        section?.raw,
        section?.text,
        0,
        simpleSectionLayout.simpleSectionItemsSpan,
        simpleSectionLayout.proposalTextCell,
        'simple-section-content'
      );
    }
  };

  return (
    <nobreak
      is="separator"
      id={prop.libraryId ? prop.libraryId : prop.name}
      class={`section-wrapper nobreak-${prop.name} ${section?.image ? 'avoid-nobreak' : ''}`}
      ref={ref}>
      <div
        id={name}
        className={`simple-section  ${section?.image && 'proposalblock-image'} ${
          titlePosition && `editor-layout-position-${titlePosition}`
        }`}
        style={fontSizeValue && fontSizeValue <= 14 ? { padding: '20px 50px 0px' } : {}}>
        {section?.image ? (
          <div className="simple-section-container">
            <div className="w-row">
              <div
                className="danger-div image-publish-container simple-section-content"
                style={{ width: '100%' }}
                dangerouslySetInnerHTML={{ __html: getSectionHtml(section?.raw, null, true) }}
              />
            </div>
          </div>
        ) : (
          <Row
            className={`simple-section-container ${isMobile ? 'column-view' : ''}`}
            style={{
              ...simpleSectionLayout.sectionContainerStyle,
              ...(screenWidth < 768 && { flexFlow: 'column' }),
            }}>
            <Col
              span={simpleSectionLayout.simpleSectionTitleSpan}
              className="simple-section-title-block"
              style={simpleSectionLayout.headerStyle}>
              <div
                className="simple-section-title"
                style={proposalHelpers.getStyleObj(prop, 'title3')}
                dangerouslySetInnerHTML={{
                  __html: utils.changeVariable(
                    props?.prop?.draft?.variables,
                    titlehtml,
                    proposalSettings?.dateFormat
                  ),
                }}
              />

              <div
                className="simple-section-sub-title"
                style={proposalHelpers.getStyleObj(prop, 'title4')}
                dangerouslySetInnerHTML={{
                  __html: utils.changeVariable(
                    props?.prop?.draft?.variables,
                    subtitlehtml,
                    proposalSettings?.dateFormat
                  ),
                }}
              />
            </Col>
            {renderMainSection()}
          </Row>
        )}
      </div>
    </nobreak>
  );
});

PublishSection.propTypes = {
  name: PropTypes.string.isRequired,
  prop: PropTypes.instanceOf(Object).isRequired,
  configText: PropTypes.func.isRequired,
  ispdf: PropTypes.bool.isRequired,
  showValidationError: PropTypes.bool,
  screen: PropTypes.string,
  wixPreview: PropTypes.bool,
  clientWixPreview: PropTypes.bool,
  templateWixPreview: PropTypes.bool,
  userWixPreview: PropTypes.bool,
};

export default PublishSection;
