import React from 'react';
import { Button, Modal, Input, Row, Divider } from 'antd';
import PropTypes from 'prop-types';
import ShareIcon from 'components/Icons/ShareIcon';
import PublishedCopyIcon from 'components/Icons/PublishedCopyIcon';
import PublishedWhatsappIcon from 'components/Icons/PublishedWhatsappIcon';
import PublishedDownloadIcon from 'components/Icons/PublishedDownloadIcon';
import CloseIcon from 'components/Icons/CloseIcon';

import './PublishedProposalModal.scss';
import { EmailSettingsSection } from './EmailSettingsSection';

const PublishedProposalModal = ({
  onCancel,
  publishedProposalLink,
  copyPropsalLink,
  downloadPdf,
  proposal,
  onSettingUpdate,
}) => {
  const pdfLink = publishedProposalLink();

  return (
    <Modal
      className="simple-info-modal published-proposal-modal"
      centered
      visible
      footer={null}
      style={{ maxWidth: '604px' }}
      width={'100%'}
      closeIcon={<CloseIcon />}
      onCancel={onCancel}>
      <div>
        <ShareIcon />
      </div>
      <h3 className="title">
        {proposal.sharedViaMail
          ? 'Your proposal has been sent.'
          : 'Great job! Your Proposal is now live.'}
      </h3>
      <div className="divider" />
      <p className="body">You can share this link below with your client.</p>
      <Row className="input-with-copy-btn">
        <Input
          id="pdf-link-input"
          name="pdf-link-input"
          type="text"
          className="pdf-link-input"
          value={pdfLink}
          readOnly
        />
        <Button
          icon={<PublishedCopyIcon />}
          className="copy-btn"
          type="primary"
          onClick={copyPropsalLink}>
          Copy
        </Button>
      </Row>
      <Row className="modal-options-block">
        <Row onClick={() => window.open(`https://web.whatsapp.com/send?text=${pdfLink}`, '_blank')}>
          <PublishedWhatsappIcon />
          <span className="helper-text">Send Via WhatsApp</span>
        </Row>
        <Row onClick={downloadPdf}>
          <PublishedDownloadIcon />
          <span className="helper-text">Download .PDF</span>
        </Row>
      </Row>
      <Divider>OR SEND VIA:</Divider>
      <EmailSettingsSection
        onClose={onCancel}
        proposal={proposal}
        onSettingUpdate={onSettingUpdate}
      />
    </Modal>
  );
};

PublishedProposalModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  publishedProposalLink: PropTypes.func.isRequired,
  copyPropsalLink: PropTypes.func.isRequired,
  downloadPdf: PropTypes.func.isRequired,
};

export default PublishedProposalModal;
