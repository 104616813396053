import getRaw from './getRaw';
import helpers from 'helpers/proposal';

const getSignature = ({ prop, user, configText }) => {
  const title = configText('section signature title');

  return {
    title,
    rawtitle: getRaw(title),
    myname: prop.name,
    rawmyname: getRaw(prop.name),
    name: prop?.client?.contact,
    rawname: getRaw(prop?.client?.contact),
    hidesig: user.hidesig,
    signature: user.signature || configText('section signature signature'),
    signatureBackgroundColor: user?.branding?.buttonColor || helpers.torgb('#005aff', false),
  };
};

export default getSignature;
