import React, { useEffect, useState, useRef } from 'react';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { Col, Row, Button, Popover, message } from 'antd';
import { SketchPicker } from 'react-color';
import { useIntercom } from 'react-use-intercom';

import PropTypes from 'prop-types';
import _ from 'lodash';

import RichEditor from 'pages/Proposal/components/RichEditor/RichEditor';
import helpers from 'helpers/proposal';
import SaveAsDefaultModal from '../../../Modals/SaveAsDefaultModal';
import PencilIcon from 'components/Icons/PencilIcon';

import { UploadSignature } from '../../DraftModals';

import './SignatureSection.scss';

// In charge of rendering the signature section of the Proposal template for editing
// You can upload images to this section as signatures, the images are resized in the client side and uploaded to amazon S3 and only the urls are saved in the Proposal object

const findSignatureLayOut = ({ position }) => {
  const signatureLayout = {
    signatureTitleSpan: 8,
    signatureItemsSpan: 16,
    signatureTitleStyle: {},
  };
  if (!position) return signatureLayout;

  if (position === 'top') {
    signatureLayout.signatureTitleSpan = 24;
    signatureLayout.signatureItemsSpan = 24;
  } else if (position === 'top-middle') {
    signatureLayout.signatureTitleSpan = 24;
    signatureLayout.signatureItemsSpan = 24;
    signatureLayout.signatureTitleStyle = {
      justifyContent: 'center',
      display: 'flex',
    };
  } else if (position === 'none') {
    signatureLayout.signatureTitleStyle = {
      display: 'none',
    };
    signatureLayout.signatureTitleSpan = 24;
    signatureLayout.signatureItemsSpan = 24;
  } else if (position === 'large') {
    signatureLayout.signatureTitleSpan = 4;
    signatureLayout.signatureItemsSpan = 20;
  }

  return signatureLayout;
};

const SignatureSection = ({
  user,
  proposal,
  name,
  config,
  saveProposal,
  saveProposalDraft,
  updateProposal,
  saveUser,
  configText,
  setOpenVariablesList,
  setScrollingTo,
  setSavingDraftProposal,
  shouldUndoRedoUpdate,
  setDisablePreview,
  wixEditor,
  templateWixEditor,
  sectionDroppable,
  ...rest
}) => {
  const { trackEvent } = useIntercom();
  const { draft } = proposal;
  const headersection = draft.header;

  const [section, setSection] = useState(draft?.[name]);
  const [disableSig, setDisableSig] = useState(!!proposal?.disableSig);
  const [hideSig, setHideSig] = useState(!!section?.hidesig);
  const [colorValue, setColorValue] = useState();
  const [saveColorAsDefaultModal, showColorAsDefaultModal] = useState(false);
  const [buttonColor, setButtonColor] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const [signame, setSigName] = useState(proposal?.draft?.signature?.signature);
  const [additionalSignature, setAdditionalSignature] = useState(section?.additionalSignature);
  const [signatureUpload, setSignatureUpload] = useState(false);
  const [signatureBackgroundColor, setSignatureBackgroundColor] = useState(
    proposal?.draft?.signature?.signatureBackgroundColor || helpers.torgb('#005aff', false)
  );
  const [signatureTextColor, setSignatureTextColor] = useState(
    proposal?.draft?.signature?.signatureTextColor || 'white'
  );
  const [additionalSignatureBackgroundColor, setAdditionalSignatureBackgroundColor] = useState(
    proposal?.draft?.signature?.additionalSignatureBackgroundColor ||
      helpers.torgb('#005aff', false)
  );
  const [additionalSignatureTextColor, setAdditionalSignatureTextColor] = useState(
    proposal?.draft?.signature?.additionalSignatureTextColor || 'white'
  );

  const defaultSignText = configText('sign proposal button text', true);

  const [signatureText, setSignatureText] = useState(defaultSignText);
  const [signatureText2, setSignatureText2] = useState(defaultSignText);

  useEffect(() => {
    setSigName(proposal?.draft?.signature?.signature);

    if (proposal?.draft?.signature?.btnText?.length > 0 && signatureText === defaultSignText) {
      setSignatureText(proposal.draft.signature.btnText);
    }
    if (proposal?.draft?.signature?.btnText2?.length > 0 && signatureText2 === defaultSignText) {
      setSignatureText2(proposal.draft.signature.btnText2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal]);

  useEffect(() => {
    setSection(proposal?.draft[name]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal?.draft?.[name]?.signature]);

  useEffect(() => {
    if (
      !section ||
      shouldUndoRedoUpdate ||
      section?.hidesig !== draft[name]?.hidesig ||
      section?.additionalSignature !== draft[name]?.additionalSignature ||
      JSON.parse(JSON.stringify(section)) !== JSON.parse(JSON.stringify(draft[name]))
    ) {
      setSection(proposal?.draft[name]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft[name], shouldUndoRedoUpdate]);

  const countcapitalz = (s) => {
    return s
      ? _.reduce(
          _.map(s, (c) => (c === c.toUpperCase() ? 1 : 0)),
          (a, b) => a + b
        )
      : 0;
  };

  let weirdCache = null;

  const debounceSignatureSave = useRef(
    _.debounce((proposal, signatureText, signatureText2) => {
      // text of 2nd btn = default, then set text of 2nd btn to 1st btn text
      let _signatureText2 = signatureText2;
      if (_signatureText2 === defaultSignText) {
        _signatureText2 = signatureText;
        setSignatureText2(signatureText);
      }

      updateProposal({
        id: proposal._id,
        _id: proposal._id,
        pid: proposal.pid,
        auid: proposal.auid,
        uid: proposal.uid,
        edited: new Date(),
        channel: proposal.channel,
        draft: {
          ...proposal?.draft,
          signature: {
            ...proposal?.draft.signature,
            btnText: signatureText,
            btnText2: _signatureText2,
          },
        },
      });
    }, 300)
  ).current;

  const signtaureTextChange = (e, type = 1) => {
    const value = e.target.value.substring(0, 24);
    let _signatureText = signatureText;
    let _signatureText2 = signatureText2;

    if (type === 1) {
      _signatureText = value;
      setSignatureText(value);
    }
    if (type === 2) {
      _signatureText2 = value;
      setSignatureText2(value);
    }

    debounceSignatureSave(proposal, _signatureText, _signatureText2);
  };

  const signtaureTextBlur = (type = 2) => {
    if (type === 2 && signatureText2.length === 0) {
      // if length of 2nd sign is 0 then add text of 1st sign to it
      const _signatureText2 = signatureText;
      setSignatureText2(_signatureText2);

      debounceSignatureSave(proposal, signatureText, _signatureText2);
    }
  };

  const getsigname = (x) => {
    let cls = null;
    if (!x) {
      cls = 'normalsig';
    }
    // else if(/https:\/\/s3-us-west-2.amazonaws.com\/prospero\/signatures/.test(x)) {
    else if (/(http(s?)):\/\//gi.test(x)) {
      cls = 'imgsig';
      // eslint-disable-next-line jsx-a11y/alt-text
      x = <img src={x} />;
    } else {
      cls = 'normalsig';
      x.split(' ').forEach((w) => {
        if (countcapitalz(w) >= 4) {
          if (countcapitalz(w) >= 6) {
            cls = 'verysmallsig';
            weirdCache = cls;
          } else {
            cls = 'smallsig';
            if (!weirdCache) {
              weirdCache = cls;
            }
          }
        }
      });
    }
    return <div className={`signaturetext ${cls}`}>{x}</div>;
  };

  if (!proposal || !section) {
    return null;
  }

  let namehtmlclean;
  if (section?.signature) {
    namehtmlclean = section.signature;
  } else {
    let namehtml = headersection.name;
    if (headersection.rawname) {
      const namecs = convertFromRaw(headersection.rawname);
      namehtml = stateToHTML(namecs);
    }
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = namehtml;
    namehtmlclean = tempDiv.textContent;
  }

  const sightml = getsigname(namehtmlclean);

  const getTitlePosition =
    draft.titleFont && draft.titleFont.position ? draft.titleFont.position : false;
  const proposalTextCell = helpers.getStyleObj(proposal, 'body2');

  const showOrHideSigntaure = () => {
    if (!proposal?.wixProposalId) {
      trackEvent('clicked-disable-sig');
    }

    setDisableSig(!disableSig);
    updateProposal({
      id: proposal._id,
      _id: proposal._id,
      pid: proposal.pid,
      auid: proposal.auid,
      uid: proposal.uid,
      edited: new Date(),
      channel: proposal.channel,
      disableSig: !disableSig,
    });
  };

  const showOrHideUserSigntaure = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!proposal?.wixProposalId) {
      trackEvent('clicked-hide-sig');
    }

    setHideSig(!section?.hidesig);
    saveProposal({
      'draft.signature.hidesig': !section?.hidesig,
    });
  };

  const addOrRemoveAdditionalSigntaure = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!proposal?.wixProposalId) {
      trackEvent('clicked-hide-sig');
    }

    setAdditionalSignature(section?.additionalSignature ? false : { name: 'Prospect Name' });
    saveProposal({
      'draft.signature.additionalSignature': section.additionalSignature
        ? false
        : { name: 'Prospect Name' },
    });
  };

  const uploadSignature = () => {
    setSignatureUpload(true);
  };

  const onColorChange = (selectedColor, handleColor, type) => {
    const {
      rgb: { r, g, b, a },
    } = selectedColor;
    const rgba = `rgba(${r},${g},${b},${a})`;
    setColorValue(rgba);
    handleColor(rgba);

    setButtonColor(rgba);
    saveProposal({
      [`draft.signature.${type}`]: rgba,
    });
  };

  const saveColorAsDefault = () => {
    if (buttonColor) {
      saveUser({
        variables: {
          user: {
            id: user._id,
            branding: {
              ...user.branding,
              buttonColor: buttonColor,
            },
          },
        },
      });

      message.success('Default color saved sucessfully');

      setButtonColor(null);
      showColorAsDefaultModal(false);
    }
  };

  const handleKeyCommand = (e) => {
    if (e.key === 'Escape') {
      const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
      if (isNotCombinedKey) {
        window.removeEventListener('keydown', handleKeyCommand, true);
        setShowPicker(showPicker);
        showColorAsDefaultModal(!showPicker);
      }
    }
  };

  const colorChangeAction = () => {
    if (!showPicker) {
      window.addEventListener('keydown', handleKeyCommand, true);
    } else {
      window.removeEventListener('keydown', handleKeyCommand, true);
    }
    setShowPicker(!showPicker);
    if (colorValue && (colorValue !== user?.branding?.buttonColor || colorValue !== helpers.torgb(user?.branding?.buttonColor))) {
      showColorAsDefaultModal(showPicker);
    }
  }

  const signatureLayOut = findSignatureLayOut({ position: getTitlePosition });

  return (
    <div
      id="proposal-signaturesection"
      className={`proposal-signaturesection disable-${!!disableSig}`}>
      <div className="cover-buttons">
        <span onClick={showOrHideSigntaure}>
          {disableSig ? 'Enable Section' : 'Disable Section'}
        </span>
        <span onClick={showOrHideUserSigntaure}>
          {hideSig ? 'Enable My Signature' : 'Disable My Signature'}
        </span>
        {!wixEditor && !templateWixEditor && (
          <span onClick={addOrRemoveAdditionalSigntaure}>
            {additionalSignature ? 'Remove Signature' : 'Add Signature'}
          </span>
        )}
      </div>
      <Row>
        <Col span={signatureLayOut.signatureTitleSpan} style={signatureLayOut.signatureTitleStyle}>
          <h1
            className={`section-title ${signatureLayOut.signatureTitleStyle.justifyContent}`}
            style={{
              ...helpers.getStyleObj(proposal, 'title3'),
            }}>
            <RichEditor
              defaultValue="Signature"
              content={section.title}
              raw={section.rawtitle}
              id={`${name}_title`}
              prop={proposal}
              config={config}
              configText={configText}
              simple={2}
              sectionName={name}
              sectionType="rawtitle"
              saveProposalDraft={saveProposalDraft}
              setDisablePreview={setDisablePreview}
              setSavingDraftProposal={setSavingDraftProposal}
              blockRenderMap={null}
              setOpenVariablesList={setOpenVariablesList}
              setScrollingTo={setScrollingTo}
              showToolbar={false}
              {...rest}
            />
          </h1>
        </Col>
        <Col
          span={signatureLayOut.signatureItemsSpan}
          className="proposal-signaturesection-content"
          style={Object.assign(proposalTextCell, { marginBottom: '1.5em' })}>
          <Row>
            <Col span={12}>
              <Col span={24} className="signature-col">
                <div className="signature-overlay">
                  <PencilIcon onClick={uploadSignature} />
                </div>
                {sightml}
              </Col>
              <Col span={24}>
                <div className="signature-name">
                  <RichEditor
                    placeholder="Your Name"
                    id={`${name}_myname`}
                    content={section?.myname}
                    raw={section?.rawmyname}
                    prop={proposal}
                    config={config}
                    sectionName={name}
                    sectionType="rawmyname"
                    saveProposalDraft={saveProposalDraft}
                    setDisablePreview={setDisablePreview}
                    setSavingDraftProposal={setSavingDraftProposal}
                    blockRenderMap={null}
                    simple={2}
                    numberOfColumns={2}
                    columnIndex={0}
                    setOpenVariablesList={setOpenVariablesList}
                    setScrollingTo={setScrollingTo}
                    {...rest}
                  />
                </div>
              </Col>
            </Col>
            <Col span={12}>
              <Col span={24} className="draft-signature-btn-col">
                <div className="signature-btn-wrapper">
                  <div className="signature-btn-wrapper-option">
                    <Popover
                      placement="topLeft"
                      visible={showPicker}
                      onVisibleChange={() => colorChangeAction()}
                      content={
                        <SketchPicker
                          color={signatureBackgroundColor}
                          onChange={(selectedColor) =>
                            onColorChange(
                              selectedColor,
                              setSignatureBackgroundColor,
                              'signatureBackgroundColor'
                            )
                          }
                        />
                      }
                      trigger="click"
                      overlayClassName="editor-color-picker">
                      Background Color
                    </Popover>
                    <Popover
                      placement="topLeft"
                      content={
                        <SketchPicker
                          color={signatureTextColor}
                          onChange={(selectedColor) =>
                            onColorChange(
                              selectedColor,
                              setSignatureTextColor,
                              'signatureTextColor'
                            )
                          }
                        />
                      }
                      trigger="click"
                      overlayClassName="editor-color-picker">
                      Text Color
                    </Popover>
                  </div>

                  <Button
                    className="sign-proposal-button"
                    data-ix="showfeedbackpop"
                    style={{
                      backgroundColor: signatureBackgroundColor,
                      color: signatureTextColor,
                    }}>
                    <input value={signatureText} onChange={signtaureTextChange} />
                  </Button>
                </div>
              </Col>
              <Col span={24}>
                <div className="signature-name">
                  <RichEditor
                    placeholder="Prospect Name"
                    id={`${name}_name`}
                    prop={proposal}
                    config={config}
                    content={section?.name}
                    raw={section?.rawname}
                    sectionName={name}
                    sectionType="rawname"
                    saveProposalDraft={saveProposalDraft}
                    setDisablePreview={setDisablePreview}
                    setSavingDraftProposal={setSavingDraftProposal}
                    blockRenderMap={null}
                    simple={2}
                    numberOfColumns={2}
                    columnIndex={1}
                    setOpenVariablesList={setOpenVariablesList}
                    setScrollingTo={setScrollingTo}
                    {...rest}
                  />
                </div>
                {additionalSignature?.name && (
                  <>
                    <Col span={12} className="draft-additional-signature-btn-col">
                      <div className="signature-btn-wrapper">
                        <div className="signature-btn-wrapper-option">
                          <Popover
                            placement="topLeft"
                            content={
                              <SketchPicker
                                color={additionalSignatureBackgroundColor}
                                onChange={(selectedColor) =>
                                  onColorChange(
                                    selectedColor,
                                    setAdditionalSignatureBackgroundColor,
                                    'additionalSignatureBackgroundColor'
                                  )
                                }
                              />
                            }
                            trigger="click"
                            overlayClassName="editor-color-picker">
                            Background Color
                          </Popover>
                          <Popover
                            placement="topLeft"
                            content={
                              <SketchPicker
                                color={additionalSignatureTextColor}
                                onChange={(selectedColor) =>
                                  onColorChange(
                                    selectedColor,
                                    setAdditionalSignatureTextColor,
                                    'additionalSignatureTextColor'
                                  )
                                }
                              />
                            }
                            trigger="click"
                            overlayClassName="editor-color-picker">
                            Text Color
                          </Popover>
                        </div>

                        <Button
                          className="sign-proposal-button"
                          data-ix="showfeedbackpop"
                          style={{
                            backgroundColor: additionalSignatureBackgroundColor,
                            color: additionalSignatureTextColor,
                          }}>
                          <input
                            value={signatureText2}
                            onChange={(e) => signtaureTextChange(e, 2)}
                            onBlur={() => signtaureTextBlur(2)}
                          />
                        </Button>
                      </div>
                    </Col>
                    <div className="additional-signature-name">
                      <RichEditor
                        placeholder="Prospect Name"
                        id={`${name}_name_additional`}
                        prop={proposal}
                        config={config}
                        content={additionalSignature.name}
                        raw={additionalSignature.rawname}
                        sectionName={name}
                        sectionType="additionalSignature.rawname"
                        saveProposalDraft={saveProposalDraft}
                        setDisablePreview={setDisablePreview}
                        setSavingDraftProposal={setSavingDraftProposal}
                        blockRenderMap={null}
                        simple={2}
                        numberOfColumns={2}
                        columnIndex={1}
                        setOpenVariablesList={setOpenVariablesList}
                        setScrollingTo={setScrollingTo}
                        {...rest}
                      />
                    </div>
                  </>
                )}
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
      {signatureUpload && (
        <UploadSignature
          proposal={proposal}
          signame={signame}
          onCancel={() => setSignatureUpload(false)}
          config={config}
          saveProposal={saveProposal}
          user={user}
          saveUser={saveUser}
        />
      )}

      {
        // prevent section drop on top on section
        sectionDroppable && <div className="prevent-drop-mask" />
      }
      {saveColorAsDefaultModal && (
        <SaveAsDefaultModal
          onCancel={() => showColorAsDefaultModal(false)}
          onConfirm={saveColorAsDefault}
          prompt={'Save Color As Default?'}
        />
      )}
    </div>
  );
};

SignatureSection.defaultProps = {
  config: '',
  templateWixEditor: false,
};

SignatureSection.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  proposal: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  saveProposal: PropTypes.func.isRequired,
  saveProposalDraft: PropTypes.func.isRequired,
  updateProposal: PropTypes.func.isRequired,
  config: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  saveUser: PropTypes.func.isRequired,
  setDisablePreview: PropTypes.func.isRequired,
  templateWixEditor: PropTypes.bool,
};

export default SignatureSection;
