import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Menu,
  Checkbox,
  Input,
  Row,
  Col,
  Select,
  Dropdown,
  Spin,
} from 'antd';
import { CaretDownOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';

import Languages from 'constants/languages';
import DateFormats from 'constants/date-format';
import { CURRENCY_FORMATS } from 'constants/currency';
import Sparkle from 'components/Icons/Sparkle';

const ProjectInfo = ({
  user,
  form,
  projectsType,
  clickedCheckbox,
  setClickedCheckbox,
  currency,
  setCurrency,
}) => {
  const inputRef = useRef();
  const [customCurrency, setCustomCurrency] = useState(null);
  const [showSearch, setShowSearch] = useState('');
  const [showPtypeError, setShowPtypeError] = useState(false);
  const [showCurrencyError, setShowCurrencyError] = useState(false);
  const [showCurrencySelect, setShowCurrencySelect] = useState(false);
  const [currencyOptions, setCurrencyOptions] = useState([...CURRENCY_FORMATS]);

  const handleCheckboxClick = (index) => {
    clickedCheckbox[index] = !clickedCheckbox[index];
    setClickedCheckbox([...clickedCheckbox]);
  };

  useEffect(() => {
    if (!clickedCheckbox.some((checked) => checked)) {
      setShowPtypeError(true);
    } else {
      setShowPtypeError(false);
    }
  }, [clickedCheckbox]);

  useEffect(() => {
    if (customCurrency?.trim().length > 10) {
      setShowCurrencyError(true);
    } else {
      setShowCurrencyError(false);
    }
  }, [customCurrency]);

  const enterCurrency = () => {
    let value = customCurrency?.trim();
    if (value.length > 10) {
      setShowCurrencySelect(false);
      return;
    }

    if (value.length < 1) {
      if (inputRef?.current) {
        inputRef.current.focus();
      }
      return;
    }

    // Convert value to uppercase
    value = value.toUpperCase();
    
    // Check if currency already exists in the list
    const isDuplicate = currencyOptions.some((option) => option.symbol === value);
    if (!isDuplicate) {
      const newCurrencyOption = { symbol: value, code: value, custom: true };
      setCurrencyOptions((prev) => [...prev, newCurrencyOption]);
      setCurrency((prev) => [...prev, value]); // Update the currency state
    }

    // Set the value in the form
    form.setFieldsValue({ currency: value });
    setShowCurrencySelect(false);
  };

  const filteredProjects = projectsType.filter((_, index) => clickedCheckbox[index]);
  const menuItems = (
    <Menu>
      {projectsType.map((el, index) => (
        <Menu.Item key={el.key} className="menu-item border-bottom">
          <div
            className="menu-item_"
            onClick={(e) => {
              e.stopPropagation();
              handleCheckboxClick(index);
            }}>
            <div className="label">
              {el.icon}
              {el.label}
            </div>
            <Checkbox
              className="item-text"
              checked={clickedCheckbox[index]}
              onChange={() => handleCheckboxClick(index)}
              onClick={(e) => {
                handleCheckboxClick(index);
              }}></Checkbox>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Form.Item label="Proposal Language" name="proposalLanguage" labelCol={false}>
        <Select suffixIcon={<CaretDownOutlined color="red" />}>
          {Languages.map((language) => (
            <Select.Option key={language.value} value={language.value}>
              {language.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Date Format" name="dateFormat" labelCol={false}>
        <Select suffixIcon={<CaretDownOutlined color="red" />}>
          {DateFormats.map((format) => (
            <Select.Option key={format.value} value={format.value}>
              {format.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Default Currency"
        name="currency"
        rules={[{ max: 10, message: 'Max length allowed is 10 characters!' }]}
        labelCol={false}
        validateStatus={showCurrencyError && 'error'}
        help={showCurrencyError && 'Max length allowed is 10 characters!'}
        shouldUpdate>
        <Select
          suffixIcon={
            !showSearch ? <CaretDownOutlined color="red" /> : <SearchOutlined color="red" />
          }
          className="currency-select"
          open={showCurrencySelect}
          filterOption={(input, option) =>
            (option?.label || '').toUpperCase().includes(input.toUpperCase())
          }
          onSearch={(value) => {
            if (value.length <= 10) {
              setCustomCurrency(value);
            }
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (customCurrency?.length >= 10 && e.key !== 'Backspace') {
              e.preventDefault();
            }
            if (e.key === 'Enter') {
              enterCurrency();
            }
          }}
          onDropdownVisibleChange={(visible) => {
            setCustomCurrency(null);
            setShowCurrencySelect(visible);
            setShowSearch(visible);
          }}
          showSearch={showSearch}
          notFoundContent={<Row className="custom-currency-box">
            <Col xs={16} md={12}>
            <Input
              ref={inputRef}
              placeholder="e.g. AUD"
              type="text"
              value={customCurrency}
              className="custom-currency-input"
              onChange={(e) => {
                setCustomCurrency(e.target.value);
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === 'Enter') {
                  enterCurrency();
                }
              }}
            />
            <Button type="primary" className="primary-btn" onClick={enterCurrency} disabled={!customCurrency}>
              Add custom
            </Button>
            </Col>
          </Row>}
          dropdownRender={(menu) => (
            <div className="expiry-settings">
              {menu}
            </div>
          )}>
          {currencyOptions.map((item) => (
            <Select.Option
              key={item.symbol}
              label={`${item.symbol}`}
            >
              {item.custom && item.symbol !== user?.profile?.currency && (
                <div className="custom-currency-suffix">
                  {item.custom === 'adding' && (
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} />
                  )}
                </div>
              )}
              {item.symbol} {item.custom ? '' : `(${item.code})`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="What type of projects do you offer?"
        name="ptype"
        className={showPtypeError ? 'margin-bottom' : ''}
        labelCol={false}
        validateStatus={showPtypeError && 'error'}
        help={showPtypeError && 'Project type is required!'}>
        <Dropdown
          overlayClassName="multi-select dashboard-multi-select-menu onboard-form"
          overlay={menuItems}
          trigger={['click']}>
          <Input
            className="select-trigger"
            suffix={<CaretDownOutlined color="red" />}
            value={filteredProjects.map((project) => project.label).join(', ')}
            name="ptype"
            readOnly
          />
        </Dropdown>
      </Form.Item>

      {clickedCheckbox[clickedCheckbox.length - 1] ? (
        <Form.Item
          label="Other project type?"
          name="otherptype"
          className={clickedCheckbox[5] ? 'margin-bottom' : ''}
          rules={[
            { required: clickedCheckbox[5], message: 'Field cannot be empty!' },
            { max: 50, message: 'Max length exceeded 50 characters' },
          ]}
          labelCol={false}>
          <Input value={user ? user.otherptype : ''} placeholder="Write short description" />
        </Form.Item>
      ) : null}

      <Row className="helper-text-wrapper-step-3">
        <Col span={2} className="helper-text-step-3 hand-icon">
          <Sparkle />
        </Col>
        <Col span={20} className="helper-text-step-3 text">
          This will help us to match you the most relevant content for your proposals
        </Col>
      </Row>
    </>
  );
};

ProjectInfo.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  projectsType: PropTypes.instanceOf(Array).isRequired,
  clickedCheckbox: PropTypes.instanceOf(Array).isRequired,
  setClickedCheckbox: PropTypes.func.isRequired,
};

export default ProjectInfo;
